.sidebar-cta-box {
  display: none;
  position: relative;
  border: 1px solid $projectGrey;
  padding: 1rem;

  @media (min-width: $screen-lg-min) {
    display: block;
  }

  &:hover {
    .sidebar-cta-box__btn {
      transform: translateY(3px);
      background-color: $brand-primary-accent;
      box-shadow: 0 3px 0 0 transparent;
    }
  }

  &__logo {
    width: 90px;
    height: 90px;
    padding: 10px;
    position: absolute;
    right: 1rem;
    top: -20px;
    border: 1px solid $projectGrey;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $projectWhite;

    img {
      align-self: center;
      width: 100%;
    }
  }

  &__title {
    font-size: $text-size-base;
    padding-right: 95px;
    color: $projectBlack;
  }

  &__update {
    font-size: 0.7rem;
    color: $projectGrey;
    padding-right: 95px;

    &__date {
      display: block;
    }
  }

  &__ranking {
    font-size: $text-size-small;
    color: $projectGrey;
    border-bottom: 1px solid $projectGrey;
    padding-bottom: 10px;
    margin-bottom: 20px;

    &__image {
      padding-right: 0;
    }

    &__text {
      font-size: $text-size-small;
      .highlight {
        color: $projectBlack;
        font-weight: 700;
      }
    }
  }

  &__list {
    li {
      font-size: $text-size-small;
      margin-bottom: 8px;
    }
  }

  &__btn {
    margin: 20px auto 10px;
    width: 100%;
  }

  &__link {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
}