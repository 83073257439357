.trust-badges {
  background-color: $projectBlack;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 25%;
    max-width: 25%;
    padding: 10px;

    @media (min-width: $screen-md-min) {
      flex-basis: 12.5%;
      max-width: 12.5%;
    }

    img {
      align-self: center;
    }
  }
}