// Paths
$global-path: '../';
$fonts-path: $global-path + 'Sources/fonts';
$image-path: $global-path + 'immagini';

// Default colors
$projectWhite: #ffffff !default;
$projectOffWhite: #f9fafb !default;
$projectBlack: #211e1f !default;
$projectGrey: #7a7879 !default;

// brand colors:
$brand-primary: #88c871 !default;
$brand-secondary: #feb312 !default;

$brand-primary-accent: #6faa64;
$brand-secondary-accent: darken($brand-secondary, 10%);

$brand-links: #8b8dd1 !default;
$brand-links-hover: #4c4eab !default;

$brand-negative: #ea3f35 !default;
$brand-positive: #88c871 !default;

$brand-rating-stars: #feb312 !default;
$brand-rating-stars--off: #f29a8b !default;

$border-color: #dddfe2 !default;

// applied colors:
$link-color: $brand-links !default;
$link-hover-color: $brand-links-hover !default;

// font stacks:
$font-family-heading: 'RobotoSlab', sans-serif !default;
$font-family-body: 'Lato', sans-serif !default;
$font-family-icons: 'casinoit', sans-serif !default;

//icons
$font-icons: 'casino-it-icons' !default;

// font sizes:
$text-size-base: 1rem !default;
$text-size-large: ($text-size-base * 1.1) !default;
$text-size-small: ($text-size-base * 0.85) !default;

$heading-size-h1: ($text-size-base * 2.4) !default;
$heading-size-h2: ($text-size-base * 2) !default;
$heading-size-h3: ($text-size-base * 1.5) !default;
$heading-size-h4: ($text-size-base * 1.6) !default;
$heading-size-h5: ($text-size-base * 1.4) !default;
$heading-size-h6: ($text-size-base * 1.2) !default;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 30em !default; //480px
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 48em !default; //768px
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 64em !default; // 1024px
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 75em !default; // 1200px
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - .01) !default;
$screen-sm-max: ($screen-md-min - .01) !default;
$screen-md-max: ($screen-lg-min - .01) !default;