.review-banner {
  background-color: $projectBlack;
  color: $projectWhite;
  margin-bottom: 20px;

  @media (min-width: $screen-md-min) {
    margin-bottom: 40px;
  }

  &__logo,
  &__casino-name,
  &__rating,
  &__platforms,
  &__bonus {
    text-align: center;
    padding: 10px;

    @media (min-width: $screen-md-min) {
      padding: 30px 0;
    }
  }

  &__casino-name-left-aligned {
    text-align: left;
  }

  &__casino-name,
  &__bonus {
    position: relative;
  }

  &__casino-name:before,
  &__bonus:before {
    content: '';
    position: absolute;
    height: 40px;
    top: 50%;
    transform: translateY(-50%);
    left: -1rem;
    background-color: transparentize($projectWhite, 0.6);
    width: 1px;

    @media (min-width: $screen-md-min) {
      height: 60px;
    }
  }
  &__bonus:before {
    display: none;
    @media (min-width: $screen-sm-min) {
      display: block;
    }
  }
  &__platforms:after {
    left: auto;
    right: 0;
  }

  &__logo {
    img {
      display: block;
      margin: 0 auto;
      border-radius: 4px;
      background-color: $projectWhite;
    }
  }

  &__casino-name {
    h1 {
      font-family: $font-family-heading;
      font-weight: bold;
      font-size: $heading-size-h6;
    }
  }

  &__rating {
    font-size: $heading-size-h6;
    display: none;

    @media (min-width: $screen-sm-min) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (min-width: $screen-md-min) {
      visibility: hidden;
      opacity: 0;
    }

    &__value {
      font-weight: 700;
      align-self: center;
    }

    &__star {
      color: $brand-rating-stars;
      align-self: center;
    }
  }

  &__platforms {
    display: none;

    @media (min-width: $screen-sm-min) {
      display: block;
    }

    &:before {
      left: initial;
      right: -1rem;

      @media (min-width: $screen-md-min) {
        right: initial;
        left: -1rem;
      }
    }

    &__icon {
      color: $brand-primary;
    }

    &__label {
      display: block;
    }
  }

  &__bonus {
    &__value {
      color: $brand-primary;
      font-size: $heading-size-h5;
      font-weight: 700;
      padding: 0;
    }
    &__label {
      display: block;
    }
  }

  &__btn {
    max-width: 180px;
    padding: 10px;
  }
}