.trust {
  align-self: center;
  margin-bottom: 40px;

  &__title {
    margin-bottom: 20px;
    text-align: center;

    @media (min-width: $screen-md-min) {
      text-align: left;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    margin-top: 20px;
    text-align: center;

    @media (min-width: $screen-xs-min) {
      flex-direction: row;
      text-align: left;
      max-width: 400px;
      margin: 20px auto 0;
    }
  }

  &__image {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid $brand-primary;
    align-self: center;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-content: center;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 86px;
      height: 86px;
      border-radius: 50%;
      border: 1px solid $border-color;
    }

    img {
      max-width: 50%;
      align-self: center;
    }
  }

  &__content {
    align-self: center;
    margin-top: 10px;

    @media (min-width: $screen-xs-min) {
      margin-top: 0;
      padding-left: 20px;
    }

    &__title {
      padding: 0;
      font-weight: bold;
    }

    &__description {
      padding: 0;
    }
  }

  &--hero {
    color: $projectWhite;
    display: none;
    @media (min-width: $screen-lg-min) {
      display: block;
    }

    .trust__image {
      &:after {
        background-color: $projectWhite;
        z-index: 1;
        border: 1px solid transparent;
      }

      img {
        z-index: 2;
      }
    }
  }
}