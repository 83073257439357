.review-casino-information {
  margin-top: 30px;
  &__header {
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid $projectGrey;
  }

  &__title {
    font-size: $heading-size-h5;
    color: $projectBlack;
  }

  &__subtitle {
    font-weight: 700;
    padding-bottom: 0;
    color: $projectBlack;
  }

  &__content {
    padding: 0;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 300px;

    &__label {
      padding: 0;
      font-size: $text-size-base;
      color: $projectBlack;
    }

    &__value {
      padding: 0;
    }
  }

  &__restricted-countries {

  }

  &__flags {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    transition: all .3s linear;

    p {
      padding: 0;
    }
  }

  &__flag {
    max-width: 46px;
    padding: 4px 4px 0 0;

    img {
      width: 42px;
      height: 32px;
      border: 1px solid $border-color;
    }

  }

  &__language,
  &__currency,
  &__software {

    &__content {
      padding: 0;
    }

    &__item {
      display: inline;
      text-transform: capitalize;

      &:after {
        content: ', '
      }

      &:last-of-type {
        &:after {
          content: '';
        }
      }
    }
  }

  &__support-hours {

  }

  &__support-methods {

  }

}