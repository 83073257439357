@charset "UTF-8";
@-webkit-keyframes sonarEffect {
  0% {
    opacity: 0.65; }
  40% {
    opacity: 0.85;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.8); }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.8);
    -webkit-transform: scale(1.3);
    opacity: 0; } }

@-moz-keyframes sonarEffect {
  0% {
    opacity: 0.65; }
  40% {
    opacity: 0.85;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.8); }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.8);
    -moz-transform: scale(1.3);
    opacity: 0; } }

@keyframes sonarEffect {
  0% {
    opacity: 0.65; }
  40% {
    opacity: 0.85;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #3851bc, 0 0 0 10px rgba(255, 255, 255, 0.8); }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #3851bc, 0 0 0 10px rgba(255, 255, 255, 0.8);
    transform: scale(1.3);
    opacity: 0; } }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, input,
textarea, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: ""; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

html {
  font-size: 14px; }
  @media (min-width: 48em) {
    html {
      font-size: 16px; } }

body {
  font-family: "Lato", sans-serif;
  background-color: #ffffff;
  font-size: 1rem;
  color: #7a7879;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased; }
  body.no-scroll {
    overflow: hidden; }

.container {
  width: 100%;
  position: relative;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (min-width: 75em) {
    .container {
      max-width: 1170px; } }

h1,
h2,
h3 {
  font-family: "RobotoSlab", sans-serif;
  line-height: 1.2; }

h1 {
  font-size: 2rem; }
  @media screen and (min-width: 48em) {
    h1 {
      font-size: 2.4rem; } }

h2 {
  font-size: 1.8rem; }
  @media screen and (min-width: 48em) {
    h2 {
      font-size: 2rem; } }

h3 {
  font-size: 1.5rem; }

h4 {
  font-size: 1.6rem; }

h5 {
  font-size: 1.4rem; }

h6 {
  font-size: 1.2rem; }

p {
  font-size: 1rem;
  line-height: 1.4;
  padding: 10px 0; }
  p .bold {
    font-weight: 700; }

a {
  color: #8b8dd1;
  text-decoration: none; }
  a:hover {
    color: #4c4eab;
    text-decoration: none; }

img {
  max-width: 100%;
  height: auto; }

h3.middle-text {
  text-align: center;
  margin-bottom: 20px; }

@font-face {
  font-family: 'Lato';
  src: url("../Sources/fonts/lato/lato-light.eot");
  src: url("../Sources/fonts/lato/lato-light.eot?#iefix") format("embedded-opentype"), url("../Sources/fonts/lato/lato-light.woff2") format("woff2"), url("../Sources/fonts/lato/lato-light.woff") format("woff"), url("../Sources/fonts/lato/lato-light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("../Sources/fonts/lato/lato-regular.eot");
  src: url("../Sources/fonts/lato/lato-regular.eot?#iefix") format("embedded-opentype"), url("../Sources/fonts/lato/lato-regular.woff2") format("woff2"), url("../Sources/fonts/lato/lato-regular.woff") format("woff"), url("../Sources/fonts/lato/lato-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("../Sources/fonts/lato/lato-bold.eot");
  src: url("../Sources/fonts/lato/lato-bold.eot?#iefix") format("embedded-opentype"), url("../Sources/fonts/lato/lato-bold.woff2") format("woff2"), url("../Sources/fonts/lato/lato-bold.woff") format("woff"), url("../Sources/fonts/lato/lato-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("../Sources/fonts/lato/lato-black.eot");
  src: url("../Sources/fonts/lato/lato-black.eot?#iefix") format("embedded-opentype"), url("../Sources/fonts/lato/lato-black.woff2") format("woff2"), url("../Sources/fonts/lato/lato-black.woff") format("woff"), url("../Sources/fonts/lato/lato-black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'RobotoSlab';
  src: url("../Sources/fonts/robotoslab/RobotoSlab-Thin.eot");
  src: local("☺"), url("../Sources/fonts/robotoslab/RobotoSlab-Thin.woff") format("woff"), url("../Sources/fonts/robotoslab/RobotoSlab-Thin.ttf") format("truetype"), url("../Sources/fonts/robotoslab/RobotoSlab-Thin.svg") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'RobotoSlab';
  src: url("../Sources/fonts/robotoslab/RobotoSlab-Light.eot");
  src: local("☺"), url("../Sources/fonts/robotoslab/RobotoSlab-Light.woff") format("woff"), url("../Sources/fonts/robotoslab/RobotoSlab-Light.ttf") format("truetype"), url("../Sources/fonts/robotoslab/RobotoSlab-Light.svg") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'RobotoSlab';
  src: url("../Sources/fonts/robotoslab/RobotoSlab-Regular.eot");
  src: local("☺"), url("../Sources/fonts/robotoslab/RobotoSlab-Regular.woff") format("woff"), url("../Sources/fonts/robotoslab/RobotoSlab-Regular.ttf") format("truetype"), url("../Sources/fonts/robotoslab/RobotoSlab-Regular.svg") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'RobotoSlab';
  src: url("../Sources/fonts/robotoslab/RobotoSlab-Bold.eot");
  src: local("☺"), url("../Sources/fonts/robotoslab/RobotoSlab-Bold.woff") format("woff"), url("../Sources/fonts/robotoslab/RobotoSlab-Bold.ttf") format("truetype"), url("../Sources/fonts/robotoslab/RobotoSlab-Bold.svg") format("svg");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'casino-it-icons';
  src: url("../Sources/fonts/fontello/casinoit.eot?8560733");
  src: url("../Sources/fonts/fontello/casinoit.eot?8560733#iefix") format("embedded-opentype"), url("../Sources/fonts/fontello/casinoit.woff2?8560733") format("woff2"), url("../Sources/fonts/fontello/casinoit.woff?8560733") format("woff"), url("../Sources/fonts/fontello/casinoit.ttf?8560733") format("truetype"), url("../Sources/fonts/fontello/casinoit.svg?8560733#casinoit") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Uncomment and set these variables to customize the grid. */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem; }

.row {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.row.reverse {
  -ms-flex-direction: row-reverse;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse; }

.col.reverse {
  -ms-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse; }

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  padding-right: 1rem;
  padding-left: 1rem; }

.col-xs {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%; }

.col-xs-1 {
  -ms-flex-preferred-size: 8.333%;
  flex-basis: 8.333%;
  max-width: 8.333%; }

.col-xs-2 {
  -ms-flex-preferred-size: 16.667%;
  flex-basis: 16.667%;
  max-width: 16.667%; }

.col-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  -ms-flex-preferred-size: 33.333%;
  flex-basis: 33.333%;
  max-width: 33.333%; }

.col-xs-5 {
  -ms-flex-preferred-size: 41.667%;
  flex-basis: 41.667%;
  max-width: 41.667%; }

.col-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  -ms-flex-preferred-size: 58.333%;
  flex-basis: 58.333%;
  max-width: 58.333%; }

.col-xs-8 {
  -ms-flex-preferred-size: 66.667%;
  flex-basis: 66.667%;
  max-width: 66.667%; }

.col-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  -ms-flex-preferred-size: 83.333%;
  flex-basis: 83.333%;
  max-width: 83.333%; }

.col-xs-11 {
  -ms-flex-preferred-size: 91.667%;
  flex-basis: 91.667%;
  max-width: 91.667%; }

.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-offset-1 {
  margin-left: 8.333%; }

.col-xs-offset-2 {
  margin-left: 16.667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.333%; }

.col-xs-offset-5 {
  margin-left: 41.667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.333%; }

.col-xs-offset-8 {
  margin-left: 66.667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.333%; }

.col-xs-offset-11 {
  margin-left: 91.667%; }

.start-xs {
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: start; }

.center-xs {
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center; }

.end-xs {
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  text-align: end; }

.top-xs {
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start; }

.middle-xs {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center; }

.bottom-xs {
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end; }

.around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.between-xs {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between; }

.first-xs {
  -ms-flex-order: -1;
  -webkit-box-ordinal-group: 0;
  order: -1; }

.last-xs {
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1; }

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem; }
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem; }
  .col-sm {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-sm-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-sm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-sm-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-sm-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-sm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-sm-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-1 {
    margin-left: 8.333%; }
  .col-sm-offset-2 {
    margin-left: 16.667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.333%; }
  .col-sm-offset-5 {
    margin-left: 41.667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.333%; }
  .col-sm-offset-8 {
    margin-left: 66.667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.333%; }
  .col-sm-offset-11 {
    margin-left: 91.667%; }
  .start-sm {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start; }
  .center-sm {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center; }
  .end-sm {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end; }
  .top-sm {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start; }
  .middle-sm {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center; }
  .bottom-sm {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end; }
  .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .between-sm {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between; }
  .first-sm {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1; }
  .last-sm {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1; } }

@media only screen and (min-width: 64em) {
  .container {
    width: 62rem; }
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem; }
  .col-md {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-md-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-md-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-md-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-md-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-1 {
    margin-left: 8.333%; }
  .col-md-offset-2 {
    margin-left: 16.667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.333%; }
  .col-md-offset-5 {
    margin-left: 41.667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.333%; }
  .col-md-offset-8 {
    margin-left: 66.667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.333%; }
  .col-md-offset-11 {
    margin-left: 91.667%; }
  .start-md {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start; }
  .center-md {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center; }
  .end-md {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end; }
  .top-md {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start; }
  .middle-md {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center; }
  .bottom-md {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end; }
  .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .between-md {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between; }
  .first-md {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1; }
  .last-md {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1; } }

@media only screen and (min-width: 75em) {
  .container {
    width: 71rem; }
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem; }
  .col-lg {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-lg-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-lg-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-lg-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-lg-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-1 {
    margin-left: 8.333%; }
  .col-lg-offset-2 {
    margin-left: 16.667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.333%; }
  .col-lg-offset-5 {
    margin-left: 41.667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.333%; }
  .col-lg-offset-8 {
    margin-left: 66.667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.333%; }
  .col-lg-offset-11 {
    margin-left: 91.667%; }
  .start-lg {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start; }
  .center-lg {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center; }
  .end-lg {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end; }
  .top-lg {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start; }
  .middle-lg {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center; }
  .bottom-lg {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end; }
  .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .between-lg {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between; }
  .first-lg {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1; }
  .last-lg {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*
* iziToast | v1.1.5
* http://izitoast.marcelodolce.com
* by Marcelo Dolce.
*/
.iziToast-capsule {
  font-size: 0;
  height: 0;
  max-height: 1000px;
  width: 100%;
  transform: translateZ(0);
  backface-visibility: hidden;
  transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1), height 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }

.iziToast-capsule,
.iziToast-capsule * {
  box-sizing: border-box; }

.iziToast {
  display: inline-block;
  clear: both;
  position: relative;
  font-family: 'Lato', arial;
  font-size: 14px;
  padding: 8px 45px 9px 0;
  background: rgba(238, 238, 238, 0.9);
  border-color: rgba(238, 238, 238, 0.9);
  width: 100%;
  pointer-events: all;
  cursor: default;
  transform: translateX(0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 54px; }

.iziToast > .iziToast-progressbar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: rgba(255, 255, 255, 0.2); }

.iziToast > .iziToast-progressbar > div {
  height: 2px;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0 0 3px 3px; }

.iziToast.iziToast-balloon:before {
  content: '';
  position: absolute;
  right: 8px;
  left: auto;
  width: 0px;
  height: 0px;
  top: 100%;
  border-right: 0px solid transparent;
  border-left: 15px solid transparent;
  border-top: 10px solid #000;
  border-top-color: inherit;
  border-radius: 0; }

.iziToast.iziToast-balloon .iziToast-progressbar {
  top: 0;
  bottom: auto; }

.iziToast.iziToast-balloon > div {
  border-radius: 0 0 0 3px; }

.iziToast > .iziToast-cover {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  margin: 0;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.1); }

.iziToast > .iziToast-close {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  padding: 0;
  opacity: 0.6;
  width: 42px;
  height: 100%;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAJPAAACTwBcGfW0QAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAD3SURBVFiF1ZdtDoMgDEBfdi4PwAX8vLFn0qT7wxantojKupmQmCi8R4tSACpgjC2ICCUbEBa8ingjsU1AXRBeR8aLN64FiknswN8CYefBBDQ3whuFESy7WyQMeC0ipEI0A+0FeBvHUFN8xPaUhAH/iKoWsnXHGegy4J0yxialOfaHJAz4bhRzQzgDvdGnz4GbAonZbCQMuBm1K/kcFu8Mp1N2cFFpsxsMuJqqbIGExGl4loARajU1twskJLLhIsID7+tvUoDnIjTg5T9DPH9EBrz8rxjPzciAl9+O8SxI8CzJ8CxKFfh3ynK8Dyb8wNHM/XDqejx/AtNyPO87tNybAAAAAElFTkSuQmCC") no-repeat 50% 50%;
  background-size: 8px;
  cursor: pointer;
  outline: none; }

.iziToast > .iziToast-close:hover {
  opacity: 1; }

.iziToast > .iziToast-body {
  position: relative;
  padding: 0 0 0 10px;
  height: 100%;
  min-height: 36px;
  margin: 0 0 0 15px; }

.iziToast > .iziToast-body::after {
  content: "";
  display: table;
  clear: both; }

.iziToast > .iziToast-body > .iziToast-buttons {
  min-height: 17px;
  display: inline-block;
  margin: 0 -2px; }

.iziToast > .iziToast-body > .iziToast-buttons > button,
.iziToast > .iziToast-body > .iziToast-buttons > a {
  display: inline-block;
  margin: 6px 2px;
  border-radius: 2px;
  border: 0;
  padding: 5px 10px;
  font-size: 12px;
  letter-spacing: 0.02em;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
  color: #000; }

.iziToast > .iziToast-body > .iziToast-buttons > button:hover,
.iziToast > .iziToast-body > .iziToast-buttons > a:hover {
  background: rgba(0, 0, 0, 0.2); }

.iziToast > .iziToast-body > .iziToast-icon {
  height: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  display: table;
  font-size: 23px;
  line-height: 24px;
  margin-top: -12px;
  color: #000; }

.iziToast > .iziToast-body > .iziToast-icon.ico-info {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAflBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACCtoPsAAAAKXRSTlMA6PsIvDob+OapavVhWRYPrIry2MxGQ97czsOzpJaMcE0qJQOwVtKjfxCVFeIAAAI3SURBVFjDlJPZsoIwEETnCiGyb8q+qmjl/3/wFmGKwjBROS9QWbtnOqDDGPq4MdMkSc0m7gcDDhF4NRdv8NoL4EcMpzoJglPl/KTDz4WW3IdvXEvxkfIKn7BMZb1bFK4yZFqghZ03jk0nG8N5NBwzx9xU5cxAg8fXi20/hDdC316lcA8o7t16eRuQvW1XGd2d2P8QSHQDDbdIII/9CR3lUF+lbucfJy4WfMS64EJPORnrZxtfc2pjJdnbuags3l04TTtJMXrdTph4Pyg4XAjugAJqMDf5Rf+oXx2/qi4u6nipakIi7CsgiuMSEF9IGKg8heQJKkxIfFSUU/egWSwNrS1fPDtLfon8sZOcYUQml1Qv9a3kfwsEUyJEMgFBKzdV8o3Iw9yAjg1jdLQCV4qbd3no8yD2GugaC3oMbF0NYHCpJYSDhNI5N2DAWB4F4z9Aj/04Cna/x7eVAQ17vRjQZPh+G/kddYv0h49yY4NWNDWMMOMUIRYvlTECmrN8pUAjo5RCMn8KoPmbJ/+Appgnk//Sy90GYBCGgm7IAskQ7D9hFKW4ApB1ei3FSYD9PjGAKygAV+ARFYBH5BsVgG9kkBSAQWKUFYBRZpkUgGVinRWAdUZQDABBQdIcAElDVBUAUUXWHQBZx1gMAGMprM0AsLbVXHsA5trZe93/wp3svQ0YNb/jWV3AIOLsMtlznSNOH7JqjOpDVh7z8qCZR10ftvO4nxeOvPLkpSuvfXnxzKtvXr7j+v8C5ii0e71At7cAAAAASUVORK5CYII=") no-repeat 50% 50%;
  background-size: 85%;
  width: 24px;
  height: 24px; }

.iziToast > .iziToast-body > .iziToast-icon.ico-warning {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAMAAAAPzWOAAAAAkFBMVEUAAAAAAAABAAIAAAABAAIAAAMAAAABAAIBAAIBAAIAAAIAAAABAAIAAAABAAICAAICAAIAAAIAAAAAAAAAAAABAAIBAAIAAAMAAAABAAIBAAMBAAECAAIAAAIAAAIAAAABAAIBAAIBAAMBAAIBAAEAAAIAAAMAAAAAAAABAAECAAICAAIAAAIAAAMAAAQAAAE05yNAAAAAL3RSTlMAB+kD7V8Q+PXicwv7I9iYhkAzJxnx01IV5cmnk2xmHfzexsK4eEw5L7Gei39aRw640awAAAHQSURBVFjD7ZfJdoJAEEWJgCiI4oDiPM8m7///LidErRO7sHrY5u7YXLr7vKqu9kTC0HPmo9n8cJbEQOzqqAdAUHeUZACQuTkGDQBoDJwkHZR0XBz9FkpafXuHP0SJ09mGeJLZ5wwlTmcbA0THPmdEK7XPGTG1zxmInn3OiJ19zkB0jSVTKExMHT0wjAwlWzC0fSPHF1gWRpIhWMYm7fYTFcQGlbemf4dFfdTGg0B/KXM8qBU/3wntbq7rSGqvJ9kla6IpueFJet8fxfem5yhykjyOgNaWF1qSGd5JMNNxpNF7SZQaVh5JzLrTCZIEJ1GyEyVyd+pClMjdaSJK5O40giSRu5PfFiVyd1pAksjdKRnrSsbVdbiHrgT7yss315fkVQPLFQrL+4FHeOXKO5YRFEKv5AiFaMlKLlBpJuVCJlC5sJfvCgztru/3NmBYccPgGTxRAzxn1XGEMUf58pXZvjoOsOCgjL08+b53mtfAM/SVsZcjKLtysQZPqIy9HPP3m/3zKItRwT0LyQo8sTr26tcO83DIUMWIJjierHLsJda/tbNBFY0BP/bKtcM8HNIWCK3aYR4OMzgxo5w5EFLOLKDExXAm9gI4E3iAO94/Ct/lKWuM2LMGbgAAAABJRU5ErkJggg==") no-repeat 50% 50%;
  background-size: 85%;
  width: 24px;
  height: 24px; }

.iziToast > .iziToast-body > .iziToast-icon.ico-error {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAeFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAVyEiIAAAAJ3RSTlMA3BsB98QV8uSyWVUFz7+kcWMM2LuZioBpTUVBNcq2qaibj4d1azLZZYABAAACZElEQVRYw7WX25KCMAyGAxUoFDkpiohnV97/DXeGBtoOUprZ2dyo1K82fxKbwJJVp+KQZ7so2mX5oThVQLKwjDe9YZu4DF3ptAn6rxY0qQPOEq9fNC9ha3y77a22ba24v+9Xbe8v8x03dPOC2/NdvB6xeSreLfGJpnx0TyotKqLm2s7Jd/WO6ivXNp0tCy02R/aFz5VQ5wUPlUL5fIfj5KIlVGU0nWHm/5QtoTVMWY8mzIVu1K9O7XH2JiU/xnOOT39gnUfj+lFHddx4tFjL3/H8jjzaFCy2Rf0c/fdQyQszI8BDR973IyMSKa4krjxAiW/lkRvMP+bKK9WbYS1ASQg8dKjaUGlYPwRe/WoIkz8tiQchH5QAEMv6T0k8MD4mUyWr4E7jAWqZ+xWcMIYkXvlwggJ3IvFK+wIOcpXAo8n8P0COAaXyKH4OsjBuZB4ew0IGu+H1SebhNazsQBbWm8yj+hFuUJB5eMsN0IUXmYendAFFfJB5uEkRMYwxmcd6zDGRtmQePEykAgubymMRFmMxCSIPCRbTuFNN5OGORTjmNGc0Po0m8Uv0gcCry6xUhR2QeLii9tofbEfhz/qvNti+OfPqNm2Mq6105FUMvdT4GPmufMiV8PqBMkc+DdT1bjYYbjzU/ew23VP4n3mLAz4n8Jtv/Ui3ceTT2mzz5o1mZt0gnBpmsdjqRqVlmplcPdqa7X23kL9brdm2t/uBYDPn2+tyu48mtIGD10JTuUrukVrbCFiwDzcHrPjxKt7PW+AZQyT/WESO+1WL7f3o+WLHL2dYMSZsg6dg/z360ofvP4//v1NPzgs28WlWAAAAAElFTkSuQmCC") no-repeat 50% 50%;
  background-size: 80%;
  width: 24px;
  height: 24px; }

.iziToast > .iziToast-body > .iziToast-icon.ico-check {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAAIVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABt0UjBAAAACnRSTlMApAPhIFn82wgGv8mVtwAAAKVJREFUSMft0LEJAkEARNFFFEw1NFJb8CKjAy1AEOzAxNw+bEEEg6nyFjbY4LOzcBwX7S/gwUxoTdIn+Jbv4Lv8bx446+kB6VsBtK0B+wbMCKxrwL33wOrVeeChX28n7KTOTjgoEu6DRSYAgAAAAkAmAIAAAAIACQIkMkACAAgAIACAyECBKAOJuCagTJwSUCaUAEMAABEBRwAAEQFLbCJgO4bW+AZKGnktR+jAFAAAAABJRU5ErkJggg==") no-repeat 50% 50%;
  background-size: 85%;
  width: 24px;
  height: 24px; }

.iziToast > .iziToast-body > strong {
  padding: 0;
  margin: 10px 0 -10px 0;
  line-height: 16px;
  font-size: 14px;
  text-align: left;
  float: left;
  color: #000; }

.iziToast > .iziToast-body > p {
  padding: 0;
  margin: 10px 0;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  float: left;
  color: rgba(0, 0, 0, 0.6); }

.iziToast.iziToast-animateInside p,
.iziToast.iziToast-animateInside strong,
.iziToast.iziToast-animateInside .iziToast-icon,
.iziToast.iziToast-animateInside .iziToast-buttons * {
  opacity: 0; }

.iziToast-target {
  position: relative;
  width: 100%;
  margin: 0 auto; }

.iziToast-target .iziToast-capsule {
  overflow: hidden; }

.iziToast-target .iziToast-capsule:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

.iziToast-target .iziToast-capsule .iziToast {
  width: 100%;
  float: left; }

.iziToast-wrapper {
  z-index: 99999;
  position: fixed;
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column; }

.iziToast-wrapper .iziToast.iziToast-balloon:before {
  border-right: 0 solid transparent;
  border-left: 15px solid transparent;
  border-top: 10px solid #000;
  border-top-color: inherit;
  right: 8px;
  left: auto; }

.iziToast-wrapper-bottomLeft {
  left: 0;
  bottom: 0; }

.iziToast-wrapper-bottomLeft .iziToast.iziToast-balloon:before {
  border-right: 15px solid transparent;
  border-left: 0 solid transparent;
  right: auto;
  left: 8px; }

.iziToast-wrapper-bottomRight {
  right: 0;
  bottom: 0;
  text-align: right; }

.iziToast-wrapper-topLeft {
  left: 0;
  top: 0; }

.iziToast-wrapper-topLeft .iziToast.iziToast-balloon:before {
  border-right: 15px solid transparent;
  border-left: 0 solid transparent;
  right: auto;
  left: 8px; }

.iziToast-wrapper-topRight {
  top: 0;
  right: 0;
  text-align: right; }

.iziToast-wrapper-topCenter {
  top: 0;
  left: 0;
  right: 0;
  text-align: center; }

.iziToast-wrapper-bottomCenter {
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center; }

.iziToast-wrapper-center {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  justify-content: center;
  flex-flow: column;
  align-items: center; }

.iziToast-rtl {
  direction: rtl;
  padding: 8px 0 9px 50px; }

.iziToast-rtl .iziToast-cover {
  left: auto;
  right: 0; }

.iziToast-rtl .iziToast-close {
  right: auto;
  left: 0; }

.iziToast-rtl .iziToast-body {
  padding: 0 10px 0 0;
  margin: 0 16px 0 0; }

.iziToast-rtl .iziToast-body strong {
  padding: 0 0 0 10px; }

.iziToast-rtl .iziToast-body strong,
.iziToast-rtl .iziToast-body p {
  float: right;
  text-align: right; }

.iziToast-rtl .iziToast-body .iziToast-icon {
  left: auto;
  right: 0; }

@media only screen and (min-width: 568px) {
  .iziToast-wrapper {
    padding: 10px 15px; }
  .iziToast-cover {
    border-radius: 3px 0 0 3px; }
  .iziToast {
    margin: 5px 0;
    border-radius: 3px;
    width: auto; }
  .iziToast::after {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    box-shadow: inset 0 -10px 20px -10px rgba(0, 0, 0, 0.2), inset 0 0 5px rgba(0, 0, 0, 0.1), 0 8px 8px -5px rgba(0, 0, 0, 0.25); }
  .iziToast.iziToast-color-dark::after {
    box-shadow: inset 0 -10px 20px -10px rgba(255, 255, 255, 0.3), 0 10px 10px -5px rgba(0, 0, 0, 0.25); }
  .iziToast.iziToast-balloon .iziToast-progressbar {
    background: transparent; }
  .iziToast.iziToast-balloon::after {
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.25), inset 0 10px 20px -5px rgba(0, 0, 0, 0.25); }
  .iziToast-target .iziToast::after {
    box-shadow: inset 0 -10px 20px -10px rgba(0, 0, 0, 0.2), inset 0 0 5px rgba(0, 0, 0, 0.1); } }

.iziToast.iziToast-theme-dark {
  background: #565c70;
  border-color: #565c70; }

.iziToast.iziToast-theme-dark strong {
  color: #fff; }

.iziToast.iziToast-theme-dark p {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300; }

.iziToast.iziToast-theme-dark .iziToast-close {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfgCR4OIQIPSao6AAAAwElEQVRIx72VUQ6EIAwFmz2XB+AConhjzqTJ7JeGKhLYlyx/BGdoBVpjIpMJNjgIZDKTkQHYmYfwmR2AfAqGFBcO2QjXZCd24bEggvd1KBx+xlwoDpYmvnBUUy68DYXD77ESr8WDtYqvxRex7a8oHP4Wo1Mkt5I68Mc+qYqv1h5OsZmZsQ3gj/02h6cO/KEYx29hu3R+VTTwz6D3TymIP1E8RvEiiVdZfEzicxYLiljSxKIqlnW5seitTW6uYnv/Aqh4whX3mEUrAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE2LTA5LTMwVDE0OjMzOjAyKzAyOjAwl6RMVgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNi0wOS0zMFQxNDozMzowMiswMjowMOb59OoAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC") no-repeat 50% 50%;
  background-size: 8px; }

.iziToast.iziToast-theme-dark .iziToast-icon {
  color: #fff; }

.iziToast.iziToast-theme-dark .iziToast-icon.ico-info {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAflBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////vroaSAAAAKXRSTlMA6PsIvDob+OapavVhWRYPrIry2MxGQ97czsOzpJaMcE0qJQOwVtKjfxCVFeIAAAI3SURBVFjDlJPZsoIwEETnCiGyb8q+qmjl/3/wFmGKwjBROS9QWbtnOqDDGPq4MdMkSc0m7gcDDhF4NRdv8NoL4EcMpzoJglPl/KTDz4WW3IdvXEvxkfIKn7BMZb1bFK4yZFqghZ03jk0nG8N5NBwzx9xU5cxAg8fXi20/hDdC316lcA8o7t16eRuQvW1XGd2d2P8QSHQDDbdIII/9CR3lUF+lbucfJy4WfMS64EJPORnrZxtfc2pjJdnbuags3l04TTtJMXrdTph4Pyg4XAjugAJqMDf5Rf+oXx2/qi4u6nipakIi7CsgiuMSEF9IGKg8heQJKkxIfFSUU/egWSwNrS1fPDtLfon8sZOcYUQml1Qv9a3kfwsEUyJEMgFBKzdV8o3Iw9yAjg1jdLQCV4qbd3no8yD2GugaC3oMbF0NYHCpJYSDhNI5N2DAWB4F4z9Aj/04Cna/x7eVAQ17vRjQZPh+G/kddYv0h49yY4NWNDWMMOMUIRYvlTECmrN8pUAjo5RCMn8KoPmbJ/+Appgnk//Sy90GYBCGgm7IAskQ7D9hFKW4ApB1ei3FSYD9PjGAKygAV+ARFYBH5BsVgG9kkBSAQWKUFYBRZpkUgGVinRWAdUZQDABBQdIcAElDVBUAUUXWHQBZx1gMAGMprM0AsLbVXHsA5trZe93/wp3svQ0YNb/jWV3AIOLsMtlznSNOH7JqjOpDVh7z8qCZR10ftvO4nxeOvPLkpSuvfXnxzKtvXr7j+v8C5ii0e71At7cAAAAASUVORK5CYII=") no-repeat 50% 50%;
  background-size: 85%; }

.iziToast.iziToast-theme-dark .iziToast-icon.ico-warning {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAMAAAAPzWOAAAAAllBMVEUAAAD////+//3+//3+//3///////z+//3+//3+//3////////////9//3////+//39//3///3////////////+//3+//39//3///z+//z+//7///3///3///3///3////////+//3+//3+//3+//z+//3+//7///3///z////////+//79//3///3///z///v+//3///+trXouAAAAMHRSTlMAB+j87RBf+PXiCwQClSPYhkAzJxnx05tSyadzcmxmHRbp5d7Gwrh4TDkvsYt/WkdQzCITAAAB1UlEQVRYw+3XaXKCQBCGYSIIighoxCVqNJrEPfly/8vFImKXduNsf/Mc4K1y7FnwlMLQc/bUbj85R6bA1LXRDICg6RjJcZa7NQYtnLUGTpERSiOXxrOPkv9s30iGKDmtbYir3H7OUHJa2ylAuvZzRvzUfs7Ii/2cgfTt54x82s8ZSM848gJmYtroQzA2jHwA+LkBIEuMGt+QIng1igzlyMrkuP2CyOi47axRaYTL5jhDJehoR+aovC29s3iIyly3Eb+hRCvZo2qsGTnhKr2cLDS+J73GsqBI9W80UCmWWpEuhIjh6ZRGjyNRarjzKGJ2Ou2himCvjHwqI+rTqQdlRH06TZQR9ek0hiqiPp06mV4ke7QPX6ERUZxO8Uo3sqrfhxvoRrCpvXwL/UjR9GRHMIvLgke4d5QbiwhM6JV2YKKF4vIl7XIBkwm4keryJVmvk/TfwcmPwQNkUQuyA2/sYGwnXL7GPu4bW1jYsmevrNj09/MGZMOEPXslQVqO8hqykD17JfPHP/bmo2yGGpdZiH3IZvzZa7B3+IdDjjpjesHJcvbs5dZ/e+cddVoDdvlq7x12Nac+iN7e4R8OXTjp0pw5CGnOLNDEzeBs5gVwFniAO+8f8wvfeXP2hyqnmwAAAABJRU5ErkJggg==") no-repeat 50% 50%;
  background-size: 85%; }

.iziToast.iziToast-theme-dark .iziToast-icon.ico-error {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAeFBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////GqOSsAAAAJ3RSTlMA3BsB98QV8uSyWVUFz6RxYwzYvbupmYqAaU1FQTXKv7abj4d1azKNUit3AAACZElEQVRYw7WXaZOCMAyGw30UORRQBLxX/v8/3BkaWjrY2szO5otKfGrzJrEp6Kw6F8f8sI+i/SE/FucKSBaWiT8p5idlaEtnXTB9tKDLLHAvdSatOan3je93k9F2vRF36+mr1a6eH2NFNydoHq/ieU/UXcWjjk9XykdNWq2ywtp4tXL6Wb2T/MqtzzZutsrNyfvA51KoQROhVCjfrnASIRpSVUZiD5v4RbWExjRdJzSmOsZFvzYz59kRSr6V5zE+/QELHkNdb3VRx45HS1b1u+zfkkcbRAZ3qJ9l/A4qefHUDMShJe+6kZKJDD2pLQ9Q4lu+5Q7rz7Plperd7AtQEgIPI6o2dxr2D4GXvxqCiKcn8cD4gxIAEt7/GYkHL16KqeJd0NB4gJbXfgVnzCGJlzGcocCVSLzUvoAj9xJ4NF7/R8gxoVQexc/hgBpSebjPjgPs59cHmYfn7NkDb6wXmUf1I1ygIPPw4gtgCE8yDw8eAop4J/PQcBExjQmZx37MsZB2ZB4cLKQCG5vKYxMWSzMxIg8pNtOyUkvkocEmXGo69mh8FgnxS4yBwMvDrJSNHZB4uC3ayz/YkcIP4lflwVIT+OU07ZSjrbTkZQ6dTPkYubZ8GC/Cqxu6WvJZII93dcCw46GdNqdpTeF/tiMOuDGB9z/NI6NvyWetGPM0g+bVNeovBmamHXWj0nCbEaGeTMN2PWrqd6cM26ZxP2DeJvj+ph/30Zi/GmRbtlK5SptI+nwGGnvH6gUruT+L16MJHF+58rwNIifTV0vM8+hwMeOXAb6Yx0wXT+b999WXfvn+8/X/F7fWzjdTord5AAAAAElFTkSuQmCC") no-repeat 50% 50%;
  background-size: 80%; }

.iziToast.iziToast-theme-dark .iziToast-icon.ico-check {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAAIVBMVEUAAAD////////////////////////////////////////PIev5AAAACnRSTlMApAPhIFn82wgGv8mVtwAAAKVJREFUSMft0LEJAkEARNFFFEw1NFJb8CKjAy1AEOzAxNw+bEEEg6nyFjbY4LOzcBwX7S/gwUxoTdIn+Jbv4Lv8bx446+kB6VsBtK0B+wbMCKxrwL33wOrVeeChX28n7KTOTjgoEu6DRSYAgAAAAkAmAIAAAAIACQIkMkACAAgAIACAyECBKAOJuCagTJwSUCaUAEMAABEBRwAAEQFLbCJgO4bW+AZKGnktR+jAFAAAAABJRU5ErkJggg==") no-repeat 50% 50%;
  background-size: 85%; }

.iziToast.iziToast-theme-dark strong {
  font-weight: 500; }

.iziToast.iziToast-theme-dark .iziToast-buttons button,
.iziToast.iziToast-theme-dark .iziToast-buttons a {
  color: #fff;
  background: rgba(255, 255, 255, 0.1); }

.iziToast.iziToast-theme-dark .iziToast-buttons button:hover,
.iziToast.iziToast-theme-dark .iziToast-buttons a:hover {
  background: rgba(255, 255, 255, 0.2); }

.iziToast.iziToast-color-red {
  background: rgba(243, 186, 189, 0.9);
  border-color: rgba(243, 186, 189, 0.9); }

.iziToast.iziToast-color-yellow {
  background: rgba(243, 237, 172, 0.9);
  border-color: rgba(243, 237, 172, 0.9); }

.iziToast.iziToast-color-blue {
  background: rgba(181, 225, 249, 0.9);
  border-color: rgba(181, 225, 249, 0.9); }

.iziToast.iziToast-color-green {
  background: rgba(180, 241, 196, 0.9);
  border-color: rgba(180, 241, 196, 0.9); }

.iziToast.iziToast-layout2 .iziToast-body > p {
  width: 100%; }

.iziToast.revealIn,
.iziToast .revealIn {
  -webkit-animation: iziT-revealIn 1s cubic-bezier(0.25, 1.6, 0.25, 1) both;
  -moz-animation: iziT-revealIn 1s cubic-bezier(0.25, 1.6, 0.25, 1) both;
  animation: iziT-revealIn 1s cubic-bezier(0.25, 1.6, 0.25, 1) both; }

.iziToast.slideIn,
.iziToast .slideIn {
  -webkit-animation: iziT-slideIn 1s cubic-bezier(0.16, 0.81, 0.32, 1) both;
  -moz-animation: iziT-slideIn 1s cubic-bezier(0.16, 0.81, 0.32, 1) both;
  animation: iziT-slideIn 1s cubic-bezier(0.16, 0.81, 0.32, 1) both; }

.iziToast.bounceInLeft {
  -webkit-animation: iziT-bounceInLeft 0.7s ease-in-out both;
  animation: iziT-bounceInLeft 0.7s ease-in-out both; }

.iziToast.bounceInRight {
  -webkit-animation: iziT-bounceInRight 0.85s ease-in-out both;
  animation: iziT-bounceInRight 0.85s ease-in-out both; }

.iziToast.bounceInDown {
  -webkit-animation: iziT-bounceInDown 0.7s ease-in-out both;
  animation: iziT-bounceInDown 0.7s ease-in-out both; }

.iziToast.bounceInUp {
  -webkit-animation: iziT-bounceInUp 0.7s ease-in-out both;
  animation: iziT-bounceInUp 0.7s ease-in-out both; }

.iziToast.fadeIn {
  -webkit-animation: iziT-fadeIn 0.5s ease both;
  animation: iziT-fadeIn 0.5s ease both; }

.iziToast.fadeInUp {
  -webkit-animation: iziT-fadeInUp 0.7s ease both;
  animation: iziT-fadeInUp 0.7s ease both; }

.iziToast.fadeInDown {
  -webkit-animation: iziT-fadeInDown 0.7s ease both;
  animation: iziT-fadeInDown 0.7s ease both; }

.iziToast.fadeInLeft {
  -webkit-animation: iziT-fadeInLeft 0.85s cubic-bezier(0.25, 0.8, 0.25, 1) both;
  animation: iziT-fadeInLeft 0.85s cubic-bezier(0.25, 0.8, 0.25, 1) both; }

.iziToast.fadeInRight {
  -webkit-animation: iziT-fadeInRight 0.85s cubic-bezier(0.25, 0.8, 0.25, 1) both;
  animation: iziT-fadeInRight 0.85s cubic-bezier(0.25, 0.8, 0.25, 1) both; }

.iziToast.flipInX {
  -webkit-animation: iziT-flipInX 0.85s cubic-bezier(0.35, 0, 0.25, 1) both;
  animation: iziT-flipInX 0.85s cubic-bezier(0.35, 0, 0.25, 1) both; }

.iziToast.fadeOut {
  -webkit-animation: iziT-fadeOut 0.7s ease both;
  animation: iziT-fadeOut 0.7s ease both; }

.iziToast.fadeOutDown {
  -webkit-animation: iziT-fadeOutDown 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both;
  animation: iziT-fadeOutDown 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both; }

.iziToast.fadeOutUp {
  -webkit-animation: iziT-fadeOutUp 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both;
  animation: iziT-fadeOutUp 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both; }

.iziToast.fadeOutLeft {
  -webkit-animation: iziT-fadeOutLeft 0.5s ease both;
  animation: iziT-fadeOutLeft 0.5s ease both; }

.iziToast.fadeOutRight {
  -webkit-animation: iziT-fadeOutRight 0.5s ease both;
  animation: iziT-fadeOutRight 0.5s ease both; }

.iziToast.flipOutX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation: iziT-flipOutX 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both;
  animation: iziT-flipOutX 0.7s cubic-bezier(0.4, 0.45, 0.15, 0.91) both; }

@-webkit-keyframes iziT-revealIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 1); }
  100% {
    opacity: 1; } }

@-moz-keyframes iziT-revealIn {
  0% {
    opacity: 0;
    -moz-transform: scale3d(0.3, 0.3, 1); }
  100% {
    opacity: 1; } }

@-webkit-keyframes iziT-slideIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@-moz-keyframes iziT-slideIn {
  0% {
    opacity: 0;
    -moz-transform: translateX(50px); }
  100% {
    opacity: 1;
    -moz-transform: translateX(0); } }

@-webkit-keyframes iziT-bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(280px); }
  50% {
    opacity: 1;
    -webkit-transform: translateX(-20px); }
  70% {
    -webkit-transform: translateX(10px); }
  100% {
    -webkit-transform: translateX(0); } }

@-webkit-keyframes iziT-bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-280px); }
  50% {
    opacity: 1;
    -webkit-transform: translateX(20px); }
  70% {
    -webkit-transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0); } }

@-webkit-keyframes iziT-bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-200px); }
  50% {
    opacity: 1;
    -webkit-transform: translateY(10px); }
  70% {
    -webkit-transform: translateY(-5px); }
  100% {
    -webkit-transform: translateY(0); } }

@-webkit-keyframes iziT-bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200px); }
  50% {
    opacity: 1;
    -webkit-transform: translateY(-10px); }
  70% {
    -webkit-transform: translateY(5px); }
  100% {
    -webkit-transform: translateY(0); } }

@-webkit-keyframes iziT-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes iziT-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes iziT-fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes iziT-fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes iziT-fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes iziT-flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg); }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@-webkit-keyframes iziT-fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes iziT-fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@-webkit-keyframes iziT-fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@-webkit-keyframes iziT-fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-200px, 0, 0);
    transform: translate3d(-200px, 0, 0); } }

@-webkit-keyframes iziT-fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0); } }

@-webkit-keyframes iziT-flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@-moz-keyframes iziT-revealIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 1); }
  100% {
    opacity: 1; } }

@-webkit-keyframes iziT-revealIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 1); }
  100% {
    opacity: 1; } }

@-o-keyframes iziT-revealIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 1); }
  100% {
    opacity: 1; } }

@keyframes iziT-revealIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 1); }
  100% {
    opacity: 1; } }

@-moz-keyframes iziT-slideIn {
  0% {
    opacity: 0;
    transform: translateX(50px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-webkit-keyframes iziT-slideIn {
  0% {
    opacity: 0;
    transform: translateX(50px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-o-keyframes iziT-slideIn {
  0% {
    opacity: 0;
    transform: translateX(50px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes iziT-slideIn {
  0% {
    opacity: 0;
    transform: translateX(50px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-moz-keyframes iziT-bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(280px); }
  50% {
    opacity: 1;
    transform: translateX(-20px); }
  70% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

@-webkit-keyframes iziT-bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(280px); }
  50% {
    opacity: 1;
    transform: translateX(-20px); }
  70% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

@-o-keyframes iziT-bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(280px); }
  50% {
    opacity: 1;
    transform: translateX(-20px); }
  70% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

@keyframes iziT-bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(280px); }
  50% {
    opacity: 1;
    transform: translateX(-20px); }
  70% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

@-moz-keyframes iziT-bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(-280px); }
  50% {
    opacity: 1;
    transform: translateX(20px); }
  70% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0); } }

@-webkit-keyframes iziT-bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(-280px); }
  50% {
    opacity: 1;
    transform: translateX(20px); }
  70% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0); } }

@-o-keyframes iziT-bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(-280px); }
  50% {
    opacity: 1;
    transform: translateX(20px); }
  70% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0); } }

@keyframes iziT-bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(-280px); }
  50% {
    opacity: 1;
    transform: translateX(20px); }
  70% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0); } }

@-moz-keyframes iziT-bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-200px); }
  50% {
    opacity: 1;
    transform: translateY(10px); }
  70% {
    transform: translateY(-5px); }
  100% {
    transform: translateY(0); } }

@-webkit-keyframes iziT-bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-200px); }
  50% {
    opacity: 1;
    transform: translateY(10px); }
  70% {
    transform: translateY(-5px); }
  100% {
    transform: translateY(0); } }

@-o-keyframes iziT-bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-200px); }
  50% {
    opacity: 1;
    transform: translateY(10px); }
  70% {
    transform: translateY(-5px); }
  100% {
    transform: translateY(0); } }

@keyframes iziT-bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-200px); }
  50% {
    opacity: 1;
    transform: translateY(10px); }
  70% {
    transform: translateY(-5px); }
  100% {
    transform: translateY(0); } }

@-moz-keyframes iziT-bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(200px); }
  50% {
    opacity: 1;
    transform: translateY(-10px); }
  70% {
    transform: translateY(5px); }
  100% {
    transform: translateY(0); } }

@-webkit-keyframes iziT-bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(200px); }
  50% {
    opacity: 1;
    transform: translateY(-10px); }
  70% {
    transform: translateY(5px); }
  100% {
    transform: translateY(0); } }

@-o-keyframes iziT-bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(200px); }
  50% {
    opacity: 1;
    transform: translateY(-10px); }
  70% {
    transform: translateY(5px); }
  100% {
    transform: translateY(0); } }

@keyframes iziT-bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(200px); }
  50% {
    opacity: 1;
    transform: translateY(-10px); }
  70% {
    transform: translateY(5px); }
  100% {
    transform: translateY(0); } }

@-moz-keyframes iziT-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes iziT-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes iziT-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes iziT-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes iziT-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes iziT-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-o-keyframes iziT-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes iziT-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-moz-keyframes iziT-fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes iziT-fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-o-keyframes iziT-fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes iziT-fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-moz-keyframes iziT-fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes iziT-fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-o-keyframes iziT-fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes iziT-fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-moz-keyframes iziT-fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes iziT-fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-o-keyframes iziT-fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes iziT-fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-moz-keyframes iziT-flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg); }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@-webkit-keyframes iziT-flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg); }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@-o-keyframes iziT-flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg); }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes iziT-flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg); }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@-moz-keyframes iziT-fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes iziT-fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-o-keyframes iziT-fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes iziT-fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-moz-keyframes iziT-fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@-webkit-keyframes iziT-fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@-o-keyframes iziT-fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes iziT-fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@-moz-keyframes iziT-fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@-webkit-keyframes iziT-fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@-o-keyframes iziT-fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes iziT-fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@-moz-keyframes iziT-fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-200px, 0, 0);
    transform: translate3d(-200px, 0, 0); } }

@-webkit-keyframes iziT-fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-200px, 0, 0);
    transform: translate3d(-200px, 0, 0); } }

@-o-keyframes iziT-fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-200px, 0, 0);
    transform: translate3d(-200px, 0, 0); } }

@keyframes iziT-fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-200px, 0, 0);
    transform: translate3d(-200px, 0, 0); } }

@-moz-keyframes iziT-fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0); } }

@-webkit-keyframes iziT-fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0); } }

@-o-keyframes iziT-fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0); } }

@keyframes iziT-fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0); } }

@-moz-keyframes iziT-flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@-webkit-keyframes iziT-flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@-o-keyframes iziT-flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@keyframes iziT-flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.content {
  padding: 20px 0; }
  @media (min-width: 64em) {
    .content {
      padding: 30px 0; } }
  .content + .content {
    padding: 0 0 20px; }
    @media (min-width: 64em) {
      .content + .content {
        padding: 0 0 30px; } }
  .content.clear {
    padding-bottom: 0; }
    .content.clear:after {
      content: '';
      clear: both;
      display: table; }
  .content__title {
    color: #211e1f; }
  .content h3 {
    padding-top: 20px; }

.box-read-more-mobile {
  overflow: hidden; }

.persona-image {
  margin-top: 10px; }

.description__bold {
  font-weight: 600; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.btn-fright {
  float: right; }

@keyframes bounce {
  0% {
    transform: translate3d(0, -25px, 0); }
  25% {
    transform: translate3d(0, 10px, 0); }
  50% {
    transform: translate3d(0, -6px, 0); }
  75% {
    transform: translate3d(0, 2px, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes bounce--horizontal {
  0% {
    transform: translate3d(0px, 0, 0); }
  25% {
    transform: translate3d(20px, 0, 0); }
  50% {
    transform: translate3d(0, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

.btn, .btn--brand, .btn--brand-secondary, .btn--brand-white {
  display: block;
  padding: 15px;
  cursor: pointer;
  text-align: center;
  color: #211e1f;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 700;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 3px 0 0 #7a7879;
  -webkit-font-smoothing: antialiased;
  transition: transform .2s linear; }
  .btn:hover, .btn--brand:hover, .btn--brand-secondary:hover, .btn--brand-white:hover {
    color: #211e1f;
    box-shadow: 0 3px 0 0 transparent; }
  .btn--brand {
    background: #88c871;
    box-shadow: 0 3px 0 0 #6faa64; }
    .btn--brand:hover {
      transform: translateY(3px);
      background: #6faa64; }
  .btn--brand-secondary {
    background: #feb312;
    box-shadow: 0 3px 0 0 #dc9601; }
    .btn--brand-secondary:hover {
      transform: translateY(3px);
      background: #dc9601; }
  .btn--brand-outline {
    border: 1px solid #88c871;
    background-color: transparent;
    box-shadow: none; }
    .btn--brand-outline:hover {
      background-color: #88c871; }
  .btn--icon {
    position: relative;
    padding-right: 55px; }
    .btn--icon:after {
      float: right;
      font-family: "casino-it-icons";
      content: '\f107';
      background: #6faa64;
      width: 25px;
      height: 25px;
      line-height: 25px;
      border-radius: 13px;
      position: absolute;
      top: 50%;
      right: 20px;
      margin-top: -12px; }
  .btn--brand-white {
    background: #ffffff;
    box-shadow: 0 3px 0 0 #dddfe2;
    border-color: #dddfe2;
    color: #8b8dd1; }
    .btn--brand-white:hover {
      transform: translateY(3px);
      background: #dddfe2; }

.btn-read-more {
  margin: 10px;
  display: flex;
  justify-content: center; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "casino-it-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-star:before {
  content: '\e800'; }

/* '' */
.icon-star-empty:before {
  content: '\e801'; }

/* '' */
.icon-star-half:before {
  content: '\e802'; }

/* '' */
.icon-minus-circled:before {
  content: '\e803'; }

/* '' */
.icon-plus-circled:before {
  content: '\e804'; }

/* '' */
.icon-down-open-big:before {
  content: '\e805'; }

/* '' */
.icon-up-open-big:before {
  content: '\e806'; }

/* '' */
.icon-ok-circled-1:before {
  content: '\e807'; }

/* '' */
.icon-cancel-circled:before {
  content: '\e808'; }

/* '' */
.icon-android:before {
  content: '\e809'; }

/* '' */
.icon-down-open:before {
  content: '\e80a'; }

/* '' */
.icon-left-open:before {
  content: '\e80b'; }

/* '' */
.icon-right-open:before {
  content: '\e80c'; }

/* '' */
.icon-up-open:before {
  content: '\e80d'; }

/* '' */
.icon-tick:before {
  content: '\e80e'; }

/* '' */
.icon-faq:before {
  content: '\e80f'; }

/* '' */
.icon-grid:before {
  content: '\e810'; }

/* '' */
.icon-list:before {
  content: '\e811'; }

/* '' */
.icon-ranking:before {
  content: '\e812'; }

/* '' */
.icon-cross:before {
  content: '\e813'; }

/* '' */
.icon-payout:before {
  content: '\e814'; }

/* '' */
.icon-games:before {
  content: '\e815'; }

/* '' */
.icon-bonus:before {
  content: '\e816'; }

/* '' */
.icon-angle-left:before {
  content: '\f104'; }

/* '' */
.icon-angle-right:before {
  content: '\f105'; }

/* '' */
.icon-angle-up:before {
  content: '\f106'; }

/* '' */
.icon-angle-down:before {
  content: '\f107'; }

/* '' */
.icon-desktop:before {
  content: '\f108'; }

/* '' */
.icon-tablet:before {
  content: '\f10a'; }

/* '' */
.icon-mobile:before {
  content: '\f10b'; }

/* '' */
.icon-star-half-alt:before {
  content: '\f123'; }

/* '' */
.icon-youtube-play:before {
  content: '\f16a'; }

/* '' */
.icon-apple:before {
  content: '\f179'; }

/* '' */
.icon-windows:before {
  content: '\f17a'; }

/* '' */
.icon-youtube:before {
  content: '\f315'; }

/* '' */
ul li {
  position: relative; }

.list li {
  padding-left: 30px;
  margin-bottom: 5px; }
  .list li:before {
    content: '';
    position: absolute;
    left: 0; }
  .list li .bold {
    font-weight: 700; }

.list--bold {
  font-weight: 700; }

.list--brand li:before {
  color: #88c871; }

.list--negative li:before {
  color: #ea3f35; }

.list--bullet li:before {
  top: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%; }

.list--bullet.list--brand li:before {
  background-color: #88c871; }

.list--numbers {
  counter-reset: numbered-list; }
  .list--numbers li:before {
    content: counter(numbered-list) ".";
    counter-increment: numbered-list;
    font-size: 1rem;
    font-weight: 700; }

.list--standard li {
  padding-left: 3px; }
  .list--standard li:before {
    position: relative;
    margin-right: 10px; }

.list--ticks li:before {
  content: '\e80e';
  font-family: "casino-it-icons";
  left: 4px;
  top: 1px; }

.list--crosses li:before {
  content: '\e808';
  font-family: "casino-it-icons";
  left: 4px;
  top: 1px; }

blockquote {
  position: relative;
  border: 1px solid #dddfe2;
  padding: 10px 10px 10px 50px; }
  blockquote:before {
    content: '\201C';
    position: absolute;
    left: 10px;
    top: 10px;
    color: #88c871;
    font-size: 5rem;
    font-family: "RobotoSlab", sans-serif;
    height: 40px;
    line-height: 1; }
  blockquote cite {
    color: #88c871; }
  blockquote .blockquote__name {
    font-weight: 700;
    color: #88c871;
    text-transform: capitalize; }
  blockquote .blockquote__source {
    color: #7a7879; }

.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat; }

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.33333em;
  line-height: 1em; }
  .flag-icon:before {
    content: '\00a0'; }

.flag-icon-ad {
  background-image: url(../immagini/flags/ad.svg); }

.flag-icon-ae {
  background-image: url(../immagini/flags/ae.svg); }

.flag-icon-af {
  background-image: url(../immagini/flags/af.svg); }

.flag-icon-ag {
  background-image: url(../immagini/flags/ag.svg); }

.flag-icon-ai {
  background-image: url(../immagini/flags/ai.svg); }

.flag-icon-al {
  background-image: url(../immagini/flags/al.svg); }

.flag-icon-am {
  background-image: url(../immagini/flags/am.svg); }

.flag-icon-ao {
  background-image: url(../immagini/flags/ao.svg); }

.flag-icon-aq {
  background-image: url(../immagini/flags/aq.svg); }

.flag-icon-ar {
  background-image: url(../immagini/flags/ar.svg); }

.flag-icon-as {
  background-image: url(../immagini/flags/as.svg); }

.flag-icon-at {
  background-image: url(../immagini/flags/at.svg); }

.flag-icon-au {
  background-image: url(../immagini/flags/au.svg); }

.flag-icon-aw {
  background-image: url(../immagini/flags/aw.svg); }

.flag-icon-ax {
  background-image: url(../immagini/flags/ax.svg); }

.flag-icon-az {
  background-image: url(../immagini/flags/az.svg); }

.flag-icon-ba {
  background-image: url(../immagini/flags/ba.svg); }

.flag-icon-bb {
  background-image: url(../immagini/flags/bb.svg); }

.flag-icon-bd {
  background-image: url(../immagini/flags/bd.svg); }

.flag-icon-be {
  background-image: url(../immagini/flags/be.svg); }

.flag-icon-bf {
  background-image: url(../immagini/flags/bf.svg); }

.flag-icon-bg {
  background-image: url(../immagini/flags/bg.svg); }

.flag-icon-bh {
  background-image: url(../immagini/flags/bh.svg); }

.flag-icon-bi {
  background-image: url(../immagini/flags/bi.svg); }

.flag-icon-bj {
  background-image: url(../immagini/flags/bj.svg); }

.flag-icon-bl {
  background-image: url(../immagini/flags/bl.svg); }

.flag-icon-bm {
  background-image: url(../immagini/flags/bm.svg); }

.flag-icon-bn {
  background-image: url(../immagini/flags/bn.svg); }

.flag-icon-bo {
  background-image: url(../immagini/flags/bo.svg); }

.flag-icon-bq {
  background-image: url(../immagini/flags/bq.svg); }

.flag-icon-br {
  background-image: url(../immagini/flags/br.svg); }

.flag-icon-bs {
  background-image: url(../immagini/flags/bs.svg); }

.flag-icon-bt {
  background-image: url(../immagini/flags/bt.svg); }

.flag-icon-bv {
  background-image: url(../immagini/flags/bv.svg); }

.flag-icon-bw {
  background-image: url(../immagini/flags/bw.svg); }

.flag-icon-by {
  background-image: url(../immagini/flags/by.svg); }

.flag-icon-bz {
  background-image: url(../immagini/flags/bz.svg); }

.flag-icon-ca {
  background-image: url(../immagini/flags/ca.svg); }

.flag-icon-cc {
  background-image: url(../immagini/flags/cc.svg); }

.flag-icon-cd {
  background-image: url(../immagini/flags/cd.svg); }

.flag-icon-cf {
  background-image: url(../immagini/flags/cf.svg); }

.flag-icon-cg {
  background-image: url(../immagini/flags/cg.svg); }

.flag-icon-ch {
  background-image: url(../immagini/flags/ch.svg); }

.flag-icon-ci {
  background-image: url(../immagini/flags/ci.svg); }

.flag-icon-ck {
  background-image: url(../immagini/flags/ck.svg); }

.flag-icon-cl {
  background-image: url(../immagini/flags/cl.svg); }

.flag-icon-cm {
  background-image: url(../immagini/flags/cm.svg); }

.flag-icon-cn {
  background-image: url(../immagini/flags/cn.svg); }

.flag-icon-co {
  background-image: url(../immagini/flags/co.svg); }

.flag-icon-cr {
  background-image: url(../immagini/flags/cr.svg); }

.flag-icon-cu {
  background-image: url(../immagini/flags/cu.svg); }

.flag-icon-cv {
  background-image: url(../immagini/flags/cv.svg); }

.flag-icon-cw {
  background-image: url(../immagini/flags/cw.svg); }

.flag-icon-cx {
  background-image: url(../immagini/flags/cx.svg); }

.flag-icon-cy {
  background-image: url(../immagini/flags/cy.svg); }

.flag-icon-cz {
  background-image: url(../immagini/flags/cz.svg); }

.flag-icon-de {
  background-image: url(../immagini/flags/de.svg); }

.flag-icon-dj {
  background-image: url(../immagini/flags/dj.svg); }

.flag-icon-dk {
  background-image: url(../immagini/flags/dk.svg); }

.flag-icon-dm {
  background-image: url(../immagini/flags/dm.svg); }

.flag-icon-do {
  background-image: url(../immagini/flags/do.svg); }

.flag-icon-dz {
  background-image: url(../immagini/flags/dz.svg); }

.flag-icon-ec {
  background-image: url(../immagini/flags/ec.svg); }

.flag-icon-ee {
  background-image: url(../immagini/flags/ee.svg); }

.flag-icon-eg {
  background-image: url(../immagini/flags/eg.svg); }

.flag-icon-eh {
  background-image: url(../immagini/flags/eh.svg); }

.flag-icon-er {
  background-image: url(../immagini/flags/er.svg); }

.flag-icon-es {
  background-image: url(../immagini/flags/es.svg); }

.flag-icon-et {
  background-image: url(../immagini/flags/et.svg); }

.flag-icon-fi {
  background-image: url(../immagini/flags/fi.svg); }

.flag-icon-fj {
  background-image: url(../immagini/flags/fj.svg); }

.flag-icon-fk {
  background-image: url(../immagini/flags/fk.svg); }

.flag-icon-fm {
  background-image: url(../immagini/flags/fm.svg); }

.flag-icon-fo {
  background-image: url(../immagini/flags/fo.svg); }

.flag-icon-fr {
  background-image: url(../immagini/flags/fr.svg); }

.flag-icon-ga {
  background-image: url(../immagini/flags/ga.svg); }

.flag-icon-gb {
  background-image: url(../immagini/flags/gb.svg); }

.flag-icon-gd {
  background-image: url(../immagini/flags/gd.svg); }

.flag-icon-ge {
  background-image: url(../immagini/flags/ge.svg); }

.flag-icon-gf {
  background-image: url(../immagini/flags/gf.svg); }

.flag-icon-gg {
  background-image: url(../immagini/flags/gg.svg); }

.flag-icon-gh {
  background-image: url(../immagini/flags/gh.svg); }

.flag-icon-gi {
  background-image: url(../immagini/flags/gi.svg); }

.flag-icon-gl {
  background-image: url(../immagini/flags/gl.svg); }

.flag-icon-gm {
  background-image: url(../immagini/flags/gm.svg); }

.flag-icon-gn {
  background-image: url(../immagini/flags/gn.svg); }

.flag-icon-gp {
  background-image: url(../immagini/flags/gp.svg); }

.flag-icon-gq {
  background-image: url(../immagini/flags/gq.svg); }

.flag-icon-gr {
  background-image: url(../immagini/flags/gr.svg); }

.flag-icon-gs {
  background-image: url(../immagini/flags/gs.svg); }

.flag-icon-gt {
  background-image: url(../immagini/flags/gt.svg); }

.flag-icon-gu {
  background-image: url(../immagini/flags/gu.svg); }

.flag-icon-gw {
  background-image: url(../immagini/flags/gw.svg); }

.flag-icon-gy {
  background-image: url(../immagini/flags/gy.svg); }

.flag-icon-hk {
  background-image: url(../immagini/flags/hk.svg); }

.flag-icon-hm {
  background-image: url(../immagini/flags/hm.svg); }

.flag-icon-hn {
  background-image: url(../immagini/flags/hn.svg); }

.flag-icon-hr {
  background-image: url(../immagini/flags/hr.svg); }

.flag-icon-ht {
  background-image: url(../immagini/flags/ht.svg); }

.flag-icon-hu {
  background-image: url(../immagini/flags/hu.svg); }

.flag-icon-id {
  background-image: url(../immagini/flags/id.svg); }

.flag-icon-ie {
  background-image: url(../immagini/flags/ie.svg); }

.flag-icon-il {
  background-image: url(../immagini/flags/il.svg); }

.flag-icon-im {
  background-image: url(../immagini/flags/im.svg); }

.flag-icon-in {
  background-image: url(../immagini/flags/in.svg); }

.flag-icon-io {
  background-image: url(../immagini/flags/io.svg); }

.flag-icon-iq {
  background-image: url(../immagini/flags/iq.svg); }

.flag-icon-ir {
  background-image: url(../immagini/flags/ir.svg); }

.flag-icon-is {
  background-image: url(../immagini/flags/is.svg); }

.flag-icon-it {
  background-image: url(../immagini/flags/it.svg); }

.flag-icon-je {
  background-image: url(../immagini/flags/je.svg); }

.flag-icon-jm {
  background-image: url(../immagini/flags/jm.svg); }

.flag-icon-jo {
  background-image: url(../immagini/flags/jo.svg); }

.flag-icon-jp {
  background-image: url(../immagini/flags/jp.svg); }

.flag-icon-ke {
  background-image: url(../immagini/flags/ke.svg); }

.flag-icon-kg {
  background-image: url(../immagini/flags/kg.svg); }

.flag-icon-kh {
  background-image: url(../immagini/flags/kh.svg); }

.flag-icon-ki {
  background-image: url(../immagini/flags/ki.svg); }

.flag-icon-km {
  background-image: url(../immagini/flags/km.svg); }

.flag-icon-kn {
  background-image: url(../immagini/flags/kn.svg); }

.flag-icon-kp {
  background-image: url(../immagini/flags/kp.svg); }

.flag-icon-kr {
  background-image: url(../immagini/flags/kr.svg); }

.flag-icon-kw {
  background-image: url(../immagini/flags/kw.svg); }

.flag-icon-ky {
  background-image: url(../immagini/flags/ky.svg); }

.flag-icon-kz {
  background-image: url(../immagini/flags/kz.svg); }

.flag-icon-la {
  background-image: url(../immagini/flags/la.svg); }

.flag-icon-lb {
  background-image: url(../immagini/flags/lb.svg); }

.flag-icon-lc {
  background-image: url(../immagini/flags/lc.svg); }

.flag-icon-li {
  background-image: url(../immagini/flags/li.svg); }

.flag-icon-lk {
  background-image: url(../immagini/flags/lk.svg); }

.flag-icon-lr {
  background-image: url(../immagini/flags/lr.svg); }

.flag-icon-ls {
  background-image: url(../immagini/flags/ls.svg); }

.flag-icon-lt {
  background-image: url(../immagini/flags/lt.svg); }

.flag-icon-lu {
  background-image: url(../immagini/flags/lu.svg); }

.flag-icon-lv {
  background-image: url(../immagini/flags/lv.svg); }

.flag-icon-ly {
  background-image: url(../immagini/flags/ly.svg); }

.flag-icon-ma {
  background-image: url(../immagini/flags/ma.svg); }

.flag-icon-mc {
  background-image: url(../immagini/flags/mc.svg); }

.flag-icon-md {
  background-image: url(../immagini/flags/md.svg); }

.flag-icon-me {
  background-image: url(../immagini/flags/me.svg); }

.flag-icon-mf {
  background-image: url(../immagini/flags/mf.svg); }

.flag-icon-mg {
  background-image: url(../immagini/flags/mg.svg); }

.flag-icon-mh {
  background-image: url(../immagini/flags/mh.svg); }

.flag-icon-mk {
  background-image: url(../immagini/flags/mk.svg); }

.flag-icon-ml {
  background-image: url(../immagini/flags/ml.svg); }

.flag-icon-mm {
  background-image: url(../immagini/flags/mm.svg); }

.flag-icon-mn {
  background-image: url(../immagini/flags/mn.svg); }

.flag-icon-mo {
  background-image: url(../immagini/flags/mo.svg); }

.flag-icon-mp {
  background-image: url(../immagini/flags/mp.svg); }

.flag-icon-mq {
  background-image: url(../immagini/flags/mq.svg); }

.flag-icon-mr {
  background-image: url(../immagini/flags/mr.svg); }

.flag-icon-ms {
  background-image: url(../immagini/flags/ms.svg); }

.flag-icon-mt {
  background-image: url(../immagini/flags/mt.svg); }

.flag-icon-mu {
  background-image: url(../immagini/flags/mu.svg); }

.flag-icon-mv {
  background-image: url(../immagini/flags/mv.svg); }

.flag-icon-mw {
  background-image: url(../immagini/flags/mw.svg); }

.flag-icon-mx {
  background-image: url(../immagini/flags/mx.svg); }

.flag-icon-my {
  background-image: url(../immagini/flags/my.svg); }

.flag-icon-mz {
  background-image: url(../immagini/flags/mz.svg); }

.flag-icon-na {
  background-image: url(../immagini/flags/na.svg); }

.flag-icon-nc {
  background-image: url(../immagini/flags/nc.svg); }

.flag-icon-ne {
  background-image: url(../immagini/flags/ne.svg); }

.flag-icon-nf {
  background-image: url(../immagini/flags/nf.svg); }

.flag-icon-ng {
  background-image: url(../immagini/flags/ng.svg); }

.flag-icon-ni {
  background-image: url(../immagini/flags/ni.svg); }

.flag-icon-nl {
  background-image: url(../immagini/flags/nl.svg); }

.flag-icon-no {
  background-image: url(../immagini/flags/no.svg); }

.flag-icon-np {
  background-image: url(../immagini/flags/np.svg); }

.flag-icon-nr {
  background-image: url(../immagini/flags/nr.svg); }

.flag-icon-nu {
  background-image: url(../immagini/flags/nu.svg); }

.flag-icon-nz {
  background-image: url(../immagini/flags/nz.svg); }

.flag-icon-om {
  background-image: url(../immagini/flags/om.svg); }

.flag-icon-pa {
  background-image: url(../immagini/flags/pa.svg); }

.flag-icon-pe {
  background-image: url(../immagini/flags/pe.svg); }

.flag-icon-pf {
  background-image: url(../immagini/flags/pf.svg); }

.flag-icon-pg {
  background-image: url(../immagini/flags/pg.svg); }

.flag-icon-ph {
  background-image: url(../immagini/flags/ph.svg); }

.flag-icon-pk {
  background-image: url(../immagini/flags/pk.svg); }

.flag-icon-pl {
  background-image: url(../immagini/flags/pl.svg); }

.flag-icon-pm {
  background-image: url(../immagini/flags/pm.svg); }

.flag-icon-pn {
  background-image: url(../immagini/flags/pn.svg); }

.flag-icon-pr {
  background-image: url(../immagini/flags/pr.svg); }

.flag-icon-ps {
  background-image: url(../immagini/flags/ps.svg); }

.flag-icon-pt {
  background-image: url(../immagini/flags/pt.svg); }

.flag-icon-pw {
  background-image: url(../immagini/flags/pw.svg); }

.flag-icon-py {
  background-image: url(../immagini/flags/py.svg); }

.flag-icon-qa {
  background-image: url(../immagini/flags/qa.svg); }

.flag-icon-re {
  background-image: url(../immagini/flags/re.svg); }

.flag-icon-ro {
  background-image: url(../immagini/flags/ro.svg); }

.flag-icon-rs {
  background-image: url(../immagini/flags/rs.svg); }

.flag-icon-ru {
  background-image: url(../immagini/flags/ru.svg); }

.flag-icon-rw {
  background-image: url(../immagini/flags/rw.svg); }

.flag-icon-sa {
  background-image: url(../immagini/flags/sa.svg); }

.flag-icon-sb {
  background-image: url(../immagini/flags/sb.svg); }

.flag-icon-sc {
  background-image: url(../immagini/flags/sc.svg); }

.flag-icon-sd {
  background-image: url(../immagini/flags/sd.svg); }

.flag-icon-se {
  background-image: url(../immagini/flags/se.svg); }

.flag-icon-sg {
  background-image: url(../immagini/flags/sg.svg); }

.flag-icon-sh {
  background-image: url(../immagini/flags/sh.svg); }

.flag-icon-si {
  background-image: url(../immagini/flags/si.svg); }

.flag-icon-sj {
  background-image: url(../immagini/flags/sj.svg); }

.flag-icon-sk {
  background-image: url(../immagini/flags/sk.svg); }

.flag-icon-sl {
  background-image: url(../immagini/flags/sl.svg); }

.flag-icon-sm {
  background-image: url(../immagini/flags/sm.svg); }

.flag-icon-sn {
  background-image: url(../immagini/flags/sn.svg); }

.flag-icon-so {
  background-image: url(../immagini/flags/so.svg); }

.flag-icon-sr {
  background-image: url(../immagini/flags/sr.svg); }

.flag-icon-ss {
  background-image: url(../immagini/flags/ss.svg); }

.flag-icon-st {
  background-image: url(../immagini/flags/st.svg); }

.flag-icon-sv {
  background-image: url(../immagini/flags/sv.svg); }

.flag-icon-sx {
  background-image: url(../immagini/flags/sx.svg); }

.flag-icon-sy {
  background-image: url(../immagini/flags/sy.svg); }

.flag-icon-sz {
  background-image: url(../immagini/flags/sz.svg); }

.flag-icon-tc {
  background-image: url(../immagini/flags/tc.svg); }

.flag-icon-td {
  background-image: url(../immagini/flags/td.svg); }

.flag-icon-tf {
  background-image: url(../immagini/flags/tf.svg); }

.flag-icon-tg {
  background-image: url(../immagini/flags/tg.svg); }

.flag-icon-th {
  background-image: url(../immagini/flags/th.svg); }

.flag-icon-tj {
  background-image: url(../immagini/flags/tj.svg); }

.flag-icon-tk {
  background-image: url(../immagini/flags/tk.svg); }

.flag-icon-tl {
  background-image: url(../immagini/flags/tl.svg); }

.flag-icon-tm {
  background-image: url(../immagini/flags/tm.svg); }

.flag-icon-tn {
  background-image: url(../immagini/flags/tn.svg); }

.flag-icon-to {
  background-image: url(../immagini/flags/to.svg); }

.flag-icon-tr {
  background-image: url(../immagini/flags/tr.svg); }

.flag-icon-tt {
  background-image: url(../immagini/flags/tt.svg); }

.flag-icon-tv {
  background-image: url(../immagini/flags/tv.svg); }

.flag-icon-tw {
  background-image: url(../immagini/flags/tw.svg); }

.flag-icon-tz {
  background-image: url(../immagini/flags/tz.svg); }

.flag-icon-ua {
  background-image: url(../immagini/flags/ua.svg); }

.flag-icon-ug {
  background-image: url(../immagini/flags/ug.svg); }

.flag-icon-um {
  background-image: url(../immagini/flags/um.svg); }

.flag-icon-us {
  background-image: url(../immagini/flags/us.svg); }

.flag-icon-uy {
  background-image: url(../immagini/flags/uy.svg); }

.flag-icon-uz {
  background-image: url(../immagini/flags/uz.svg); }

.flag-icon-va {
  background-image: url(../immagini/flags/va.svg); }

.flag-icon-vc {
  background-image: url(../immagini/flags/vc.svg); }

.flag-icon-ve {
  background-image: url(../immagini/flags/ve.svg); }

.flag-icon-vg {
  background-image: url(../immagini/flags/vg.svg); }

.flag-icon-vi {
  background-image: url(../immagini/flags/vi.svg); }

.flag-icon-vn {
  background-image: url(../immagini/flags/vn.svg); }

.flag-icon-vu {
  background-image: url(../immagini/flags/vu.svg); }

.flag-icon-wf {
  background-image: url(../immagini/flags/wf.svg); }

.flag-icon-ws {
  background-image: url(../immagini/flags/ws.svg); }

.flag-icon-ye {
  background-image: url(../immagini/flags/ye.svg); }

.flag-icon-yt {
  background-image: url(../immagini/flags/yt.svg); }

.flag-icon-za {
  background-image: url(../immagini/flags/za.svg); }

.flag-icon-zm {
  background-image: url(../immagini/flags/zm.svg); }

.flag-icon-zw {
  background-image: url(../immagini/flags/zw.svg); }

.to-top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 0;
  right: 10px;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s 0s, visibility 0s .3s, bottom .3s linear;
  border-radius: 50%;
  background-color: #88c871; }
  @media (min-width: 48em) {
    .to-top {
      right: 20px; } }
  @media (min-width: 64em) {
    .to-top {
      height: 60px;
      width: 60px;
      right: 30px; } }
  .to-top:before {
    content: '\e80d';
    font-family: "casino-it-icons";
    font-size: 1.5rem;
    line-height: 0.9;
    color: #ffffff;
    align-self: center; }
    @media (min-width: 64em) {
      .to-top:before {
        font-size: 2rem; } }
  .to-top--visible:hover, .to-top--fade:hover {
    background-color: #6faa64;
    opacity: 1; }
  .to-top--visible {
    visibility: visible;
    opacity: 1;
    border-radius: 50%;
    bottom: 40px; }
    @media (min-width: 48em) {
      .to-top--visible {
        bottom: 20px; } }
    @media (min-width: 64em) {
      .to-top--visible {
        bottom: 30px; } }
  .to-top--fade {
    opacity: .5; }

.scrollable-table {
  width: 100%;
  overflow-x: auto;
  margin: 20px 0; }
  .scrollable-table .table {
    margin: 0; }

.table {
  display: table;
  margin: 20px 0; }
  .table.bounce {
    animation: bounce--horizontal 1.5s 1; }
    @media (min-width: 30em) {
      .table.bounce {
        animation: none; } }
  .table__row {
    display: table-row; }
    .table__row:last-of-type .table__cell {
      border-bottom: 1px solid #dddfe2; }
  .table__heading {
    background-color: #211e1f;
    display: table-header-group;
    font-weight: bold;
    color: #ffffff; }
  .table__body {
    display: table-row-group; }
  .table__cell, .table__head {
    border-left: 1px solid #dddfe2;
    border-top: 1px solid #dddfe2;
    display: table-cell;
    padding: 3px 10px; }
    .table__cell:last-of-type, .table__head:last-of-type {
      border-right: 1px solid #dddfe2; }

.container-bic .bonus-cards-skeleton {
  display: grid;
  margin-bottom: 2rem;
  grid-template-columns: repeat(1, minmax(0, 1fr)); }

@media (min-width: 1024px) {
  .container-bic .bonus-cards-skeleton {
    grid-template-columns: repeat(2, minmax(0, 1fr)); } }

@media (min-width: 1440px) {
  .container-bic .bonus-cards-skeleton {
    grid-template-columns: repeat(3, minmax(0, 1fr)); } }

.container-bic .bonus-cards-skeleton > .bg-skeleton {
  border: #f2f2f5 1px solid;
  border-radius: 8px;
  height: 506px; }

.container-bic .commercial-toplist-skeleton {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px; }

@media (min-width: 768px) {
  .container-bic .commercial-toplist-skeleton {
    grid-gap: 24px;
    gap: 24px; } }

.container-bic .commercial-toplist-skeleton > .toplist-row-skeleton {
  height: 242px;
  border-radius: 16px; }

.container-bic .bg-skeleton {
  background-color: #f2f2f5;
  background-image: linear-gradient(90deg, #f2f2f5 0%, #ffffff 20%, #f2f2f5 40%, #f2f2f5 100%);
  background-repeat: no-repeat; }

.container-bic .skeleton-animation {
  animation: skeleton-loading 2s infinite;
  animation-timing-function: ease; }

@keyframes skeleton-loading {
  0% {
    background-position: -400px 0; }
  100% {
    background-position: 400px 0; } }

.container-bic .hidden {
  display: none; }

.header {
  border-top: 1px solid #dddfe2;
  border-bottom: 1px solid #dddfe2;
  padding: 20px 0 15px; }
  .header__logo {
    display: inline-block; }
    .header__logo a {
      display: inline-block; }
    .header__logo img {
      width: 100%;
      max-width: 218px; }
  .header__title {
    display: none;
    width: 170px;
    height: 49px;
    border-left: 1px solid #dddfe2;
    padding: 5px 0 0 14px;
    vertical-align: top;
    font-size: 0.75rem;
    line-height: 18px;
    color: #7a7879;
    margin-left: 15px; }
    @media (min-width: 48em) {
      .header__title {
        display: inline-block; } }

.nav {
  background: #fff;
  position: fixed;
  top: 89px;
  right: -300px;
  z-index: 100;
  width: 300px;
  height: calc(100% - 89px);
  border-left: 1px solid #dddfe2;
  transition: all .3s linear;
  overflow: auto; }
  .nav__menu {
    border-top: 1px solid #dddfe2; }
  .nav__item {
    border-bottom: 1px solid #dddfe2;
    display: block;
    font-size: 1.2rem; }
    .nav__item--active .nav__link span {
      transform: rotateX(180deg); }
  .nav__link {
    cursor: default;
    display: block;
    color: #7a7879;
    padding: 20px 15px; }
    .nav__link span {
      width: 30px;
      height: 30px;
      display: block;
      float: right;
      text-align: center;
      padding: 0 5px;
      font-size: 1.5rem;
      transition-duration: 0.2s; }
      @media (min-width: 64em) {
        .nav__link span {
          display: none; } }
    .nav__link:hover {
      color: #7a7879;
      border-left: 4px solid #88c871; }
      @media (min-width: 64em) {
        .nav__link:hover {
          border-left: 0; } }
  .nav__submenu {
    display: none; }
    .nav__submenu li {
      border-top: 1px solid #dddfe2;
      font-size: 0.88rem; }
      .nav__submenu li a {
        display: block;
        color: #7a7879;
        padding: 15px 30px; }
  .nav--open {
    right: 0; }
  .nav__toggle {
    position: relative;
    z-index: 120;
    margin-top: 15px;
    cursor: pointer;
    float: right;
    width: 25px;
    height: 21px; }
    .nav__toggle span, .nav__toggle:before, .nav__toggle:after {
      content: '';
      position: absolute;
      display: block;
      width: 25px;
      height: 3px;
      top: 7px;
      background: #000;
      transition: all .3s linear; }
    .nav__toggle--open {
      position: fixed;
      top: 21px;
      right: 16px; }
    .nav__toggle:before {
      top: 0; }
    .nav__toggle:after {
      top: 14px; }
    .nav__toggle--open .nav__toggle__item {
      opacity: 0; }
    .nav__toggle--open:before {
      top: 5px;
      transform: rotate(45deg); }
    .nav__toggle--open:after {
      top: 5px;
      transform: rotate(-45deg); }
    @media (min-width: 64em) {
      .nav__toggle {
        display: none; } }
  .nav__background {
    position: fixed;
    top: 89px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 90;
    display: none; }
  @media (min-width: 64em) {
    .nav {
      border: 0;
      position: relative;
      right: 0;
      display: inline-block;
      width: auto;
      top: 0;
      transition: none;
      margin-top: 5px;
      overflow: visible; }
      .nav .nav__menu {
        border: 0; }
      .nav .nav__link {
        padding: 10px 13px;
        font-size: 0.88rem; }
      .nav .nav__item {
        position: relative;
        border: 0;
        display: inline-block; }
        .nav .nav__item:hover .nav__submenu {
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
          display: block !important;
          position: absolute;
          top: 38px;
          left: 0;
          width: 180px;
          background-color: #88c871; }
          .nav .nav__item:hover .nav__submenu li a {
            color: #fff;
            padding: 8px 0;
            margin: 0 15px;
            border-bottom: 1px solid #aeda9f; }
          .nav .nav__item:hover .nav__submenu .nav__view-all {
            background: #6faa64; }
            .nav .nav__item:hover .nav__submenu .nav__view-all a {
              border: 0; }
      .nav .nav__submenu li {
        border: 0; } }

.header__icons {
  display: none;
  height: 49px;
  float: right;
  border-left: 1px solid #dddfe2;
  padding-left: 29px; }
  @media (min-width: 75em) {
    .header__icons {
      display: block; } }

.footer {
  background-color: #211e1f;
  margin-top: 50px; }
  .footer .footer__title {
    color: #ffffff;
    display: block;
    margin: 20px 0 5px;
    font-family: "RobotoSlab", sans-serif; }
    @media (min-width: 48em) {
      .footer .footer__title {
        margin: 50px 0 10px; } }
  .footer__description {
    color: #7a7879; }
  .footer__menu {
    margin-bottom: 0; }
    @media (min-width: 48em) {
      .footer__menu {
        margin-bottom: 40px; } }
    .footer__menu li {
      border-bottom: 1px solid #7a7879;
      padding: 7px 0; }
      .footer__menu li a {
        color: #7a7879; }
        .footer__menu li a:hover {
          color: #88c871; }
  .footer__logos {
    margin-top: 20px;
    text-align: left;
    margin-bottom: 20px; }
    @media (min-width: 48em) {
      .footer__logos {
        text-align: right; } }
    .footer__logos img {
      margin: 0 20px 0 0; }
      @media (min-width: 48em) {
        .footer__logos img {
          margin: 0 0 0 20px; } }
  .footer__tagline {
    color: #ffffff;
    font-size: 1.4rem;
    margin-top: 10px; }
    @media (min-width: 48em) {
      .footer__tagline {
        margin-top: 0;
        margin-bottom: 20px; } }
    .footer__tagline:after {
      content: '';
      width: 50px;
      display: block;
      margin-top: 10px;
      border-bottom: 2px solid #88c871; }

.footer-copyright {
  font-size: 0.8rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #211e1f;
  padding: 30px 0; }
  .footer-copyright__text {
    font-size: 0.8rem;
    color: #7a7879; }
    .footer-copyright__text a {
      color: #88c871; }
  .footer-copyright__menu {
    text-align: left;
    margin-bottom: 20px; }
    @media (min-width: 48em) {
      .footer-copyright__menu {
        text-align: right;
        margin-bottom: 0; } }
  .footer-copyright__item {
    display: block; }
    @media (min-width: 48em) {
      .footer-copyright__item {
        display: inline-block;
        margin-left: 8px;
        border-left: 1px solid #7a7879;
        padding-left: 8px; } }
    .footer-copyright__item a {
      color: #7a7879; }
      .footer-copyright__item a:hover {
        color: #88c871; }
    .footer-copyright__item:first-child {
      border: 0;
      margin-left: 0;
      padding-left: 0; }

.faq {
  padding: 30px 0 0; }
  .faq ul {
    margin-top: 20px; }
  .faq__title {
    text-align: center; }
    .faq__title--no-desc {
      margin-bottom: 20px; }
  .faq__description {
    text-align: center;
    margin-bottom: 10px; }
    @media (min-width: 48em) {
      .faq__description {
        width: 90%;
        margin: 0 auto 20px; } }
  .faq__item {
    border: 1px solid #ddd;
    margin-bottom: 20px;
    transition-duration: 0.5s; }
    .faq__item--open {
      background: #211e1f; }
      .faq__item--open .faq__item_title {
        color: #ffffff; }
      .faq__item--open .faq__item_content {
        color: #ffffff; }
      .faq__item--open .icon-down-open {
        transform: rotateX(180deg); }
    .faq__item:last-of-type {
      margin-bottom: 0; }
  .faq__item_title {
    padding: 17px 30px;
    font-family: "RobotoSlab", sans-serif;
    font-weight: bold;
    cursor: pointer;
    position: relative; }
    @media (min-width: 48em) {
      .faq__item_title {
        padding: 17px 50px; } }
    .faq__item_title:before {
      position: absolute;
      color: #88c871;
      width: 16px;
      font-size: 18px;
      left: 6px;
      top: 50%;
      transform: translateY(-50%); }
      @media (min-width: 48em) {
        .faq__item_title:before {
          margin-left: 20px; } }
  .faq__item_content {
    padding: 0 30px 20px; }
  .faq .icon-down-open {
    position: absolute;
    top: 15px;
    right: 20px;
    transition-duration: 0.5s; }
    @media (min-width: 30em) {
      .faq .icon-down-open {
        top: 15px;
        right: 26px; } }
    .faq .icon-down-open:before {
      color: #88c871; }

.blacklist {
  background: #211e1f;
  padding-top: 50px; }
  .blacklist__heading {
    text-align: center;
    color: #ffffff; }
  .blacklist__description {
    text-align: center;
    color: #ffffff; }
    @media (min-width: 48em) {
      .blacklist__description {
        width: 80%;
        display: block;
        margin: 0 auto; } }
  .blacklist .btn--brand {
    width: 95%;
    margin: 30px 0 65px 0; }
    @media (min-width: 48em) {
      .blacklist .btn--brand {
        width: 60%; } }

.blacklist-item__content {
  text-align: left;
  background: #ea3f35;
  margin-top: 50px;
  position: relative; }

.blacklist-item__logo {
  position: absolute;
  top: -20px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  background: #ffffff;
  border-radius: 4px;
  margin: 0 25px;
  border: 1px solid #ddd; }
  .blacklist-item__logo img {
    vertical-align: middle; }

.blacklist-item__name {
  height: 80px;
  margin: 10px 0 0 140px;
  display: inline-block;
  float: left;
  color: #ffffff; }

.blacklist-item__name_text {
  display: block;
  font-size: 1.4rem; }

.blacklist-item__reason {
  clear: left;
  display: block;
  padding: 0 20px 20px; }
  .blacklist-item__reason__title {
    display: block;
    font-family: "RobotoSlab", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    color: #ffffff;
    letter-spacing: 1px;
    margin-bottom: 4px; }
  .blacklist-item__reason ul li {
    color: #ffffff;
    margin-bottom: 4px;
    font-size: 0.85rem; }
    .blacklist-item__reason ul li i {
      color: #f29a8b; }

.blacklist-item .icon {
  color: #feb312; }
  .blacklist-item .icon:before {
    margin-right: 5px;
    margin-left: 0; }
  .blacklist-item .icon--off {
    color: #f29a8b; }

.blacklist-item .blacklist-item__rating {
  font-weight: bold; }

.jackpot {
  background: 50% 50% #211e1f;
  background-size: cover;
  text-align: center;
  padding: 70px 0 50px;
  position: relative; }
  .jackpot__wrapper {
    position: relative; }
  .jackpot__over-cta {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    cursor: pointer; }
  .jackpot__menu {
    margin-top: 30px; }
    .jackpot__menu li:nth-child(n+9) {
      display: none; }
      @media (min-width: 64em) {
        .jackpot__menu li:nth-child(n+9) {
          display: block; } }
  .jackpot__heading {
    color: #ffffff; }
  .jackpot__description {
    color: #ffffff; }
    @media (min-width: 48em) {
      .jackpot__description {
        width: 80%;
        display: block;
        margin: 0 auto; } }
  .jackpot__image {
    width: 100%;
    transition-duration: 0.2s;
    border-radius: 75px;
    border: 2px solid #34495e; }
    .jackpot__image img {
      display: block;
      width: 100%; }
  .jackpot__border {
    position: relative;
    border-radius: 75px;
    border: 0px solid #7bc78f;
    overflow: hidden; }
  .jackpot__play {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    opacity: 0;
    transition-duration: 0.2s; }
    .jackpot__play span {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      margin-top: -12px; }
  .jackpot__top {
    max-width: 150px;
    height: 165px;
    overflow: hidden;
    margin: 0 auto; }
  .jackpot__item {
    display: block;
    margin-bottom: 30px; }
    .jackpot__item:hover .jackpot__image {
      width: 92%;
      margin: 4% 0 0 4%;
      border: 8px solid #34495e; }
    .jackpot__item:hover .jackpot__border {
      border: 3px solid #7bc78f; }
    .jackpot__item:hover .jackpot__play {
      opacity: 1; }
  .jackpot__prize {
    color: #ffffff;
    font-weight: bold; }

.free-games {
  background: #f9fafb;
  padding: 30px 0; }
  .free-games__title {
    background: #ffffff;
    border: 1px solid #dddfe2;
    padding: 10px 30px;
    font-family: "RobotoSlab", sans-serif;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 0;
    position: relative; }
    .free-games__title_arrow {
      position: absolute;
      right: 6px;
      top: 50%;
      margin-top: -15px; }
      @media (min-width: 64em) {
        .free-games__title_arrow {
          display: none; } }
    @media (min-width: 30em) {
      .free-games__title {
        margin: 0 0.5rem; } }
    @media (min-width: 64em) {
      .free-games__title {
        cursor: auto; } }
  .free-games__count {
    color: #7a7879;
    text-align: right;
    margin: 10px 0.5rem 0; }
    @media (min-width: 64em) {
      .free-games__count {
        text-align: left; } }
    .free-games__count span {
      color: #211e1f;
      font-weight: bold; }
  .free-games__heading {
    display: block;
    text-align: center; }
  .free-games__description {
    display: block;
    width: 80%;
    text-align: center;
    margin: 0 auto 30px; }

.games-results {
  margin-top: 10px; }
  .games-results__holder {
    flex: 0 0 auto;
    flex-basis: 100%;
    max-width: 100%;
    margin: 0; }
    @media (min-width: 30em) {
      .games-results__holder {
        margin: 0 0.5rem;
        flex-basis: 46%;
        max-width: 46%; } }
    @media (min-width: 75em) {
      .games-results__holder {
        flex-basis: 31%;
        max-width: 31%; } }
  .games-results__item {
    background: #ffffff;
    padding: 14px 14px 44px;
    margin-bottom: 20px;
    position: relative;
    border: 1px solid #dddfe2; }
    @media (min-width: 48em) {
      .games-results__item {
        padding: 14px;
        min-height: 200px; }
        .games-results__item:hover {
          padding: 14px 14px 54px;
          margin-bottom: 0;
          top: -5px;
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2); }
          .games-results__item:hover .games-results__button, .games-results__item:hover .games-results__fun {
            display: block; }
          .games-results__item:hover .games-results__rating {
            display: block; }
          .games-results__item:hover .games-results__image {
            padding-top: 50%; } }
  .games-results__title {
    display: block;
    font-weight: bold;
    font-family: "RobotoSlab", sans-serif;
    color: #88c871;
    min-height: 2.3em;
    position: relative;
    line-height: 1.1em;
    margin-bottom: 4px; }
    .games-results__title_inner {
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: auto; }
  .games-results__image {
    position: relative;
    overflow: hidden;
    margin-bottom: 5px;
    padding: 60% 0 0 0;
    background: url("../immagini/giochi-gratis-on-error.jpg") 0 0 transparent no-repeat scroll;
    background-size: cover; }
    .games-results__image img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      left: 0; }
  .games-results__type {
    display: block;
    color: #7a7879;
    margin-bottom: 5px; }
  .games-results__rating {
    position: absolute;
    z-index: 12;
    top: 14px;
    left: 14px;
    padding: 2px 10px;
    color: #feb312;
    background: #ffffff; }
    .games-results__rating i:before {
      margin: 0; }
    @media (min-width: 48em) {
      .games-results__rating {
        display: none; } }
  .games-results__button {
    padding: 5px; }
    @media (min-width: 48em) {
      .games-results__button {
        display: none;
        padding: 8px; } }
  .games-results__fun {
    margin-top: 5px;
    text-align: center;
    display: block; }
    @media (min-width: 48em) {
      .games-results__fun {
        display: none; } }

.categories {
  position: relative; }
  .categories__header {
    background: #211e1f;
    color: #ffffff;
    font-size: 1.5rem;
    font-family: "RobotoSlab", sans-serif;
    font-weight: bold;
    padding: 10px 30px;
    display: none; }
    @media (min-width: 64em) {
      .categories__header {
        display: block; } }
  .categories__menu {
    position: absolute;
    top: 55px;
    left: 0;
    z-index: 33;
    overflow: hidden;
    display: none;
    width: 100%;
    flex-wrap: wrap; }
    @media (min-width: 64em) {
      .categories__menu {
        display: flex;
        position: relative;
        top: auto;
        left: auto; } }
    .categories__menu--open {
      display: flex; }
    .categories__menu li.categories__item {
      cursor: pointer;
      background: #ffffff;
      border-left: 1px solid #dddfe2;
      border-bottom: 1px solid #dddfe2;
      width: 50%;
      height: 110px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .categories__menu li.categories__item img {
        max-height: 30px;
        margin-bottom: 10px; }
      .categories__menu li.categories__item span {
        display: block;
        color: #88c871;
        font-family: "RobotoSlab", sans-serif;
        font-size: 0.95rem;
        font-weight: bold; }
      .categories__menu li.categories__item:nth-child(even) {
        border-right: 1px solid #dddfe2; }
      .categories__menu li.categories__item:hover, .categories__menu li.categories__item--active {
        background: #f9fafb; }

.game-collections {
  padding: 20px 25px;
  border-left: 1px solid #dddfe2;
  border-right: 1px solid #dddfe2;
  border-bottom: 1px solid #dddfe2;
  display: none; }
  @media (min-width: 64em) {
    .game-collections {
      display: block; } }
  .game-collections__title {
    display: block;
    border-bottom: 1px solid #dddfe2;
    padding-bottom: 10px;
    font-family: "RobotoSlab", sans-serif;
    font-weight: bold;
    margin-bottom: 8px; }
  .game-collections__menu li a {
    color: #88c871;
    font-family: "RobotoSlab", sans-serif;
    cursor: pointer;
    font-size: 0.9rem; }
    .game-collections__menu li a:hover {
      color: #6faa64; }

.toplist {
  padding-top: 60px;
  padding-bottom: 60px; }
  .toplist--pb-0 {
    padding-bottom: 0; }
  .toplist__title {
    text-align: center;
    margin-bottom: 30px; }
  .toplist--inner .toplist__title {
    margin-bottom: 0; }
  .toplist__description {
    margin: 0 auto; }
    @media (min-width: 64em) {
      .toplist__description {
        width: 80%; } }
    .toplist__description + .toplist__description {
      padding-top: 0; }
  .toplist__row, .toplist__header {
    display: flex; }
  .toplist__holder {
    margin-top: 30px; }
  .toplist__header {
    display: none;
    padding: 0 20px;
    border: 1px solid #dddfe2;
    background: #f9fafb;
    align-items: center; }
    @media (min-width: 48em) {
      .toplist__header {
        display: flex; } }
    .toplist__header p {
      color: #211e1f;
      align-self: center;
      flex-grow: 0;
      font-size: 0.9rem; }
  .toplist__row {
    position: relative;
    color: #7a7879;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-left: 1px solid #dddfe2;
    border-right: 1px solid #dddfe2;
    border-bottom: 1px solid #dddfe2;
    padding: 20px; }
    .toplist__row:hover {
      background-color: #f9fafb; }
      .toplist__row:hover .toplist__btn {
        background-color: #6faa64;
        transform: translateY(3px);
        box-shadow: none; }
      .toplist__row:hover .toplist__bonus {
        -webkit-transform: scale(1.2);
        transform: scale(1.2); }
    .toplist__row--featured {
      background-color: rgba(136, 200, 113, 0.1);
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      border: 1px solid #88c871; }
      .toplist__row--featured:hover {
        background-color: rgba(136, 200, 113, 0.2); }
        .toplist__row--featured:hover .toplist__btn {
          background-color: #dc9601; }
      .toplist__row--featured .toplist__payout,
      .toplist__row--featured .toplist__payout-speed,
      .toplist__row--featured .toplist__games,
      .toplist__row--featured .toplist__payment-options,
      .toplist__row--featured .toplist__platforms,
      .toplist__row--featured .toplist__divider {
        display: block; }
      @media (min-width: 64em) {
        .toplist__row--featured .toplist__games,
        .toplist__row--featured .toplist__payment-options,
        .toplist__row--featured .toplist__platforms {
          display: none; } }
      @media (min-width: 75em) {
        .toplist__row--featured .toplist__games,
        .toplist__row--featured .toplist__payment-options,
        .toplist__row--featured .toplist__platforms {
          display: block; } }
      @media (min-width: 64em) {
        .toplist__row--featured .toplist__divider {
          display: none; } }
  .toplist__label {
    font-size: 1rem;
    color: #211e1f;
    display: block;
    font-weight: normal;
    padding: 0; }
    @media (min-width: 64em) {
      .toplist__label {
        display: none; } }
  .toplist__value {
    padding: 0; }
  .toplist__divider {
    display: none;
    height: 1px;
    background-color: #dddfe2;
    width: 100%;
    margin: 20px; }
  .toplist__position {
    font-size: 1.5rem;
    flex-basis: 10%; }
    @media (min-width: 64em) {
      .toplist__position {
        flex-basis: 7%;
        max-width: 7%; } }
    @media (min-width: 75em) {
      .toplist__position {
        order: 1;
        flex-basis: 4%;
        max-width: 4%; } }
  .toplist__casino {
    flex-basis: 50%; }
    @media (min-width: 64em) {
      .toplist__casino {
        flex-basis: 25%;
        max-width: 25%; } }
    @media (min-width: 75em) {
      .toplist__casino {
        order: 2;
        flex-basis: 14%;
        max-width: 14%; } }
    .toplist__casino i:before {
      font-size: 1.2rem;
      color: #feb312; }
    .toplist__casino img {
      display: block;
      margin: 0 auto 10px;
      max-width: 125px; }
  .toplist__bonus {
    flex-basis: 40%;
    transition: -webkit-transform .2s linear;
    transition: transform .2s linear;
    transition: transform .2s linear,-webkit-transform .2s linear; }
    @media (min-width: 64em) {
      .toplist__bonus {
        flex-basis: 16%;
        max-width: 16%; } }
    @media (min-width: 75em) {
      .toplist__bonus {
        order: 3;
        flex-basis: 15%;
        max-width: 15%; } }
    .toplist__bonus .toplist__value {
      color: #88c871;
      font-weight: bold;
      font-size: 1.5rem; }
  .toplist__payout {
    display: none;
    flex-basis: 30%; }
    @media (min-width: 64em) {
      .toplist__payout {
        display: block;
        flex-basis: 16%;
        max-width: 16%; } }
    @media (min-width: 75em) {
      .toplist__payout {
        order: 4;
        flex-basis: 8%;
        max-width: 8%; } }
  .toplist__payout-speed {
    display: none;
    flex-basis: 40%; }
    @media (min-width: 64em) {
      .toplist__payout-speed {
        display: block;
        flex-basis: 16%;
        max-width: 16%; } }
    @media (min-width: 75em) {
      .toplist__payout-speed {
        order: 5;
        flex-basis: 10%;
        max-width: 10%; } }
  .toplist__games {
    display: none;
    flex-basis: 30%; }
    @media (min-width: 75em) {
      .toplist__games {
        display: block;
        order: 6;
        flex-basis: 8%;
        max-width: 8%; } }
  .toplist__payment-options {
    display: none;
    flex-basis: 70%;
    margin-top: 10px; }
    @media (min-width: 75em) {
      .toplist__payment-options {
        display: block;
        order: 8;
        flex-basis: 15%;
        max-width: 15%;
        margin-top: 0; } }
    .toplist__payment-options__value {
      display: flex;
      justify-content: space-around;
      align-content: center;
      flex-wrap: wrap;
      padding: 10px; }
      .toplist__payment-options__value img {
        align-self: center;
        margin: 4px;
        max-width: 50px; }
      .toplist__payment-options__value img:nth-child(n+4) {
        display: none; }
        @media (min-width: 48em) {
          .toplist__payment-options__value img:nth-child(n+4) {
            display: inline; } }
  .toplist__platforms {
    display: none;
    flex-basis: 30%;
    margin-top: 10px; }
    @media (min-width: 75em) {
      .toplist__platforms {
        display: block;
        order: 7;
        flex-basis: 12%;
        max-width: 12%;
        margin-top: 0; } }
  .toplist__button-block {
    flex-basis: 100%;
    margin-top: 10px; }
    @media (min-width: 64em) {
      .toplist__button-block {
        flex-basis: 20%;
        max-width: 20%;
        margin-top: 0; } }
    @media (min-width: 75em) {
      .toplist__button-block {
        order: 9;
        flex-basis: 14%;
        max-width: 14%; } }
  .toplist__btn {
    max-width: 370px;
    margin: 0 auto;
    display: block; }
  .toplist__review-link {
    color: #8b8dd1;
    margin-top: 10px;
    display: block;
    position: relative;
    z-index: 2; }
  .toplist__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block; }

.reasons {
  background: 50% 50% #77b887;
  background-size: cover;
  padding: 30px 0 50px;
  text-align: center; }
  .reasons-slider {
    display: flex; }
    .reasons-slider--content {
      display: flex; }
    @media (max-width: 48em) {
      .reasons-slider .slick-track {
        display: flex; }
      .slick-track .reasons-slider--content {
        float: none;
        display: flex;
        flex-basis: auto;
        height: auto; } }
  .reasons__heading {
    color: #ffffff; }
  .reasons__description {
    color: #ffffff;
    text-align: center;
    width: 90%;
    margin: 0 auto 30px; }
  .reasons__item {
    position: relative;
    background: #ffffff;
    padding: 50px 30px 40px;
    margin-bottom: 30px; }
  .reasons__image {
    height: 95px; }
    .reasons__image img {
      width: 64px;
      margin: 0 auto; }
  .reasons__title {
    font-weight: bold; }
  .reasons__text {
    font-size: 0.9rem; }

.related {
  padding-top: 30px; }
  @media (min-width: 30em) {
    .related {
      padding-bottom: 30px; } }
  .related__heading {
    text-align: center; }
  .related__description {
    width: 80%;
    margin: 0 auto 30px;
    text-align: center; }
  .related__icon {
    margin: 35px 0 0;
    font-size: 6rem;
    color: #fff;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased; }
  .related__item {
    display: block;
    border: 1px solid #dddfe2;
    padding: 15px 15px 30px;
    text-align: center;
    margin: 0 auto 20px;
    color: #7a7879;
    min-height: 375px;
    position: relative; }
    .related__item .cls-1, .related__item .cls-2, .related__item .cls-3 {
      fill: none;
      stroke: #5fc86b; }
    .related__item .cls-1, .related__item .cls-2, .related__item .cls-3, .related__item .cls-4 {
      stroke-width: 1px; }
    .related__item .cls-bold {
      stroke-width: 2px; }
    .related__item:hover {
      color: #7a7879; }
      .related__item:hover svg .cls-1, .related__item:hover svg .cls-2, .related__item:hover svg .cls-3 {
        stroke: #ffffff !important; }
    .related__item .related__more {
      color: #8b8dd1;
      position: absolute;
      bottom: 15px;
      left: 0;
      text-align: center;
      display: block;
      width: 100%; }
    .related__item:hover .related__more {
      color: #4c4eab; }
    .related__item:hover .related__image {
      background: none; }
      .related__item:hover .related__image span:before {
        color: #88c871; }
  .related__image {
    background-color: #211e1f;
    background-size: cover;
    margin-bottom: 15px;
    width: 100%;
    height: 154px;
    position: relative; }
    .related__image span:before {
      margin: 40px 0 0;
      font-size: 4rem;
      color: #ffffff; }
  .related__title {
    font-weight: bold; }

.relatedBoxSlider .slick-arrow {
  position: absolute;
  top: 120px;
  background: #211e1f;
  display: block;
  border: 0;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  z-index: 1;
  cursor: pointer; }
  .relatedBoxSlider .slick-arrow:focus {
    outline: none; }
  .relatedBoxSlider .slick-arrow:before {
    font-family: "casino-it-icons";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
    width: 100%;
    text-align: center; }

.relatedBoxSlider .slick-prev {
  left: 38px; }
  .relatedBoxSlider .slick-prev:before {
    content: '\f104'; }
  @media (min-width: 30em) {
    .relatedBoxSlider .slick-prev {
      left: 0; } }

.relatedBoxSlider .slick-next {
  right: 38px; }
  .relatedBoxSlider .slick-next:before {
    content: '\f105'; }
  @media (min-width: 30em) {
    .relatedBoxSlider .slick-next {
      right: 0; } }

.trust {
  align-self: center;
  margin-bottom: 40px; }
  .trust__title {
    margin-bottom: 20px;
    text-align: center; }
    @media (min-width: 64em) {
      .trust__title {
        text-align: left; } }
  .trust__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    margin-top: 20px;
    text-align: center; }
    @media (min-width: 30em) {
      .trust__item {
        flex-direction: row;
        text-align: left;
        max-width: 400px;
        margin: 20px auto 0; } }
  .trust__image {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #88c871;
    align-self: center;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-content: center; }
    .trust__image:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 86px;
      height: 86px;
      border-radius: 50%;
      border: 1px solid #dddfe2; }
    .trust__image img {
      max-width: 50%;
      align-self: center; }
  .trust__content {
    align-self: center;
    margin-top: 10px; }
    @media (min-width: 30em) {
      .trust__content {
        margin-top: 0;
        padding-left: 20px; } }
    .trust__content__title {
      padding: 0;
      font-weight: bold; }
    .trust__content__description {
      padding: 0; }
  .trust--hero {
    color: #ffffff;
    display: none; }
    @media (min-width: 75em) {
      .trust--hero {
        display: block; } }
    .trust--hero .trust__image:after {
      background-color: #ffffff;
      z-index: 1;
      border: 1px solid transparent; }
    .trust--hero .trust__image img {
      z-index: 2; }

.hub {
  background-color: #211e1f;
  color: #f9fafb;
  padding: 30px 0;
  /* btn load more */ }
  .hub__title {
    color: #ffffff; }
  .hub--inner {
    padding-top: 10px; }
    .hub--inner .hub__title {
      text-align: center;
      margin: 20px 0 10px; }
    .hub--inner .hub-block__text {
      font-size: 0.9rem; }
  .hub__description {
    text-align: center; }
  .hub__load-more {
    display: inline-block;
    margin: 10px auto; }

.hub-block__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #f9fafb;
  margin: 10px 0; }
  @media (min-width: 30em) {
    .hub-block__item {
      flex-direction: row; } }
  @media (min-width: 48em) {
    .hub-block__item {
      padding: 20px; } }

.hub-block__image {
  text-align: center;
  width: 100%; }
  @media (min-width: 30em) {
    .hub-block__image {
      width: 30%;
      padding: 0 20px; } }
  .hub-block__image img {
    max-height: 150px; }
    @media (min-width: 30em) {
      .hub-block__image img {
        max-height: 100px; } }

.hub-block__content {
  max-height: 0;
  width: 100%;
  visibility: hidden;
  transition: 0.5s;
  opacity: 0;
  padding-top: 20px; }
  @media (min-width: 30em) {
    .hub-block__content {
      max-height: initial;
      display: block;
      visibility: visible;
      margin-top: 0;
      padding-top: 0;
      padding-left: 20px;
      border-top: 0;
      border-left: 1px solid #dddfe2;
      opacity: 1; } }
  .hub-block__content.active {
    max-height: 500px;
    padding-top: 20px;
    margin-top: 10px;
    border-top: 1px solid #dddfe2;
    visibility: visible;
    opacity: 1; }

.hub-block__heading {
  color: #211e1f; }

.hub-block p, .hub-block li {
  color: #7a7879; }

.hub-block__view-more {
  cursor: pointer; }
  @media (min-width: 30em) {
    .hub-block__view-more {
      display: none; } }

.cookies__bar {
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100%;
  background-color: #a7d696;
  color: #ffffff;
  padding: 10px 16px;
  z-index: 99; }
  .cookies__bar:before, .cookies__bar:after {
    content: "";
    display: table; }
  .cookies__bar:after {
    clear: both; }
  .cookies__bar--cookie_note {
    position: relative;
    width: auto;
    float: left;
    font-size: 0.8rem; }
    .cookies__bar--cookie_note a {
      color: #ffffff;
      text-decoration: underline; }
    @media (min-width: 30em) {
      .cookies__bar--cookie_note {
        padding: 6px 0;
        font-size: .9rem; } }
  .cookies__bar__btn {
    color: #ffffff;
    padding: 6px; }
    .cookies__bar__btn--wrapper {
      text-align: center;
      padding: 0; }
      @media (min-width: 30em) {
        .cookies__bar__btn--wrapper {
          padding-right: 1rem;
          padding-left: 1rem; } }
    @media (min-width: 30em) {
      .cookies__bar__btn {
        padding: 6px 10px; } }

@keyframes move-the-object {
  0% {
    width: 25px;
    height: 25px;
    opacity: 1;
    top: -2px;
    left: -2px; }
  99.99% {
    width: 31px;
    height: 31px;
    top: -5px;
    left: -5px;
    opacity: 0; }
  100% {
    border: 1px solid #ffffff;
    opacity: 0.7; } }

.recent-winners {
  max-width: 100%;
  padding-top: 20px; }
  .recent-winners__heading {
    text-align: center; }
  .recent-winners__description {
    text-align: center; }
    @media (min-width: 64em) {
      .recent-winners__description {
        width: 75%;
        margin: 0 auto 20px; } }
  .recent-winners__map {
    display: none; }
    @media (min-width: 48em) {
      .recent-winners__map {
        display: block;
        background: no-repeat;
        position: relative;
        width: 526px;
        height: 681px;
        background-size: initial;
        background-position: initial;
        margin: 0 auto; } }
  .recent-winners__vector-map {
    display: block;
    margin: 0 auto; }
  .recent-winners__point {
    position: absolute;
    top: 100px;
    left: 100px;
    cursor: pointer; }
    .recent-winners__point span {
      display: block;
      width: 23px;
      height: 23px;
      background: #88c871;
      border-radius: 23px; }
      .recent-winners__point span:before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: 25px;
        height: 25px;
        border-radius: 23px;
        border: 1px solid #88c871;
        animation: move-the-object 1s 3 forwards; }
    .recent-winners__point--active span:before {
      width: 31px;
      height: 31px;
      top: -5px;
      left: -5px; }
    .recent-winners__point:hover span:before {
      width: 31px;
      height: 31px;
      top: -5px;
      left: -5px; }

.recent-winners-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  overflow: visible;
  width: 100%;
  height: 0; }
  @media (min-width: 30em) {
    .recent-winners-tooltip {
      width: 0;
      height: 0; } }
  .recent-winners-tooltip__wrapper {
    position: absolute;
    bottom: auto;
    top: 54px;
    left: 0;
    right: auto;
    background: #ffffff;
    border: 1px solid #dddfe2;
    width: 100%;
    padding: 10px;
    box-shadow: 3px -3px 3px 1px rgba(0, 0, 0, 0.01); }
    @media (min-width: 30em) {
      .recent-winners-tooltip__wrapper {
        left: 10px;
        width: 270px; } }
    .recent-winners-tooltip__wrapper:after, .recent-winners-tooltip__wrapper:before {
      content: '';
      display: none;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 40px 0 0 40px;
      border-color: transparent transparent transparent #ffffff;
      position: absolute;
      top: -40px;
      left: 0; }
      @media (min-width: 30em) {
        .recent-winners-tooltip__wrapper:after, .recent-winners-tooltip__wrapper:before {
          display: block; } }
    .recent-winners-tooltip__wrapper:before {
      border-width: 42px 0 0 42px;
      border-color: transparent transparent transparent #dddfe2;
      top: -43px;
      left: -1px; }
    @media (min-width: 30em) {
      .recent-winners-tooltip__wrapper--right {
        left: auto;
        right: -10px; }
        .recent-winners-tooltip__wrapper--right:after, .recent-winners-tooltip__wrapper--right:before {
          border-width: 0 0 40px 40px;
          border-color: transparent transparent #ffffff transparent;
          left: auto;
          bottom: auto;
          top: -40px;
          right: 0; }
        .recent-winners-tooltip__wrapper--right:before {
          border-width: 0 0 42px 42px;
          border-color: transparent transparent #dddfe2 transparent;
          top: -43px;
          right: -1px; }
      .recent-winners-tooltip__wrapper--bottom {
        top: auto;
        bottom: 30px;
        left: 10px;
        right: auto; }
        .recent-winners-tooltip__wrapper--bottom:after, .recent-winners-tooltip__wrapper--bottom:before {
          border-width: 40px 40px 0 0;
          border-color: #ffffff transparent transparent transparent;
          top: auto;
          bottom: -40px;
          left: 0; }
        .recent-winners-tooltip__wrapper--bottom:before {
          border-width: 42px 42px 0 0;
          border-color: #dddfe2 transparent transparent transparent;
          top: auto;
          bottom: -43px;
          left: -1px; }
      .recent-winners-tooltip__wrapper--right-bottom {
        bottom: 30px;
        left: auto;
        top: auto;
        right: -10px; }
        .recent-winners-tooltip__wrapper--right-bottom:after, .recent-winners-tooltip__wrapper--right-bottom:before {
          border-width: 0 40px 40px 0;
          border-color: transparent #ffffff transparent transparent;
          bottom: -40px;
          top: auto;
          left: auto;
          right: 0; }
        .recent-winners-tooltip__wrapper--right-bottom:before {
          border-width: 0 42px 42px 0;
          border-color: transparent #dddfe2 transparent transparent;
          bottom: -43px;
          right: -1px; } }
  .recent-winners-tooltip__name {
    background: #88c871;
    display: block;
    padding: 12px 20px;
    font-size: 1.5rem;
    color: #ffffff;
    font-weight: bold; }
  .recent-winners-tooltip__menu {
    padding: 20px;
    overflow: hidden; }
    .recent-winners-tooltip__menu dt {
      float: left;
      clear: left;
      color: #7a7879;
      padding-right: 5px; }
    .recent-winners-tooltip__menu dd {
      color: #211e1f;
      text-align: left;
      float: left;
      font-weight: 700; }
      .recent-winners-tooltip__menu dd.casino {
        clear: left;
        margin-bottom: 20px; }

.jsmaps-wrapper circle {
  visibility: hidden;
  opacity: 0;
  transition: all .4s;
  fill: #88c871; }
  .jsmaps-wrapper circle.is-visible {
    display: block;
    visibility: visible;
    opacity: 1;
    fill: #8b8dd1; }
  .jsmaps-wrapper circle.is-visible:hover, .jsmaps-wrapper circle.is-active {
    fill: #88c871; }

.jsmaps-tooltip {
  display: none !important; }

.hero {
  background: 50% 50% #211e1f url(../immagini/bg/slider-bg.jpg) no-repeat;
  background-size: cover;
  padding-bottom: 20px; }
  @media (min-width: 48em) {
    .hero {
      padding-bottom: 0; } }
  .hero__heading {
    color: #ffffff;
    margin-top: 30px; }
    @media (min-width: 64em) {
      .hero__heading {
        margin-top: 50px; } }
  .hero__subheading {
    font-size: 1rem;
    font-weight: 700;
    color: #ffffff;
    margin-top: 10px; }
  .hero__description {
    color: #ffffff;
    font-size: 1rem; }
  .hero__list {
    color: #ffffff; }
  .hero .btn, .hero .btn--brand, .hero .btn--brand-secondary, .hero .btn--brand-white {
    margin-bottom: 30px; }
    @media (min-width: 75em) {
      .hero .btn, .hero .btn--brand, .hero .btn--brand-secondary, .hero .btn--brand-white {
        margin-bottom: 70px; } }
  .hero__tagline {
    color: #ffffff;
    font-size: 1.3rem;
    font-weight: lighter;
    margin-bottom: 40px; }
    .hero__tagline:after {
      content: '';
      display: block;
      width: 40px;
      border-bottom: 2px solid #88c871;
      margin-top: 10px; }
  .hero__icons {
    overflow: scroll; }
    @media (min-width: 48em) {
      .hero__icons {
        overflow: auto; } }
  .hero__logo-menu {
    margin-bottom: 30px;
    display: inline-block;
    width: 700px; }
    @media (min-width: 48em) {
      .hero__logo-menu {
        width: auto; } }
    .hero__logo-menu li {
      height: 58px;
      line-height: 58px;
      float: left;
      padding: 0 15px;
      border-right: 1px solid rgba(255, 255, 255, 0.2); }
      .hero__logo-menu li img {
        vertical-align: middle; }
      .hero__logo-menu li:last-child {
        border-right: 0; }
      @media (min-width: 64em) {
        .hero__logo-menu li {
          padding: 0 10px;
          margin-bottom: 0; } }
      @media (min-width: 75em) {
        .hero__logo-menu li {
          padding: 0 15px; } }

.inner-header {
  background: #f9fafb;
  box-shadow: inset 0 4px 7px -4px rgba(0, 0, 0, 0.2);
  position: relative;
  padding-bottom: 50px; }
  .inner-header__heading {
    color: #211e1f;
    margin-top: 20px;
    font-family: "RobotoSlab", sans-serif; }
    @media (min-width: 48em) {
      .inner-header__heading {
        margin-top: 50px; } }
  .inner-header__description {
    font-size: 1rem; }
  .inner-header__image {
    display: flex;
    margin: 10px auto 0;
    justify-content: center; }
    @media (min-width: 64em) {
      .inner-header__image {
        margin-top: 90px;
        display: block; } }
    .inner-header__image img {
      border-radius: 50%;
      background-color: #fff;
      box-sizing: content-box;
      width: 115px;
      align-self: center;
      border: 2px solid #73ca74;
      padding: 25px; }
      @media (min-width: 64em) {
        .inner-header__image img {
          width: 200px; } }
    .inner-header__image--corner {
      display: inline-block;
      float: right;
      margin: 0 0 20px 20px; }
      .inner-header__image--corner img {
        padding: 0;
        border: none; }
        @media (min-width: 64em) {
          .inner-header__image--corner img {
            border: 2px solid #73ca74;
            padding: 25px;
            margin: 10px 0 2px 10px; } }
    .inner-header__image--casino-terrestri {
      padding-top: 55px;
      text-align: center; }
      .inner-header__image--casino-terrestri img {
        padding: 2px;
        border: 1px solid #73ca74; }
        @media (max-width: 64em) {
          .inner-header__image--casino-terrestri img {
            max-width: 300px; } }
      @media (max-width: 64em) {
        .inner-header__image--casino-terrestri {
          padding-top: 23px; } }
  .inner-header__button {
    width: 100%;
    margin: 20px 0 30px; }
    @media (min-width: 64em) {
      .inner-header__button {
        margin: 40px 0 80px;
        width: 80%; } }
  .inner-header:after {
    content: '';
    background: url(../immagini/arrow.png);
    position: absolute;
    bottom: -40px;
    right: 0;
    left: 0;
    margin: auto;
    width: 20px;
    height: 89px;
    background-size: 20px;
    background-repeat: no-repeat; }
  .inner-header--no-arrow:after {
    display: none; }
  .inner-header__template {
    max-width: 100%;
    border: 2px solid #88c871;
    padding: 15px 10px 25px;
    margin: 15px 0;
    box-shadow: 0 1px 7px 0px rgba(0, 0, 0, 0.2); }
    .inner-header__template .item__rating-right-side {
      float: right;
      text-align: right; }
    .inner-header__template .item__diff--end-rating {
      margin-left: -4px;
      color: #7a7879; }
    .inner-header__template .item__rating li {
      padding: 10px 0;
      border-top: 1px solid #dddfe2;
      border-bottom: 1px solid #dddfe2; }
    .inner-header__template .item__rating li:last-child {
      border-top: 0px; }
    .inner-header__template .item__game-information {
      margin-top: 20px; }
      .inner-header__template .item__game-information li {
        padding-top: 5px; }
      .inner-header__template .item__game-information .item__game-info-key,
      .inner-header__template .item__game-information .item__game-info-value {
        width: 49%;
        display: inline-block; }
      .inner-header__template .item__game-information .item__game-info-key {
        color: #211e1f; }
      .inner-header__template .item__game-information .item__game-info-value {
        color: #7a7879; }
    .inner-header__template .item__white-bg-logo {
      padding: 20px 5px;
      background: #fff;
      text-align: center;
      border: 1px solid #dddfe2;
      border-radius: 5px;
      margin-bottom: 20px; }
      .inner-header__template .item__white-bg-logo a {
        margin-top: 10px;
        display: block; }
      .inner-header__template .item__white-bg-logo--casino {
        padding: 30px 0;
        float: left;
        margin-right: 10px; }
        .inner-header__template .item__white-bg-logo--casino img {
          max-width: 100px; }
      .inner-header__template .item__white-bg-logo--casino + span {
        position: relative;
        top: 30px;
        font-size: 14px; }
    .inner-header__template .item__play-btn-container {
      clear: both; }

.toplist__holder--inner {
  border-top: 1px solid #dddfe2;
  display: none;
  margin-bottom: 30px; }
  @media (min-width: 64em) {
    .toplist__holder--inner {
      display: block; } }
  .toplist__holder--inner .toplist__label {
    display: block;
    font-size: 0.85rem; }

.top-3 {
  align-items: flex-start;
  margin-top: 30px;
  max-width: 100%; }
  .top-3__wrapper {
    width: 100%;
    margin: 0; }
  .top-3__main-link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
    .top-3__main-link:focus {
      outline: 1px solid #ffffff; }
  .top-3__item {
    position: relative;
    color: #7a7879;
    padding: 15px 15px 30px;
    border: 1px solid #dddfe2;
    margin-top: 35px;
    width: 32%;
    max-width: 400px; }
    .top-3__item--1 {
      width: 36%; }
    @media (min-width: 48em) {
      .top-3__item {
        max-width: initial; }
        .top-3__item--2 {
          border-right: 0; } }
    .top-3__item--3 {
      border-left: 0; }
    .top-3__item:hover {
      background-color: #f9fafb;
      color: #7a7879; }
      .top-3__item:hover .top-3__btn .btn, .top-3__item:hover .top-3__btn .btn--brand, .top-3__item:hover .top-3__btn .btn--brand-secondary, .top-3__item:hover .top-3__btn .btn--brand-white {
        background: #88c871; }
      .top-3__item:hover .top-3__bonus span {
        -webkit-transform: scale(1.2);
        transform: scale(1.2); }
    .top-3__item--featured {
      border: 2px solid #88c871;
      background: rgba(136, 200, 113, 0.1);
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      margin-top: 0; }
      .top-3__item--featured .top-3__position {
        background: #211e1f;
        color: #ffffff; }
        .top-3__item--featured .top-3__position__number:after {
          display: none; }
      .top-3__item--featured:hover {
        background-color: rgba(136, 200, 113, 0.2); }
        .top-3__item--featured:hover .top-3__btn .btn, .top-3__item--featured:hover .top-3__btn .btn--brand, .top-3__item--featured:hover .top-3__btn .btn--brand-secondary, .top-3__item--featured:hover .top-3__btn .btn--brand-white {
          background-color: #6faa64;
          transform: translateY(3px);
          box-shadow: none; }
  .top-3__btn {
    display: none; }
    @media (min-width: 30em) {
      .top-3__btn {
        width: 45%;
        margin-top: 10px; } }
    @media (min-width: 48em) {
      .top-3__btn {
        display: block;
        width: 100%; } }
    .top-3__btn--bottom {
      display: block; }
    .top-3__btn .btn, .top-3__btn .btn--brand, .top-3__btn .btn--brand-secondary, .top-3__btn .btn--brand-white {
      max-width: 370px;
      margin: 0 auto; }
  .top-3__position {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dddfe2;
    font-size: 1.5rem;
    vertical-align: middle;
    position: relative;
    padding: 10px 5px; }
    .top-3__position__number {
      align-self: center; }
      .top-3__position__number:after {
        content: "";
        width: 60px;
        border-bottom: 2px solid #88c871;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%); }
    .top-3__position__text {
      align-self: center;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      padding: 0 10px;
      margin-left: 10px;
      font-size: 0.8rem;
      text-transform: uppercase; }
  .top-3__image {
    margin-top: 10px; }
    .top-3__image img {
      margin: 0 auto; }
  .top-3__rating {
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center; }
    @media (min-width: 30em) {
      .top-3__rating {
        width: 50%;
        padding: 10px 0;
        border-right: 1px solid #dddfe2;
        border-top: 1px solid #dddfe2; } }
    @media (min-width: 48em) {
      .top-3__rating {
        width: 100%;
        padding: 0;
        border: none; } }
    .top-3__rating__stars {
      align-self: center; }
    .top-3__rating__value {
      align-self: center;
      padding: 0; }
    .top-3__rating i:before {
      font-size: 1rem;
      color: #feb312; }
    .top-3__rating span {
      font-weight: bold; }
  .top-3__review {
    display: block;
    color: #8b8dd1;
    margin: 20px auto 0;
    padding-bottom: 10px;
    font-size: 0.85rem;
    position: relative;
    z-index: 9; }
    .top-3__review--bottom {
      width: 100%;
      z-index: 2;
      margin-top: 10px; }
      @media (min-width: 30em) {
        .top-3__review--bottom {
          width: 45%; } }
      @media (min-width: 48em) {
        .top-3__review--bottom {
          display: none; } }
  .top-3__bonus {
    padding: 10px 0 0; }
    @media (min-width: 30em) {
      .top-3__bonus {
        width: 50%;
        border-top: 1px solid #dddfe2;
        padding: 10px 0; } }
    .top-3__bonus span {
      font-size: 1.5rem;
      color: #88c871;
      display: block;
      font-weight: 700;
      transition: -webkit-transform 0.2s linear;
      transition: transform 0.2s linear;
      transition: transform 0.2s linear, -webkit-transform 0.2s linear; }
    .top-3__bonus p {
      padding: 0; }
  .top-3__game-image {
    display: none; }
    @media (min-width: 30em) {
      .top-3__game-image {
        display: block; } }
    @media (min-width: 64em) {
      .top-3__game-image {
        margin-top: 20px; } }
    .top-3__game-image img {
      margin: 0 auto; }
  .top-3__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-top: 6px; }
    @media (min-width: 30em) {
      .top-3__row {
        flex-direction: row; } }
    .top-3__row--btn {
      justify-content: space-between; }
  .top-3__label {
    width: 100%;
    text-align: center;
    font-size: 0.82rem;
    padding: 0 10px 0 0;
    align-self: center;
    padding-bottom: 2px; }
    @media (min-width: 30em) {
      .top-3__label {
        text-align: right;
        width: 50%; } }
  .top-3__value {
    width: 100%;
    padding: 0;
    align-self: center;
    text-align: center; }
    @media (min-width: 30em) {
      .top-3__value {
        text-align: left;
        width: 50%; } }
    .top-3__value--banking {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-content: center; }
      @media (min-width: 30em) {
        .top-3__value--banking {
          justify-content: flex-start; } }
      .top-3__value--banking img {
        margin-right: 4px;
        align-self: center; }
  .top-3 .slick-arrow {
    position: absolute;
    top: 120px;
    background: #211e1f;
    display: block;
    border: 0;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    z-index: 1;
    cursor: pointer; }
    .top-3 .slick-arrow:focus {
      outline: none; }
    .top-3 .slick-arrow:before {
      font-family: "casino-it-icons";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #ffffff;
      width: 100%;
      text-align: center; }
  .top-3 .slick-prev {
    left: 28px; }
    .top-3 .slick-prev:before {
      content: "\f104"; }
  .top-3 .slick-next {
    right: 28px; }
    .top-3 .slick-next:before {
      content: "\f105"; }
  .top-3 .slick-disabled {
    background: #dddfe2; }

.movies {
  background: #f9fafb; }
  .movies__poster {
    background: #ffffff;
    border: 1px solid #dddfe2;
    padding: 4px;
    float: left; }
    .movies__poster img {
      display: block;
      width: 150px; }
  .movies p {
    padding: 5px 0; }
  .movies__quote {
    margin: 10px 0; }
  .movies h3 {
    display: inline-block;
    padding-top: 0; }
  .movies__play {
    color: #ff0000;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px; }
    .movies__play:before {
      font-size: 2rem; }
    .movies__play:hover {
      color: #7a7879; }

.trust-inner__item {
  position: relative;
  border: 1px solid #dddfe2;
  margin-bottom: 10px;
  padding: 25px; }
  @media (min-width: 30em) {
    .trust-inner__item {
      padding-left: 100px; } }
  .trust-inner__item h3 {
    font-size: 1.2rem; }
  .trust-inner__item p {
    font-size: 0.9rem; }

.trust-inner__image {
  float: left;
  margin-right: 20px;
  height: 72px;
  width: 65px; }
  @media (min-width: 30em) {
    .trust-inner__image {
      float: none;
      margin-right: 0;
      position: absolute;
      top: 30px;
      left: 20px; } }
  .trust-inner__image img {
    display: block; }

.sitemap {
  padding: 30px 0; }
  .sitemap__menu {
    column-count: 1; }
    @media (min-width: 48em) {
      .sitemap__menu {
        column-count: 2; } }
    @media (min-width: 75em) {
      .sitemap__menu {
        column-count: 3; } }
  .sitemap__item {
    break-inside: avoid;
    background: #ffffff;
    padding: 5px 5px 10px;
    border: 2px solid #dddfe2;
    width: 100%;
    margin-bottom: 20px; }
    .sitemap__item ul li a {
      padding: 2px 10px;
      color: #7a7879; }
      .sitemap__item ul li a:hover {
        padding-left: 6px;
        color: #211e1f;
        border-left: 4px solid #88c871; }
    .sitemap__item .icon-angle-down {
      display: none; }
  .sitemap__link {
    padding: 10px;
    display: block;
    background: #211e1f;
    color: #ffffff;
    font-size: 1.2rem;
    font-family: "RobotoSlab", sans-serif;
    margin-bottom: 5px; }
    .sitemap__link:hover {
      color: #ffffff; }

.contact_form {
  width: 100%;
  max-width: 840px;
  margin: 25px auto;
  overflow: hidden;
  background: white;
  border-radius: 5px;
  position: relative; }
  .contact_form header,
  .contact_form footer {
    min-height: 75px;
    background: rgba(0, 0, 0, 0.05);
    line-height: 75px;
    padding: 0 20px;
    border-radius: 5px 5px 0 0; }
    .contact_form header h3,
    .contact_form footer h3 {
      font-size: 20px;
      text-transform: uppercase;
      line-height: 75px;
      margin: 0;
      padding-top: 0;
      color: #6faa64; }
  .contact_form footer {
    border-radius: 0 0 5px 5px; }
    .contact_form footer:before, .contact_form footer:after {
      content: "";
      display: table; }
    .contact_form footer:after {
      clear: both; }
  .contact_form input,
  .contact_form textarea {
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
    margin: 0;
    border: 0;
    padding: 5px 5px 5px 20px;
    display: block;
    width: 100%;
    overflow: hidden;
    height: 50px;
    font-size: 1rem; }
    .contact_form input:focus,
    .contact_form textarea:focus {
      outline: none;
      background-color: rgba(254, 179, 18, 0.1);
      color: rgba(33, 30, 31, 0.7); }
    .contact_form input.validation--error,
    .contact_form textarea.validation--error {
      background: rgba(254, 179, 18, 0.3);
      position: relative; }
  .contact_form .first_last_container {
    display: block; }
    @media (min-width: 64em) {
      .contact_form .first_last_container {
        display: flex;
        flex-wrap: nowrap; } }
  .contact_form__firstname, .contact_form__lastname {
    width: 100%;
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0.1); }
  .contact_form__lastname {
    border-top: 0; }
    @media (min-width: 64em) {
      .contact_form__lastname {
        border-left: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1); } }
  .contact_form__email {
    width: 100%;
    border-top: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .contact_form__message {
    width: 100%; }
    .contact_form__message textarea {
      width: 100%;
      height: 200px;
      resize: none;
      padding: 20px;
      border-top: 0;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .contact_form__btn {
    width: 100%;
    max-width: 320px;
    height: 50px;
    margin: 12px auto; }
    @media (min-width: 64em) {
      .contact_form__btn {
        float: right;
        margin: 12px 20px 0 12px; } }
    .contact_form__btn:focus {
      outline: none; }
  .contact_form img {
    max-width: 100%; }
  .contact_form .loader {
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.2);
    top: 0;
    left: 0;
    z-index: 2;
    text-align: center;
    cursor: progress; }
    .contact_form .loader i {
      font-size: 50px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      color: #dc9601; }
  .contact_form__success {
    width: 100%;
    max-width: 840px;
    margin: 25px auto;
    overflow: hidden;
    background: white;
    border-radius: 5px; }
    .contact_form__success header {
      min-height: 75px;
      background: rgba(0, 0, 0, 0.05);
      line-height: 75px;
      padding: 0 20px;
      border-radius: 5px 5px 0 0; }
      .contact_form__success header h3 {
        padding-top: 0;
        font-size: 24px;
        text-transform: uppercase;
        color: #88c871;
        line-height: 75px;
        margin: 0; }
    .contact_form__success p {
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 20px; }

.iziToast.iziToast-color-red {
  background: rgba(254, 179, 18, 0.9);
  border-color: rgba(33, 30, 31, 0.9); }

.free-games-library {
  padding-top: 20px;
  margin: 0 -.5rem; }
  @media (min-width: 48em) {
    .free-games-library {
      margin: 0; } }
  .free-games-library__header {
    background-color: #6faa64;
    padding: 15px;
    color: #ffffff;
    display: flex;
    flex-direction: column; }
    @media (min-width: 30em) {
      .free-games-library__header {
        flex-direction: row; } }
    .free-games-library__header__title,
    .free-games-library__header a {
      text-align: center; }
      @media (min-width: 30em) {
        .free-games-library__header__title,
        .free-games-library__header a {
          flex-basis: 50%;
          max-width: 50%;
          align-self: center; } }
    .free-games-library__header__title {
      color: #ffffff; }
      @media (min-width: 30em) {
        .free-games-library__header__title {
          text-align: left; } }
    .free-games-library__header a {
      color: #ffffff;
      opacity: 0.7;
      text-decoration: underline;
      font-size: 12px; }
      @media (min-width: 30em) {
        .free-games-library__header a {
          text-align: right; } }
  .free-games-library__filter-bar {
    background-color: #feb312;
    padding: 10px;
    display: flex;
    color: #ffffff;
    flex-wrap: wrap; }
    @media (min-width: 48em) {
      .free-games-library__filter-bar {
        padding: 20px;
        align-items: center; } }
    .free-games-library__filter-bar .filter-sort,
    .free-games-library__filter-bar .filter-by {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
      .free-games-library__filter-bar .filter-sort .sort-by-label,
      .free-games-library__filter-bar .filter-sort span,
      .free-games-library__filter-bar .filter-by .sort-by-label,
      .free-games-library__filter-bar .filter-by span {
        width: 100%; }
        @media (min-width: 75em) {
          .free-games-library__filter-bar .filter-sort .sort-by-label,
          .free-games-library__filter-bar .filter-sort span,
          .free-games-library__filter-bar .filter-by .sort-by-label,
          .free-games-library__filter-bar .filter-by span {
            width: auto;
            margin-right: 6px; } }
      .free-games-library__filter-bar .filter-sort select,
      .free-games-library__filter-bar .filter-by select {
        width: 100%;
        background-color: #ffffff;
        border: none;
        border-radius: 0;
        margin-top: 4px;
        height: 40px;
        color: #88c871;
        font-size: 12px;
        margin-bottom: 5px; }
        @media (min-width: 30em) {
          .free-games-library__filter-bar .filter-sort select,
          .free-games-library__filter-bar .filter-by select {
            width: 48%;
            font-size: 14px;
            height: 30px;
            margin-bottom: 0; } }
    @media (min-width: 48em) {
      .free-games-library__filter-bar .filter-sort {
        width: 33.33333333333%; } }
    @media (min-width: 48em) {
      .free-games-library__filter-bar .filter-sort select {
        width: 60%; } }
    .free-games-library__filter-bar .filter-by {
      margin-top: 6px; }
      @media (min-width: 48em) {
        .free-games-library__filter-bar .filter-by {
          width: 50%;
          margin-top: 0; } }
      @media (min-width: 48em) {
        .free-games-library__filter-bar .filter-by span {
          display: inline-block; } }
      @media (min-width: 75em) {
        .free-games-library__filter-bar .filter-by select {
          width: 40%;
          margin-top: 0; } }
      @media (min-width: 30em) {
        .free-games-library__filter-bar .filter-by select:first-of-type {
          margin-right: 4%; } }
    .free-games-library__filter-bar a {
      color: #ea3f35;
      text-align: right;
      display: block;
      font-size: 12px;
      margin: 5px auto 0; }
      @media (min-width: 48em) {
        .free-games-library__filter-bar a {
          width: 16.6666666%; } }
      .free-games-library__filter-bar a:hover {
        color: #d62116; }
  .free-games-library__body {
    background-color: #f9fafb;
    padding: 0 0 20px;
    min-height: 60px; }
    .free-games-library__body .btn-red {
      padding: 10px 30px; }
    .free-games-library__body .filter_results {
      text-align: center;
      padding-top: 20px; }
      .free-games-library__body .filter_results--white {
        color: #ffffff; }
  .free-games-library__games {
    display: flex;
    flex-direction: column; }
    .free-games-library__games.slick {
      flex-direction: row; }
    @media (min-width: 48em) {
      .free-games-library__games {
        flex-direction: column; } }
    .free-games-library__games .games_row {
      display: flex;
      justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
      margin: 10px 0; }
  .free-games-library__main-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: block; }
    .free-games-library__main-image--wrapper {
      border-radius: 3px;
      border: 3px solid #fff;
      width: 100%;
      height: 120px;
      max-width: 190px;
      max-height: 120px;
      margin: 0 auto 10px;
      position: relative;
      display: block;
      background: url("../immagini/lazy-3x2.png") 0 50% scroll no-repeat white;
      background-size: contain;
      overflow: hidden; }
      @media (min-width: 64em) {
        .free-games-library__main-image--wrapper {
          padding: 60% 0 0;
          height: auto; } }
  .free-games-library__item {
    padding: 10px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    width: 90%;
    margin: 10px 5%;
    text-align: center;
    position: relative;
    padding-bottom: 54px;
    background-color: rgba(9, 146, 184, 0.8); }
    @media (min-width: 30em) {
      .free-games-library__item {
        width: 45%;
        margin: 10px 2.5%; } }
    @media (min-width: 64em) {
      .free-games-library__item {
        width: 22%;
        margin: 20px 1%; } }
    .free-games-library__item__body .rating-stars {
      height: 18px;
      width: 88px; }
      .free-games-library__item__body .rating-stars .star-empty,
      .free-games-library__item__body .rating-stars .star-full {
        width: 88px; }
        .free-games-library__item__body .rating-stars .star-empty img,
        .free-games-library__item__body .rating-stars .star-full img {
          width: 88px; }
    .free-games-library__item__body .reviews-count {
      margin: 10px 0;
      text-align: center;
      font-size: 12px;
      color: #ffffff;
      font-weight: 400; }
    .free-games-library__item_cta {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      z-index: 20; }
      .free-games-library__item_cta .btn-red--wrapper {
        display: block;
        position: absolute;
        padding: 0 10px 10px;
        bottom: 0;
        left: 0;
        width: 100%; }
      .free-games-library__item_cta .btn-red {
        width: 100%;
        padding: 10px; }
  .free-games-library__pagination {
    display: block;
    margin: 0 auto;
    text-align: center;
    position: relative;
    color: #7a7879;
    font-family: "Lato", sans-serif; }
    .free-games-library__pagination .button-wrapper {
      display: block;
      width: auto;
      position: relative;
      padding: 0 6.3rem; }
      @media (min-width: 30em) {
        .free-games-library__pagination .button-wrapper {
          display: inline-block; } }
      .free-games-library__pagination .button-wrapper.loading {
        margin: 60px 0; }
    .free-games-library__pagination .slick-dots {
      display: inline-block;
      width: auto;
      padding: 0 0.4rem;
      position: relative;
      bottom: auto; }
      .free-games-library__pagination .slick-dots li {
        display: inline-block;
        height: auto;
        width: auto;
        margin: 0; }
        .free-games-library__pagination .slick-dots li button {
          display: none;
          height: auto;
          width: auto;
          background-color: transparent;
          color: #7a7879;
          font-family: "Lato", sans-serif; }
          .free-games-library__pagination .slick-dots li button:hover {
            color: #ffffff; }
          @media (min-width: 30em) {
            .free-games-library__pagination .slick-dots li button.active-friends {
              display: inline-block; } }
          .free-games-library__pagination .slick-dots li button:before {
            content: normal;
            display: none; }
      .free-games-library__pagination .slick-dots .slick-active.active-friends {
        display: none; }
      .free-games-library__pagination .slick-dots .slick-active .page-num {
        display: none; }
      @media (min-width: 30em) {
        .free-games-library__pagination .slick-dots .slick-active.active-friends {
          display: inline-block; }
        .free-games-library__pagination .slick-dots .slick-active .page-num {
          display: block;
          background-color: #6faa64;
          color: #ffffff; } }
    .free-games-library__pagination .next,
    .free-games-library__pagination .prev,
    .free-games-library__pagination .page-num {
      transition: all .4s;
      border: 0 none;
      padding: 10px;
      height: auto;
      width: auto;
      font-size: 1rem;
      display: inline-block;
      line-height: 0.9;
      margin: 0 0.1rem;
      cursor: pointer;
      font-family: "Lato", sans-serif;
      font-weight: 400; }
      .free-games-library__pagination .next:hover,
      .free-games-library__pagination .prev:hover,
      .free-games-library__pagination .page-num:hover {
        background-color: #588d4e;
        border-color: #588d4e; }
      .free-games-library__pagination .next.current,
      .free-games-library__pagination .prev.current,
      .free-games-library__pagination .page-num.current {
        background-color: #6faa64;
        border-color: #6faa64; }
    .free-games-library__pagination .prev,
    .free-games-library__pagination .next {
      background-color: #211e1f;
      color: #ffffff;
      padding: 10px 15px;
      position: absolute;
      top: 0;
      min-width: 68px; }
    .free-games-library__pagination .next {
      left: auto;
      right: 0; }
    .free-games-library__pagination .prev {
      left: 0; }

/* Modal */
/* ModalFreeGame */
/*gameinfo*/
.btn.btn--full-width, .btn--full-width.btn--brand, .btn--full-width.btn--brand-secondary, .btn--full-width.btn--brand-white {
  min-width: 80%; }

.gamePopupImg {
  height: 420px; }

.gameInfo {
  background-color: #fff; }
  .gameInfo:before {
    content: '';
    position: absolute;
    top: 4px;
    right: 23px;
    z-index: 2;
    display: block;
    width: 78px;
    height: 81px; }
  .gameInfoHeader {
    padding: 20px;
    font-weight: 700;
    font-size: 20px;
    margin: 0;
    position: relative;
    color: #88c871;
    background-color: #ffffff; }
    .gameInfoHeader__name {
      color: #ea3f35;
      line-height: 128%;
      font-size: 18px;
      width: auto;
      font-weight: 400;
      text-decoration: none;
      display: inline-block;
      padding-right: 20px; }
  .gameInfoRating {
    font-weight: 400;
    font-size: 1rem;
    display: inline-block; }
    .gameInfoRating__message {
      font-size: 0.8rem;
      display: inline-block;
      width: auto;
      vertical-align: baseline; }
  .gameInfoImage {
    position: relative; }
    @media (max-height: 920px) {
      .gameInfoImage iframe {
        max-height: 454px; } }
    .gameInfoImage img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: 100%;
      max-height: 100%;
      margin: auto; }
  .gameInfoLogo {
    float: left; }
  .gameInfoFooter {
    text-align: center;
    padding: 0; }
    .gameInfoFooter .claim-now-link {
      min-width: 300px;
      text-align: center;
      float: none; }
    .gameInfoFooter__col--top span {
      font-size: 16px;
      display: inline-block;
      margin: 6px auto;
      background-color: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.8);
      border-radius: 20px;
      padding: 6px 14px; }
      @media (max-height: 920px) {
        .gameInfoFooter__col--top span {
          font-size: 10px; } }
    .gameInfoFooter__wrapper {
      background: #211e1f;
      margin-top: 0;
      padding: 10px 0;
      text-align: center; }
      .gameInfoFooter__wrapper .slick-arrow {
        position: absolute;
        display: block;
        line-height: 0;
        font-size: 0;
        cursor: pointer;
        background: transparent;
        color: transparent;
        top: 50%;
        padding: 0;
        outline: none;
        transition: all .4s ease;
        z-index: 40;
        width: 2.6rem;
        margin-top: -1.3rem;
        height: 2.6rem;
        border-radius: 50%;
        background: #feb312;
        border: 3px solid #211e1f; }
        .gameInfoFooter__wrapper .slick-arrow:before {
          font-family: "casino-it-icons";
          font-size: 2.4rem;
          width: 100%;
          height: 100%;
          display: block;
          line-height: 0.85em;
          opacity: 1;
          text-align: center;
          color: #ffffff;
          top: 0;
          position: absolute; }
        @media (min-width: 48em) {
          .gameInfoFooter__wrapper .slick-arrow:hover {
            border-color: #dc9601;
            background-color: #fecb5e; }
          .gameInfoFooter__wrapper .slick-arrow:active {
            border: 3px solid #211e1f; } }
      .gameInfoFooter__wrapper .slick-next {
        right: -20px; }
        .gameInfoFooter__wrapper .slick-next:before {
          left: 0.03em;
          content: "\f105"; }
      .gameInfoFooter__wrapper .slick-prev {
        left: -20px; }
        .gameInfoFooter__wrapper .slick-prev:before {
          left: auto;
          right: 0.03em;
          content: "\f104"; }

.free-game-slider__item {
  padding: 0;
  width: 100%;
  position: relative;
  margin: 4px;
  text-align: center;
  color: #ffffff;
  font-size: 14px; }
  .free-game-slider__item__body {
    border-radius: 3px;
    margin: 0;
    position: absolute;
    top: 0;
    max-width: 190px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7); }
    .free-game-slider__item__body .rating-stars {
      height: 18px;
      width: 88px; }
      .free-game-slider__item__body .rating-stars .star-empty,
      .free-game-slider__item__body .rating-stars .star-full {
        width: 88px; }
        .free-game-slider__item__body .rating-stars .star-empty img,
        .free-game-slider__item__body .rating-stars .star-full img {
          width: 88px; }
    .free-game-slider__item__body .title-game {
      margin: 2px 0;
      position: relative;
      min-height: 40px;
      display: block;
      width: 100%; }
      .free-game-slider__item__body .title-game span {
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        padding: 0 10px;
        transform: translateY(-50%);
        display: block;
        text-align: center;
        color: #ffffff;
        font-size: 16px;
        line-height: 1em; }
    .free-game-slider__item__body .btn, .free-game-slider__item__body .btn--brand, .free-game-slider__item__body .btn--brand-secondary, .free-game-slider__item__body .btn--brand-white {
      width: 100%;
      padding: 10px; }
  .free-game-slider__item:hover .free-game-slider__item__body {
    visibility: visible;
    opacity: 1; }
  .free-game-slider__item_cta {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    z-index: 1; }

.free-game-slider__body .rating-stars {
  height: 18px;
  width: 88px; }
  .free-game-slider__body .rating-stars .star-empty,
  .free-game-slider__body .rating-stars .star-full {
    width: 88px; }
    .free-game-slider__body .rating-stars .star-empty img,
    .free-game-slider__body .rating-stars .star-full img {
      width: 88px; }

.free-game-slider__body .reviews-count {
  margin: 10px 0;
  text-align: center;
  color: #535253;
  font-size: 12px; }

.free-game-slider_cta {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  z-index: 1; }
  .free-game-slider_cta .btn-red--wrapper {
    display: block;
    position: absolute;
    padding: 0 10px 10px;
    bottom: 0;
    left: 0;
    width: 100%; }
  .free-game-slider_cta .btn-red {
    width: 100%;
    padding: 10px; }

.free-game-slider__main-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: block; }
  .free-game-slider__main-image--wrapper {
    border-radius: 3px;
    border: 3px solid #fff;
    width: 100%;
    max-width: 190px;
    max-height: 120px;
    margin: 0 auto;
    position: relative;
    display: block;
    padding: 60% 0 0;
    height: auto;
    overflow: hidden;
    background: url("../immagini/giochi-gratis-on-error.jpg") 0 50% scroll no-repeat white;
    background-size: cover; }

.modalFreeGame {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  background-color: rgba(33, 30, 31, 0.7);
  font-size: 1rem;
  line-height: 1.5; }
  .modalFreeGame .modalPane {
    background-color: #fff;
    padding: 0;
    position: relative;
    width: 80%;
    max-width: 1024px;
    margin: 3% auto; }
  .modalFreeGame .modalClose {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 2;
    display: block;
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 100%;
    outline: 0;
    padding: 0;
    margin: 0;
    font-size: 0;
    color: #feb312;
    cursor: pointer; }
    @media (max-height: 920px) {
      .modalFreeGame .modalClose {
        top: 16px; } }
    .modalFreeGame .modalClose:before {
      position: absolute;
      top: -6px;
      left: 0;
      margin-left: 10px;
      color: #ffffff;
      content: '\e813';
      font-family: "casino-it-icons";
      font-style: normal;
      font-weight: 400;
      speak: none;
      line-height: 1em;
      font-size: 2.2rem;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased; }

.modalFreeGameName {
  margin: 10px 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.25;
  text-align: center; }

.modalFreeGameThumbnail {
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
  background-color: #737373; }
  .modalFreeGameThumbnail img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: auto;
    border: 0;
    margin: auto; }

.gameRatingBar {
  display: inline-block;
  height: 19px; }
  .gameRatingBar ul {
    display: inline-block;
    width: auto;
    padding: 0 10px 0 0; }
  .gameRatingBar li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 17px;
    cursor: pointer;
    padding: 0;
    opacity: .6;
    color: darkgrey; }
    .gameRatingBar li:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '\e800';
      font-family: "casino-it-icons";
      font-style: normal;
      font-weight: 400;
      speak: none;
      line-height: 1em;
      font-size: 1.4rem;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased; }
    .gameRatingBar li.active {
      color: gold;
      opacity: 1; }

/********** New Free Game Rating Class ************/
.freeGameRatingLabel {
  color: #6c6b6b;
  font-weight: 700;
  font-size: 0.75em;
  line-height: 1.5; }

.free-games-slider__wrapper .bx-wrapper {
  display: block; }

.free-games-slider__wrapper .free-game__item {
  padding: 0; }

/* height adjustments for laptops viewport */
@media (max-height: 920px) {
  .modalFreeGame .modalPane {
    margin: 0 auto; }
  .gameInfoHeader {
    padding: 6px 20px; }
  .gameInfoRating .modalFreeGameRatingLabel {
    padding: 0; }
  .gameInfoImage iframe {
    max-height: 454px; } }

.free-games__slick-slider {
  display: none; }
  .free-games__slick-slider.slick-dotted {
    margin-bottom: 0; }
  .free-games__slick-slider.slick-initialized {
    display: block; }

.games-slider {
  margin-bottom: 40px; }
  .games-slider .slick-prev,
  .games-slider .slick-next {
    background: #7a7879;
    border: 3px solid #fff;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    z-index: 2; }
  .games-slider .slick-prev {
    left: 10px; }
  .games-slider .slick-next {
    right: 10px; }
  .games-slider .slick-list {
    width: 85%;
    margin: 0 auto; }
    .games-slider .slick-list img {
      margin: auto; }

.pagination {
  text-align: center; }
  .pagination__item {
    border-radius: 4px;
    display: inline-block;
    padding: 5px 12px;
    cursor: pointer; }
    .pagination__item:hover {
      background: #6faa64;
      color: #ffffff; }
    .pagination__item:nth-child(n+5):nth-child(-n+11) {
      display: none; }
      @media (min-width: 64em) {
        .pagination__item:nth-child(n+5):nth-child(-n+11) {
          display: inline-block; } }
    .pagination__item--active {
      background: #88c871;
      color: #ffffff; }
    .pagination__item--next, .pagination__item--previous {
      display: inline-block;
      background: #211e1f;
      color: #ffffff;
      font-size: 1rem;
      font-weight: bold; }
      .pagination__item--next:hover, .pagination__item--previous:hover {
        background: #211e1f; }
    .pagination__item--previous {
      margin-right: 10px; }
      .pagination__item--previous:before {
        font-family: "casino-it-icons";
        content: '\f104\f104';
        margin-right: 8px; }
    .pagination__item--next {
      margin-left: 10px; }
      .pagination__item--next:after {
        font-family: "casino-it-icons";
        content: '\f105\f105';
        margin-left: 8px; }

.review-banner {
  background-color: #211e1f;
  color: #ffffff;
  margin-bottom: 20px; }
  @media (min-width: 64em) {
    .review-banner {
      margin-bottom: 40px; } }
  .review-banner__logo, .review-banner__casino-name, .review-banner__rating, .review-banner__platforms, .review-banner__bonus {
    text-align: center;
    padding: 10px; }
    @media (min-width: 64em) {
      .review-banner__logo, .review-banner__casino-name, .review-banner__rating, .review-banner__platforms, .review-banner__bonus {
        padding: 30px 0; } }
  .review-banner__casino-name-left-aligned {
    text-align: left; }
  .review-banner__casino-name, .review-banner__bonus {
    position: relative; }
  .review-banner__casino-name:before, .review-banner__bonus:before {
    content: '';
    position: absolute;
    height: 40px;
    top: 50%;
    transform: translateY(-50%);
    left: -1rem;
    background-color: rgba(255, 255, 255, 0.4);
    width: 1px; }
    @media (min-width: 64em) {
      .review-banner__casino-name:before, .review-banner__bonus:before {
        height: 60px; } }
  .review-banner__bonus:before {
    display: none; }
    @media (min-width: 48em) {
      .review-banner__bonus:before {
        display: block; } }
  .review-banner__platforms:after {
    left: auto;
    right: 0; }
  .review-banner__logo img {
    display: block;
    margin: 0 auto;
    border-radius: 4px;
    background-color: #ffffff; }
  .review-banner__casino-name h1 {
    font-family: "RobotoSlab", sans-serif;
    font-weight: bold;
    font-size: 1.2rem; }
  .review-banner__rating {
    font-size: 1.2rem;
    display: none; }
    @media (min-width: 48em) {
      .review-banner__rating {
        display: flex;
        align-items: center;
        justify-content: center; } }
    @media (min-width: 64em) {
      .review-banner__rating {
        visibility: hidden;
        opacity: 0; } }
    .review-banner__rating__value {
      font-weight: 700;
      align-self: center; }
    .review-banner__rating__star {
      color: #feb312;
      align-self: center; }
  .review-banner__platforms {
    display: none; }
    @media (min-width: 48em) {
      .review-banner__platforms {
        display: block; } }
    .review-banner__platforms:before {
      left: initial;
      right: -1rem; }
      @media (min-width: 64em) {
        .review-banner__platforms:before {
          right: initial;
          left: -1rem; } }
    .review-banner__platforms__icon {
      color: #88c871; }
    .review-banner__platforms__label {
      display: block; }
  .review-banner__bonus__value {
    color: #88c871;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 0; }
  .review-banner__bonus__label {
    display: block; }
  .review-banner__btn {
    max-width: 180px;
    padding: 10px; }

.review-screenshots__item_image {
  margin: 0 auto; }

.review-screenshots__main-image {
  margin-bottom: 10px; }
  .review-screenshots__main-image li {
    display: none;
    list-style: none; }
    .review-screenshots__main-image li img {
      width: 100%; }
    .review-screenshots__main-image li.first {
      display: block; }

.review-screenshots__thumbnails {
  margin-bottom: 10px;
  padding: 0 20px;
  position: relative;
  display: none; }
  .review-screenshots__thumbnails li {
    width: 100px;
    margin: 0 5px;
    padding: 2px 0;
    list-style: none;
    float: left;
    cursor: pointer; }
    .review-screenshots__thumbnails li:focus {
      outline: none; }
    .review-screenshots__thumbnails li img {
      opacity: 0.7; }
    .review-screenshots__thumbnails li.slick-current img {
      opacity: 1;
      outline: 2px solid #88c871; }
  .review-screenshots__thumbnails .slick-arrow {
    position: absolute;
    top: 0px;
    height: 100%;
    border: 0;
    background: none;
    width: 20px;
    cursor: pointer; }
    .review-screenshots__thumbnails .slick-arrow:before {
      font-family: "casino-it-icons";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #88c871;
      width: 100%;
      text-align: center; }
    .review-screenshots__thumbnails .slick-arrow:focus {
      outline: none; }
      .review-screenshots__thumbnails .slick-arrow:focus:before {
        color: #6faa64; }
  .review-screenshots__thumbnails .slick-prev {
    left: -5px; }
    .review-screenshots__thumbnails .slick-prev:focus {
      box-shadow: inset 2px 0 0 0 #88c871; }
    .review-screenshots__thumbnails .slick-prev:before {
      content: '\f104'; }
  .review-screenshots__thumbnails .slick-next {
    right: -5px; }
    .review-screenshots__thumbnails .slick-next:focus {
      box-shadow: inset -2px 0 0 0 #88c871; }
    .review-screenshots__thumbnails .slick-next:before {
      content: '\f105'; }

.review-screenshots .slick-initialized {
  display: block; }

.sidebar-cta-box {
  display: none;
  position: relative;
  border: 1px solid #7a7879;
  padding: 1rem; }
  @media (min-width: 75em) {
    .sidebar-cta-box {
      display: block; } }
  .sidebar-cta-box:hover .sidebar-cta-box__btn {
    transform: translateY(3px);
    background-color: #6faa64;
    box-shadow: 0 3px 0 0 transparent; }
  .sidebar-cta-box__logo {
    width: 90px;
    height: 90px;
    padding: 10px;
    position: absolute;
    right: 1rem;
    top: -20px;
    border: 1px solid #7a7879;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff; }
    .sidebar-cta-box__logo img {
      align-self: center;
      width: 100%; }
  .sidebar-cta-box__title {
    font-size: 1rem;
    padding-right: 95px;
    color: #211e1f; }
  .sidebar-cta-box__update {
    font-size: 0.7rem;
    color: #7a7879;
    padding-right: 95px; }
    .sidebar-cta-box__update__date {
      display: block; }
  .sidebar-cta-box__ranking {
    font-size: 0.85rem;
    color: #7a7879;
    border-bottom: 1px solid #7a7879;
    padding-bottom: 10px;
    margin-bottom: 20px; }
    .sidebar-cta-box__ranking__image {
      padding-right: 0; }
    .sidebar-cta-box__ranking__text {
      font-size: 0.85rem; }
      .sidebar-cta-box__ranking__text .highlight {
        color: #211e1f;
        font-weight: 700; }
  .sidebar-cta-box__list li {
    font-size: 0.85rem;
    margin-bottom: 8px; }
  .sidebar-cta-box__btn {
    margin: 20px auto 10px;
    width: 100%; }
  .sidebar-cta-box__link {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; }

.review-ratings__total-rating {
  background-color: #88c871;
  display: flex;
  justify-content: center;
  align-content: center;
  max-width: 100px;
  max-height: 100px;
  padding: 2rem 0.5rem; }
  .review-ratings__total-rating span {
    align-self: center;
    color: #ffffff; }

.review-ratings__overall {
  color: #211e1f; }

.review-ratings__users, .review-ratings__experts {
  color: #7a7879;
  padding: 0; }

.review-ratings__breakdown__item, .review-ratings__add-rating__item {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .review-ratings__breakdown__item__label, .review-ratings__add-rating__item__label {
    padding: 0;
    font-size: 0.85rem;
    color: #211e1f; }
  .review-ratings__breakdown__item__stars, .review-ratings__add-rating__item__stars {
    color: #feb312; }

.review-ratings__breakdown {
  margin-top: 20px; }
  @media (min-width: 48em) {
    .review-ratings__breakdown {
      margin-top: 0; } }
  @media (min-width: 64em) {
    .review-ratings__breakdown {
      margin-top: 20px; } }

.review-ratings__add-rating {
  margin-top: 20px;
  border-top: 1px solid #7a7879;
  padding-top: 20px; }
  .review-ratings__add-rating__item__stars label:before {
    content: '\e801';
    font-family: "casino-it-icons";
    color: #feb312; }
  .review-ratings__add-rating__item__stars label:hover:before {
    content: '\e800'; }
  .review-ratings__add-rating__item__stars input {
    display: none; }

.review-casino-information {
  margin-top: 30px; }
  .review-casino-information__header {
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #7a7879; }
  .review-casino-information__title {
    font-size: 1.4rem;
    color: #211e1f; }
  .review-casino-information__subtitle {
    font-weight: 700;
    padding-bottom: 0;
    color: #211e1f; }
  .review-casino-information__content {
    padding: 0; }
  .review-casino-information__item {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 300px; }
    .review-casino-information__item__label {
      padding: 0;
      font-size: 1rem;
      color: #211e1f; }
    .review-casino-information__item__value {
      padding: 0; }
  .review-casino-information__flags {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    transition: all .3s linear; }
    .review-casino-information__flags p {
      padding: 0; }
  .review-casino-information__flag {
    max-width: 46px;
    padding: 4px 4px 0 0; }
    .review-casino-information__flag img {
      width: 42px;
      height: 32px;
      border: 1px solid #dddfe2; }
  .review-casino-information__language__content, .review-casino-information__currency__content, .review-casino-information__software__content {
    padding: 0; }
  .review-casino-information__language__item, .review-casino-information__currency__item, .review-casino-information__software__item {
    display: inline;
    text-transform: capitalize; }
    .review-casino-information__language__item:after, .review-casino-information__currency__item:after, .review-casino-information__software__item:after {
      content: ', '; }
    .review-casino-information__language__item:last-of-type:after, .review-casino-information__currency__item:last-of-type:after, .review-casino-information__software__item:last-of-type:after {
      content: ''; }

.feature-boxes {
  display: none;
  margin-top: 30px;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center; }
  @media (min-width: 75em) {
    .feature-boxes {
      display: block; } }
  .feature-boxes__item {
    height: 100%;
    border: 1px solid #7a7879;
    background-color: #ffffff;
    text-align: center;
    padding: 20px; }
    .feature-boxes__item:hover, .feature-boxes__item.active {
      background-color: #211e1f;
      color: #ffffff; }
      .feature-boxes__item:hover .feature-boxes__item__divider, .feature-boxes__item.active .feature-boxes__item__divider {
        background-color: rgba(255, 255, 255, 0.6); }
      .feature-boxes__item:hover .feature-boxes__item__icon, .feature-boxes__item.active .feature-boxes__item__icon {
        display: none; }
      .feature-boxes__item:hover .feature-boxes__item__desc, .feature-boxes__item.active .feature-boxes__item__desc {
        display: flex; }
      .feature-boxes__item:hover .feature-boxes__item__more-info, .feature-boxes__item.active .feature-boxes__item__more-info {
        display: none; }
    .feature-boxes__item__icon img {
      max-height: 64px;
      margin: 0 auto 20px;
      display: block; }
    .feature-boxes__item__title {
      font-size: 1.2rem; }
    .feature-boxes__item__key-value {
      color: #88c871;
      font-weight: 700; }
    .feature-boxes__item__divider {
      height: 1px;
      width: 100%;
      margin: 10px 0;
      background-color: #7a7879; }
    .feature-boxes__item__more-info {
      color: #8b8dd1; }
    .feature-boxes__item__desc {
      display: none;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      font-size: 0.85rem; }
      .feature-boxes__item__desc p {
        font-size: 0.85rem;
        padding: 0; }
    .feature-boxes__item__payout-option {
      width: 50%;
      padding: 10px 5px 0; }
      .feature-boxes__item__payout-option__value {
        color: #8b8dd1;
        font-size: 0.85rem; }
    .feature-boxes__item__game-list {
      display: flex;
      flex-wrap: wrap; }
    .feature-boxes__item__game {
      text-align: left;
      text-transform: capitalize; }
      .feature-boxes__item__game:nth-of-type(odd) {
        text-align: right; }
        .feature-boxes__item__game:nth-of-type(odd):after {
          content: '|';
          margin: 0 5px; }
      .feature-boxes__item__game__num {
        color: #8b8dd1; }

.pros-cons {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  justify-content: space-between;
  align-items: flex-start; }
  .pros-cons__title {
    font-size: 1.1rem; }
  .pros-cons__link {
    margin-top: 20px;
    display: block;
    color: #8b8dd1; }
    .pros-cons__link:hover {
      color: #4c4eab; }
  .pros-cons__item {
    width: 100%;
    padding: 10px 0; }
    @media (min-width: 30em) {
      .pros-cons__item {
        width: 48%; } }
    @media (min-width: 64em) {
      .pros-cons__item {
        width: calc(100% / 3.2); } }
    .pros-cons__item p,
    .pros-cons__item ul {
      padding: 10px 0 0; }
    .pros-cons__item ul li {
      margin-bottom: 10px; }
    .pros-cons__item--positive .icon {
      color: #88c871; }
    .pros-cons__item--negative .icon {
      color: #ea3f35; }
    .pros-cons__item--recommend {
      background-color: #211e1f;
      width: 100%;
      padding: 20px;
      margin-top: 20px; }
      @media (min-width: 64em) {
        .pros-cons__item--recommend {
          margin-top: 0;
          width: calc(100% / 3.2); } }
      .pros-cons__item--recommend .pros-cons__title,
      .pros-cons__item--recommend p {
        color: #ffffff; }

.operator-info__title {
  font-size: 1.6rem;
  color: #211e1f;
  margin-bottom: 10px; }

.operator-info__sidebar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center; }

.operator-info__item {
  margin-bottom: 10px;
  width: 100%; }
  .operator-info__item__title {
    background-color: #211e1f;
    font-size: 1.2rem;
    color: #ffffff;
    padding: 10px 20px; }
  .operator-info__item__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px; }
    @media (min-width: 48em) {
      .operator-info__item__content {
        padding: 20px; } }
    .operator-info__item__content p {
      padding: 0; }
    .operator-info__item__content--border {
      border: 1px solid #dddfe2; }
    .operator-info__item__content--border-top {
      border-top: 1px solid #dddfe2;
      padding: 20px 0; }
  @media (min-width: 30em) {
    .operator-info__item--languages {
      width: 50%; } }
  @media (min-width: 48em) {
    .operator-info__item--languages {
      width: calc(100% / 3.2); } }
  @media (min-width: 64em) {
    .operator-info__item--languages {
      width: 100%;
      order: 1; } }
  .operator-info__item--languages .operator-info__item__content {
    justify-content: flex-start; }
  @media (min-width: 30em) {
    .operator-info__item--support {
      width: 50%; } }
  @media (min-width: 48em) {
    .operator-info__item--support {
      width: calc(100% / 3.2); } }
  @media (min-width: 64em) {
    .operator-info__item--support {
      width: 100%;
      order: 3; } }
  .operator-info__item--payment-options {
    width: 100%; }
    @media (min-width: 48em) {
      .operator-info__item--payment-options {
        width: calc(100% / 3.2); } }
    @media (min-width: 64em) {
      .operator-info__item--payment-options {
        width: 100%;
        order: 2; } }
    .operator-info__item--payment-options .operator-info__item__content {
      justify-content: space-around; }
  @media (min-width: 30em) {
    .operator-info__item--payouts .operator-info__item__content {
      padding: 20px 0; } }
  @media (min-width: 48em) {
    .operator-info__item--payouts .operator-info__item__content {
      padding: 20px; } }

.operator-info__flag {
  max-width: 30%;
  margin-right: 3%; }
  .operator-info__flag:last-of-type {
    margin-right: 0; }
  .operator-info__flag img {
    border: 1px solid #dddfe2;
    width: 100%;
    display: block; }

.operator-info__bank-option {
  margin-bottom: 5px;
  margin-right: 5px; }

.operator-info__device {
  text-align: center;
  width: calc(100% / 3); }
  .operator-info__device__icon {
    color: #88c871;
    display: block;
    font-size: 1.8rem; }

.operator-info__ring {
  text-align: center;
  width: 100%;
  margin-bottom: 30px; }
  @media (min-width: 30em) {
    .operator-info__ring {
      width: initial;
      margin-bottom: 0; } }
  .operator-info__ring__value {
    position: relative;
    width: 128px;
    height: 128px;
    border-radius: 50%;
    border: 1px solid #88c871;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 700;
    margin: 0 auto; }
    .operator-info__ring__value:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 116px;
      height: 116px;
      border-radius: 50%;
      border: 1px solid #dddfe2; }
  .operator-info__ring__label {
    margin-top: 10px;
    font-weight: 700; }

.operator-info__about {
  width: 100%;
  padding-right: 10px;
  padding-bottom: 20px; }
  @media (min-width: 30em) {
    .operator-info__about {
      width: calc(100% / 3);
      padding-bottom: 0; } }
  .operator-info__about__title {
    font-weight: 700;
    font-family: "RobotoSlab", sans-serif;
    font-size: 1.1rem;
    color: #211e1f; }

.operator-info__link {
  width: 100%; }
  @media (min-width: 30em) {
    .operator-info__link {
      width: calc(100% / 3); } }

.operator-info__btn {
  padding: 10px 15px; }

.review-content {
  margin-top: 20px; }
  .review-content__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column; }
    @media (min-width: 48em) {
      .review-content__header {
        flex-direction: row; } }
  .review-content__title {
    color: #211e1f;
    font-size: 1.6rem; }
  .review-content__rating {
    display: flex;
    margin-top: 4px;
    color: #feb312;
    justify-content: space-between;
    align-items: center; }
    .review-content__rating span {
      align-self: center;
      margin: 0 2px; }
  .review-content__section {
    margin-top: 20px; }
    .review-content__section .features__title {
      margin-bottom: 20px; }
  .review-content__section.features__section {
    border: 1px solid #dddfe2;
    padding: 20px; }
  .review-content__mobile-container {
    float: right;
    min-height: 100px;
    min-width: 300px;
    text-align: center;
    border: 1px solid #dddfe2;
    margin-top: 11px; }
    .review-content__mobile-container .mobile__title {
      background: #211e1f;
      padding: 10px 0;
      color: #ffffff; }
      .review-content__mobile-container .mobile__title h3 {
        font-size: 1.3rem; }
    .review-content__mobile-container .mobile__content {
      padding: 20px 15px; }
    .review-content__mobile-container .mobile__list {
      display: flex;
      justify-content: space-between;
      margin: 0px;
      padding: 0px;
      list-style-type: none; }
      .review-content__mobile-container .mobile__list span, .review-content__mobile-container .mobile__list strong, .review-content__mobile-container .mobile__list p {
        font-size: 18px;
        margin: 0px;
        padding: 0px;
        text-transform: capitalize; }
      .review-content__mobile-container .mobile__list li > span:first-child {
        color: #88c871; }
  .review-content__game-detail-section .list {
    margin-bottom: 20px; }
    .review-content__game-detail-section .list li {
      padding: 4px 0;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      align-items: center; }
      .review-content__game-detail-section .list li .details__title {
        flex-basis: 60%; }
      .review-content__game-detail-section .list li .details__desc {
        flex-basis: 40%; }
  .review-content__game-detail-section .section__game-footer a {
    padding: 10px;
    display: block;
    text-align: center; }

.available-games {
  text-align: center;
  margin-top: 20px; }
  .available-games .row {
    margin: 0;
    border-right: 1px solid #dddfe2;
    border-bottom: 1px solid #dddfe2; }
  .available-games__item {
    border-left: 1px solid #dddfe2;
    border-top: 1px solid #dddfe2;
    position: relative;
    padding: 10px; }
    .available-games__item:before {
      content: '';
      position: absolute;
      left: 5px;
      top: 5px;
      font-size: 0.85rem;
      font-family: "casino-it-icons"; }
    .available-games__item--available:before {
      content: '\e80e';
      color: #88c871; }
    .available-games__item--unavailable:before {
      content: '\e813';
      color: #7a7879; }
  .available-games__icon {
    display: flex;
    justify-content: center;
    align-items: center; }
    .available-games__icon img {
      align-self: center;
      max-height: 40px; }
  .available-games__label {
    font-weight: 700;
    color: #88c871; }
  .available-games__btn {
    margin-top: 20px; }

.trust-badges {
  background-color: #211e1f;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center; }
  .trust-badges__item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 25%;
    max-width: 25%;
    padding: 10px; }
    @media (min-width: 64em) {
      .trust-badges__item {
        flex-basis: 12.5%;
        max-width: 12.5%; } }
    .trust-badges__item img {
      align-self: center; }

.conclusion-cta-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #dddfe2;
  align-content: center;
  margin: 20px 0; }
  .conclusion-cta-box:hover .conclusion-cta-box__btn {
    transform: translateY(3px);
    box-shadow: none;
    background-color: #6faa64; }
  .conclusion-cta-box__header {
    padding: 20px 10px 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    background-color: #f9fafb; }
    @media (min-width: 30em) {
      .conclusion-cta-box__header {
        padding: 0 10px;
        margin: 20px 0; } }
    @media (min-width: 48em) {
      .conclusion-cta-box__header {
        width: 30%;
        padding: 0 10px;
        margin: 20px 0;
        background-color: initial;
        align-content: initial;
        border-right: 1px solid #dddfe2; } }
  .conclusion-cta-box__body {
    padding: 20px;
    width: 100%; }
    @media (min-width: 48em) {
      .conclusion-cta-box__body {
        width: 70%; } }
  .conclusion-cta-box__title {
    font-size: 1.6rem; }
  .conclusion-cta-box__logo, .conclusion-cta-box__rating {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center; }
    @media (min-width: 30em) {
      .conclusion-cta-box__logo, .conclusion-cta-box__rating {
        width: 50%; } }
    @media (min-width: 48em) {
      .conclusion-cta-box__logo, .conclusion-cta-box__rating {
        width: 100%; } }
  @media (min-width: 30em) {
    .conclusion-cta-box__logo {
      border-right: 1px solid #dddfe2; } }
  @media (min-width: 48em) {
    .conclusion-cta-box__logo {
      border-right: 0; } }
  .conclusion-cta-box__logo img {
    align-self: center; }
  .conclusion-cta-box__rating {
    display: flex;
    align-content: center;
    justify-content: center; }
    .conclusion-cta-box__rating__value {
      align-self: center; }
      .conclusion-cta-box__rating__value span {
        font-weight: 700;
        font-size: 1.1rem; }
  .conclusion-cta-box__stars {
    align-self: center;
    padding-right: 5px;
    color: #feb312; }
  .conclusion-cta-box__btn {
    width: 100%;
    display: block;
    margin: 0 20px 20px; }
  .conclusion-cta-box__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/*
// Main map styles
*/
.jsmaps-wrapper {
  position: relative;
  margin: 0 auto;
  /* console */ }

.jsmaps-wrapper .jsmaps-preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 16px 14px; }

.jsmaps-wrapper .jsmaps {
  position: absolute;
  left: 0;
  top: 0; }

.jsmaps-wrapper .jsmaps-text {
  float: left;
  overflow: auto;
  padding: 10px 20px;
  clear: both; }

.jsmaps-wrapper .jsmaps-tooltip {
  position: fixed;
  display: none;
  padding: 10px 15px;
  background: #f2f2f2;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #262626;
  border-radius: 4px;
  pointer-events: none;
  /* Opera 10.5, IE9, Saf5, Chrome, FF4, iOS 4, Android 2.1+ */
  /* useful if you don't want a bg color from leaking outside the border: */
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

.jsmaps-wrapper .jsmaps-mouse-position,
.jsmaps-wrapper .jsmaps-viewbox-data {
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  color: #fff;
  font-size: 14px;
  padding: 10px; }

.jsmaps-wrapper .jsmaps-viewbox-data {
  left: auto;
  right: 0; }

.jsmaps-wrapper .jsmaps-console {
  float: left;
  clear: both;
  display: none;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 2; }

.jsmaps-wrapper .jsmaps-console ul {
  padding: 0;
  margin: 0; }

.jsmaps-wrapper .jsmaps-console ul li {
  list-style-type: none;
  float: left;
  cursor: pointer; }

.jsmaps-wrapper .jsmaps-console ul li button {
  background: none;
  border: 0;
  position: relative;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  margin-right: 1px;
  cursor: pointer;
  opacity: 0.9;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out; }

.jsmaps-wrapper .jsmaps-console ul li button:hover {
  opacity: 1; }

/*
// Select
*/
.jsmaps-select {
  position: relative;
  display: none; }

.jsmaps-select.all-devices {
  display: block; }

@media (max-width: 767px) {
  .jsmaps-select.mobile {
    display: block; } }

@media (min-width: 768px) {
  .jsmaps-select.tablet {
    display: block; } }

@media (min-width: 992px) {
  .jsmaps-select.tablet {
    display: none; } }

@media (min-width: 992px) {
  .jsmaps-select.desktop {
    display: block; } }

.jsmaps-select select {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  padding: 10px 40px 10px 13px !important;
  border: 1px solid #999;
  border-radius: 3px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: inherit;
  /* for IE and Edge */ }

.jsmaps-select select::-ms-expand {
  display: none; }

.jsmaps-select select option {
  padding: 4px; }

.jsmaps-select .jsmaps-select-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  border-left: 1px solid #999;
  pointer-events: none; }

/*
// Icons
*/
[class*="jsmaps-icon"] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

[class*="jsmaps-icon"]:before, [class*="jsmaps-icon"]:after {
  content: '';
  display: inline-block; }

[class*="jsmaps-icon"].jsmaps-icon-chevron:before {
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  -webkit-transform: translate(25%, 0) rotate(-135deg);
  -moz-transform: translate(25%, 0) rotate(-135deg);
  -ms-transform: translate(25%, 0) rotate(-135deg);
  transform: translate(25%, 0) rotate(-135deg); }

[class*="jsmaps-icon"].jsmaps-icon-chevron.jsmaps-icon-chevron-right:before {
  -webkit-transform: translate(-25%, 0) rotate(45deg);
  -moz-transform: translate(-25%, 0) rotate(45deg);
  -ms-transform: translate(-25%, 0) rotate(45deg);
  transform: translate(-25%, 0) rotate(45deg); }

[class*="jsmaps-icon"].jsmaps-icon-chevron.jsmaps-icon-chevron-up:before {
  -webkit-transform: translate(0, calc(25%)) rotate(-45deg);
  -moz-transform: translate(0, calc(25%)) rotate(-45deg);
  -ms-transform: translate(0, calc(25%)) rotate(-45deg);
  transform: translate(0, calc(25%)) rotate(-45deg); }

[class*="jsmaps-icon"].jsmaps-icon-chevron.jsmaps-icon-chevron-down:before {
  -webkit-transform: translate(0, calc(-25%)) rotate(135deg);
  -moz-transform: translate(0, calc(-25%)) rotate(135deg);
  -ms-transform: translate(0, calc(-25%)) rotate(135deg);
  transform: translate(0, calc(-25%)) rotate(135deg); }

[class*="jsmaps-icon"].jsmaps-icon-chevron.jsmaps-theme-light:before {
  border-color: #999; }

[class*="jsmaps-icon"].jsmaps-icon-plus {
  height: 12px; }

@-moz-document url-prefix() {
  [class*="jsmaps-icon"].jsmaps-icon-plus {
    margin-top: -1px; } }

[class*="jsmaps-icon"].jsmaps-icon-plus:before, [class*="jsmaps-icon"].jsmaps-icon-plus:after {
  background: #fff; }

[class*="jsmaps-icon"].jsmaps-icon-plus:before {
  width: 12px;
  height: 2px;
  -webkit-transform: translate(0, -5px);
  -moz-transform: translate(0, -5px);
  -ms-transform: translate(0, -5px);
  transform: translate(0, -5px); }

[class*="jsmaps-icon"].jsmaps-icon-plus:after {
  width: 2px;
  height: 12px;
  -webkit-transform: translate(-7px, 0);
  -moz-transform: translate(-7px, 0);
  -ms-transform: translate(-7px, 0);
  transform: translate(-7px, 0); }

[class*="jsmaps-icon"].jsmaps-icon-minus {
  height: 12px; }

@-moz-document url-prefix() {
  [class*="jsmaps-icon"].jsmaps-icon-minus {
    margin-top: -1px; } }

[class*="jsmaps-icon"].jsmaps-icon-minus:before, [class*="jsmaps-icon"].jsmaps-icon-minus:after {
  background: #fff; }

[class*="jsmaps-icon"].jsmaps-icon-minus:before {
  width: 12px;
  height: 2px;
  -webkit-transform: translate(0, -5px);
  -moz-transform: translate(0, -5px);
  -ms-transform: translate(0, -5px);
  transform: translate(0, -5px); }

[class*="jsmaps-icon"].jsmaps-icon-minus:after {
  width: 2px;
  height: 12px;
  -webkit-transform: translate(-7px, 0);
  -moz-transform: translate(-7px, 0);
  -ms-transform: translate(-7px, 0);
  transform: translate(-7px, 0); }

[class*="jsmaps-icon"].jsmaps-icon-minus:after {
  background: transparent; }

[class*="jsmaps-icon"].jsmaps-icon-reset {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-left-color: transparent; }

[class*="jsmaps-icon"].jsmaps-icon-reset:before {
  position: absolute;
  width: 0;
  height: 0;
  left: -6.5px;
  bottom: 0;
  border-width: 5px;
  border-style: solid;
  border-top-color: #fff;
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg); }

/*# sourceMappingURL=jsmaps.css.map */
