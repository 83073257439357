blockquote {
  position: relative;
  border: 1px solid $border-color;
  padding: 10px 10px 10px 50px;

  &:before {
    content: '\201C';
    position: absolute;
    left: 10px;
    top: 10px;
    color: $brand-primary;
    font-size: ($text-size-base * 5);
    font-family: $font-family-heading;
    height: 40px;
    line-height: 1;
  }

  cite {
    color: $brand-primary;
  }

  .blockquote__name {
    font-weight: 700;
    color: $brand-primary;
    text-transform: capitalize;
  }

  .blockquote__source {
    color: $projectGrey;
  }

}