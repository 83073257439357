.to-top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 0;
  right: 10px;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s 0s, visibility 0s .3s, bottom .3s linear;
  border-radius: 50%;
  background-color: $brand-primary;

  @media (min-width: $screen-sm-min) {
    right: 20px;
  }

  @media (min-width: $screen-md-min) {
    height: 60px;
    width: 60px;
    right: 30px;
  }

  &:before {
    content: '\e80d';
    font-family: $font-icons;
    font-size: 1.5rem;
    line-height: 0.9;
    color: $projectWhite;
    align-self: center;

    @media (min-width: $screen-md-min) {
      font-size: 2rem;
    }
  }

  &--visible,
  &--fade {
    &:hover {
        background-color: $brand-primary-accent;
        opacity: 1;
    }
  }

  &--visible {
    visibility: visible;
    opacity: 1;
    border-radius: 50%;
    bottom: 40px;
    @media (min-width: $screen-sm-min) {
      bottom: 20px;
    }

    @media (min-width: $screen-md-min) {
      bottom: 30px;
    }
  }

  &--fade {
    opacity: .5;
  }
}