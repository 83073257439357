.free-games-library {
  padding-top: 20px;
  margin: 0 -.5rem;

  @media (min-width: $screen-sm-min) {
    margin: 0;
  }

  &__header {
    background-color: $brand-primary-accent;
    padding: 15px;
    color: $projectWhite;
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-xs-min) {
      flex-direction: row;
    }

    &__title,
    a {
      text-align: center;
      @media (min-width: $screen-xs-min) {
        flex-basis: 50%;
        max-width: 50%;
        align-self: center;
      }
    }

    &__title {
      color: $projectWhite;
      @media (min-width: $screen-xs-min) {
        text-align: left;
      }
    }

    a {
      color: $projectWhite;
      opacity: 0.7;
      text-decoration: underline;
      font-size: 12px;
      @media (min-width: $screen-xs-min) {
        text-align: right;
      }
    }
  }

  &__filter-bar {
    background-color: $brand-secondary;
    padding: 10px;
    display: flex;
    color: $projectWhite;
    flex-wrap: wrap;
    @media (min-width: $screen-sm-min) {
      padding: 20px;
      align-items: center;
    }

    .filter-sort,
    .filter-by {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .sort-by-label,
      span {
        width: 100%;

        @media (min-width: $screen-lg-min) {
          width: auto;
          margin-right: 6px;
        }
      }

      select {
        width: 100%;
        background-color: $projectWhite;
        border: none;
        border-radius: 0;
        margin-top: 4px;
        height: 40px;
        color: $brand-primary;
        font-size: 12px;
        margin-bottom: 5px;

        @media(min-width: $screen-xs-min) {
          width: 48%;
          font-size: 14px;
          height: 30px;
          margin-bottom: 0;
        }
      }
    }

    .filter-sort {
      @media (min-width: $screen-sm-min) {
        width: 33.33333333333%
      }

      select {
        @media (min-width: $screen-sm-min) {
          width: 60%;
        }
      }
    }

    .filter-by {
      margin-top: 6px;
      @media (min-width: $screen-sm-min) {
        width: 50%;
        margin-top: 0;
      }

      span {
        @media (min-width: $screen-sm-min) {
          display: inline-block;
        }
      }

      select {
        @media (min-width: $screen-lg-min) {
          width: 40%;
          margin-top: 0;
        }

        &:first-of-type {
          @media (min-width: $screen-xs-min) {
            margin-right: 4%;
          }
        }

      }
    }

    a {
      color: $brand-negative;
      text-align: right;
      display: block;
      font-size: 12px;
      margin: 5px auto 0;

      @media (min-width: $screen-sm-min) {
        width: 16.6666666%;
      }

      &:hover {
        color: darken($brand-negative, 10%);
      }
    }

  }

  &__body {
    background-color: $projectOffWhite;
    padding: 0 0 20px;
    min-height: 60px;
    .btn-red {
      padding: 10px 30px;
    }
    .filter_results {
      text-align: center;
      padding-top: 20px;
      &--white {
        color: $projectWhite;
      }
    }
  }

  &__games {
    display: flex;
    flex-direction: column;

    &.slick {
      flex-direction: row;
    }

    @media (min-width: $screen-sm-min) {
      flex-direction: column;
    }

    .games_row {
      display: flex;
      justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
      margin: 10px 0;

      @media (min-width: $screen-md-min) {
        //flex-wrap: nowrap;
      }
    }
  }

  &__main-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: block;

    &--wrapper {
      border-radius: 3px;
      border: 3px solid #fff;
      width: 100%;
      height: 120px;
      max-width: 190px;
      max-height: 120px;
      margin: 0 auto 10px;
      position: relative;
      display: block;
      background: url("#{$image-path}/lazy-3x2.png") 0 50% scroll no-repeat white;
      background-size: contain;

      @media (min-width: $screen-md-min) {
        padding: 60% 0 0;
        height: auto;
      }
      overflow: hidden;
    }
  }

  &__item {
    padding: 10px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    width: 90%;
    margin: 10px 5%;
    text-align: center;
    position: relative;
    padding-bottom: 54px;
    background-color: rgba(9, 146, 184, 0.8);

    @media (min-width: $screen-xs-min) {
      width: 45%;
      margin: 10px 2.5%;
    }

    @media (min-width: $screen-md-min) {
      width: 22%;
      margin: 20px 1%;
    }

    &__body {

      .rating-stars {
        height: 18px;
        width: 88px;

        .star-empty,
        .star-full {
          width: 88px;

          img {
            width: 88px;
          }
        }

      }
      .reviews-count {
        margin: 10px 0;
        text-align: center;
        font-size: 12px;
        color: $projectWhite;
        font-weight: 400;
      }

    }
    &_cta {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      z-index: 20;
      &:hover {
        //background-color: rgba(0, 0, 0, 0.2);
      }
      .btn-red--wrapper {
        display: block;
        position: absolute;
        padding: 0 10px 10px;
        bottom: 0;
        left: 0;
        width: 100%;
      }
      .btn-red {
        width: 100%;
        padding: 10px;
      }
    }
  }

  &__pagination {
    display: block;
    margin: 0 auto;
    text-align: center;
    position: relative;
    color: $projectGrey;
    font-family: $font-family-body;

    .button-wrapper {
      display: block;
      width: auto;
      position: relative;
      padding: 0 6.3rem;
      @media (min-width: $screen-xs-min) {
        display: inline-block;
      }
      &.loading {
        margin: 60px 0;
      }
    }
    .slick-dots {
      display: inline-block;
      width: auto;
      padding: 0 0.4rem;
      position: relative;
      bottom: auto;
      li {
        display: inline-block;
        height: auto;
        width: auto;
        margin: 0;
        //display: inline-block;
        button {
          display: none;
          height: auto;
          width: auto;
          background-color: transparent;
          color: $projectGrey;
          font-family: $font-family-body;
          &:hover {
            color: $projectWhite;
          }
          &.active-friends {
            @media (min-width: $screen-xs-min) {
              display: inline-block;
            }
          }
          &:before {
            content: normal;
            display: none;
          }
        }

      }
      .slick-active {
        &.active-friends {
          display: none;
        }
        .page-num {
          display: none;
        }
        @media (min-width: $screen-xs-min) {
          &.active-friends {
            display: inline-block;
          }
          .page-num {
            display: block;
            background-color: $brand-primary-accent;
            color: $projectWhite;
          }
        }

      }
      .next,
      .prev,
      .page-num {
        //color: $projectWhite;
        //padding: 10px;

      }
    }

    .next,
    .prev,
    .page-num {
      transition: all .4s;
      border: 0 none;
      padding: 10px;
      height: auto;
      width: auto;
      font-size: 1rem;
      display: inline-block;
      line-height: 0.9;
      margin: 0 0.1rem;
      cursor: pointer;
      font-family: $font-family-body;
      font-weight: 400;
      &:hover {
        background-color: darken($brand-primary-accent, 10%);
        border-color: darken($brand-primary-accent, 10%);
      }
      &.current {
        background-color: $brand-primary-accent;
        border-color: $brand-primary-accent;
      }
    }

    .prev,
    .next {
      background-color: $projectBlack;
      color: $projectWhite;
      padding: 10px 15px;
      position: absolute;
      top: 0;
      min-width: 68px;
    }
    .next {
      left: auto;
      right: 0;
    }
    .prev {
      left: 0;
    }
  }
}

/* Modal */

/* ModalFreeGame */
/*gameinfo*/

.btn.btn--full-width {
  min-width: 80%;
  //text-transform: uppercase;
}

.gamePopupImg {
  height: 420px;
}

.gameInfo {
  background-color: #fff;

  &:before {
    content: '';
    position: absolute;
    top: 4px;
    right: 23px;
    z-index: 2;
    display: block;
    width: 78px;
    height: 81px;
    //background: transparent url('/bilder/popup/free.png') no-repeat scroll 0 0;
  }

  &Header {
    padding: 20px;
    font-weight: 700;
    font-size: 20px;
    margin: 0;
    position: relative;
    color: $brand-primary;
    background-color: $projectWhite;

    &__name {
      color: $brand-negative;
      line-height: 128%;
      font-size: 18px;
      width: auto;
      font-weight: 400;
      //font-family: $font-crete-round;
      text-decoration: none;
      display: inline-block;
      padding-right: 20px;

    }
  }
  &Body {

  }
  &Rating {
    //font-style: italic;
    font-weight: 400;
    font-size: 1rem;
    display: inline-block;

    &__message {
      font-size: 0.8rem;
      display: inline-block;
      width: auto;
      vertical-align: baseline;
    }
  }

  &Image {
    position: relative;
    @media (max-height: 920px) {
      iframe {
        max-height: 454px;
      }
    }
    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }
  }

  &Logo {
    float: left;
  }

  &Footer {
    text-align: center;
    padding: 0;
    .claim-now-link {
      min-width: 300px;
      text-align: center;
      float: none;
    }
    &__col {
      &--top {
        span {
          font-size: 16px;
          display: inline-block;
          margin: 6px auto;
          background-color: transparentize($projectWhite, 0.9);
          color: transparentize($projectWhite, 0.2);
          border-radius: 20px;
          padding: 6px 14px;
          @media (max-height: 920px) {
            font-size: 10px;
          }
        }
      }
    }
    &__wrapper {
      background: $projectBlack;
      margin-top: 0;
      padding: 10px 0;
      text-align: center;
      .slick-arrow {
        position: absolute;
        display: block;
        line-height: 0;
        font-size: 0;
        cursor: pointer;
        background: transparent;
        color: transparent;
        top: 50%;
        padding: 0;
        outline: none;
        transition: all .4s ease;
        z-index: 40;
        width: 2.6rem;
        margin-top: -1.3rem;
        height: 2.6rem;
        border-radius: 50%;
        background: $brand-secondary;
        border: 3px solid $projectBlack;
        &:before {
          font-family: $font-icons;
          font-size: 2.4rem;
          width: 100%;
          height: 100%;
          display: block;
          line-height: 0.85em;
          opacity: 1;
          text-align: center;
          color: $projectWhite;
          top: 0;
          position: absolute;
        }
        @media (min-width: $screen-sm-min) {
          &:hover {
            border-color: darken($brand-secondary, 10%);
            background-color: lighten($brand-secondary, 15%);
          }
          &:active {
            border: 3px solid $projectBlack;
          }
        }
      }
      .slick-next {
        right: -20px;

        &:before {
          left: 0.03em;
          content: "\f105";
        }
      }
      .slick-prev {
        left: -20px;

        &:before {
          left: auto;
          right: 0.03em;
          content: "\f104";
        }
      }
    }
  }
}

.free-game-slider {
  &__item {
    padding: 0;
    width: 100%;
    position: relative;
    margin: 4px;
    text-align: center;
    color: #ffffff;
    font-size: 14px;

    &__body {
      border-radius: 3px;
      margin: 0;
      position: absolute;
      top: 0;
      max-width: 190px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      visibility: hidden;
      width: 100%;
      height: 100%;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.7);

      .rating-stars {
        height: 18px;
        width: 88px;

        .star-empty,
        .star-full {
          width: 88px;

          img {
            width: 88px;
          }
        }

      }
      .title-game {
        margin: 2px 0;
        position: relative;
        min-height: 40px;
        display: block;
        width: 100%;
        span {
          position: absolute;
          width: 100%;
          left: 0;
          top: 50%;
          padding: 0 10px;
          transform: translateY(-50%);
          display: block;
          text-align: center;
          color: $projectWhite;
          font-size: 16px;
          line-height: 1em;
        }

      }

      .btn {
        width: 100%;
        padding: 10px;
      }
    }

    &:hover &__body {
      visibility: visible;
      opacity: 1;
    }
    &_cta {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      z-index: 1;
    }
  }
  &__body {

    .rating-stars {
      height: 18px;
      width: 88px;

      .star-empty,
      .star-full {
        width: 88px;

        img {
          width: 88px;
        }
      }

    }
    .reviews-count {
      margin: 10px 0;
      text-align: center;
      color: darken($projectGrey, 15%);
      font-size: 12px;
    }

  }
  &_cta {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    z-index: 1;
    .btn-red--wrapper {
      display: block;
      position: absolute;
      padding: 0 10px 10px;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    .btn-red {
      width: 100%;
      padding: 10px;
    }
  }
  &__main-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: block;

    &--wrapper {
      border-radius: 3px;
      border: 3px solid #fff;
      width: 100%;
      max-width: 190px;
      max-height: 120px;
      margin: 0 auto;
      position: relative;
      display: block;
      padding: 60% 0 0;
      height: auto;
      overflow: hidden;
      background: url("#{$image-path}/giochi-gratis-on-error.jpg") 0 50% scroll no-repeat white;
      background-size: cover;
    }
  }
}

.modalFreeGame {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  background-color: transparentize($projectBlack, 0.3);
  font-size: 1rem;
  line-height: 1.5;
  .modalPane {
    background-color: #fff;
    padding: 0;
    position: relative;
    width: 80%;
    max-width: 1024px;
    margin: 3% auto;
  }
  .modalClose {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 2;
    display: block;
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 100%;
    outline: 0;
    padding: 0;
    margin: 0;
    font-size: 0;
    color: $brand-secondary;
    //background-color: #fff;
    cursor: pointer;
    @media (max-height: 920px) {
      top: 16px;
    }
    &:before {
      position: absolute;
      top: -6px;
      left: 0;
      margin-left: 10px;
      color: $projectWhite;
      content: '\e813';
      font-family: $font-icons;
      font-style: normal;
      font-weight: 400;
      speak: none;
      line-height: 1em;
      font-size: 2.2rem;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
    }
  }
}

.modalFreeGameName {
  margin: 10px 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
  //text-transform: uppercase;
}

.modalFreeGameThumbnail {
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
  background-color: #737373;
  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: auto;
    border: 0;
    margin: auto;
  }
}

.gameRatingBar {
  display: inline-block;
  height: 19px;
  ul {
    display: inline-block;
    width: auto;
    padding: 0 10px 0 0;
  }
  li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 17px;
    cursor: pointer;
    padding: 0;
    opacity: .6;
    //background: url("../bilder/lightbox/bewertung.png") left top no-repeat;

    color: darkgrey;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '\e800';
      font-family: $font-icons;
      font-style: normal;
      font-weight: 400;
      speak: none;
      line-height: 1em;
      font-size: 1.4rem;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
    }
    &.active /*, .unblocked &:hover */
    {
      color: gold;
      opacity: 1;
    }
  }

}

/********** New Free Game Rating Class ************/

.freeGameRatingLabel {
  color: #6c6b6b;
  font-weight: 700;
  font-size: 0.75em;
  line-height: 1.5;
  //text-transform: uppercase;
}

.free-games-slider__wrapper {
  .bx-wrapper {
    display: block;
  }

  .free-game__item {
    padding: 0;
  }
}

/* height adjustments for laptops viewport */
@media (max-height: 920px) {

  .modalFreeGame .modalPane {
    margin: 0 auto;

  }
  .gameInfo {
    &Header {
      padding: 6px 20px;
    }

    &Rating .modalFreeGameRatingLabel {
      padding: 0;
    }

    &Footer {
      //padding: 8px 25px 4px;
    }

    &Image iframe {
      max-height: 454px;
    }
  }
}
