.reasons {
  background: 50% 50% #77b887;
  background-size: cover;
  padding: 30px 0 50px;
  text-align: center;

  &-slider {
    display: flex;

    &--content {
      display: flex;
    }

    @media (max-width: $screen-sm-min) {
      .slick-track {
        display: flex;
      }

      //needed one more class, so the styles override the slick block style
      .slick-track &--content {
        float: none;

        display: flex;
        flex-basis: auto;

        height: auto;
      }
    }
  }

  &__heading {
    color: $projectWhite;
  }
  &__description {
    color: $projectWhite;
    text-align: center;
    width: 90%;
    margin: 0 auto 30px;
  }
  &__item {
    position: relative;
    background: $projectWhite;
    padding: 50px 30px 40px;
    margin-bottom: 30px;
  }
  &__image {
    height: 95px;

    img {
      width: 64px;
      margin: 0 auto;
    }
  }
  &__title {
    font-weight: bold;
  }
  &__text {
    font-size: 0.9rem;
  }
}