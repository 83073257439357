.free-games__slick-slider {
  display: none;
  &.slick-dotted {
    margin-bottom: 0;
  }
  &.slick-initialized {
    display: block;
  }
}

.games-slider {
  margin-bottom: 40px;

  .slick-prev,
  .slick-next {
    background: $projectGrey;
    border: 3px solid #fff;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    z-index: 2;
  }

  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }

  .slick-list {
    width: 85%;
    margin: 0 auto;

    img {
      margin: auto;
    }
  }
}