.trust-inner {
  &__item {
    position: relative;
    border: 1px solid $border-color;
    margin-bottom: 10px;
    padding: 25px;
    @media (min-width: $screen-xs-min) {
      padding-left: 100px;
    }
    h3 {
      font-size: 1.2rem;
    }
    p {
      font-size: 0.9rem;
    }
  }
  &__image {
    float: left;
    margin-right: 20px;
    height: 72px;
    width: 65px;
    @media (min-width: $screen-xs-min) {
      float: none;
      margin-right: 0;
      position: absolute;
      top: 30px;
      left: 20px;
      //min-height: 100px;
    }
    img {
      display: block;
    }
  }
}