.review-ratings {
  &__total-rating {
    background-color: $brand-primary;
    display: flex;
    justify-content: center;
    align-content: center;
    max-width: 100px;
    max-height: 100px;
    padding: 2rem 0.5rem;

    span {
      align-self: center;
      color: $projectWhite
    }
  }

  &__overall {
    color: $projectBlack;
  }

  &__users,
  &__experts {
    color: $projectGrey;
    padding: 0;
  }

  &__breakdown,
  &__add-rating {

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__label {
        padding: 0;
        font-size: $text-size-small;
        color: $projectBlack;
      }

      &__stars {
        color: $brand-rating-stars;
      }
    }
  }

  &__breakdown {
    margin-top: 20px;

    @media (min-width: $screen-sm-min) {
      margin-top: 0;
    }

    @media (min-width: $screen-md-min) {
      margin-top: 20px;
    }
  }

  &__add-rating {
    margin-top: 20px;
    border-top: 1px solid $projectGrey;
    padding-top: 20px;

    &__item {
      &__stars {
        label {
          &:before {
            content: '\e801';
            font-family: $font-icons;
            color: $brand-rating-stars;
          }

          &:hover {
            &:before {
              content: '\e800';
            }
          }
        }

        input {
          display: none;
        }
      }
    }
  }
}