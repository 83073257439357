.cookies__bar {
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100%;
  background-color: lighten($brand-positive, 10%);
  color: $projectWhite;
  padding: 10px 16px;
  z-index: 99;
  @include clearfix();
  &--cookie_note {
    position: relative;
    width: auto;
    float: left;
    font-size: 0.8rem;
    a {
      color: $projectWhite;
      text-decoration: underline;
    }
    @media (min-width: $screen-xs-min) {
      padding: 6px 0;
      font-size: .9rem;
    }
  }
  &__btn {
    &--wrapper {
      text-align: center;
      padding: 0;
      @media (min-width: $screen-xs-min) {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }
    color: $projectWhite;
    padding: 6px;
    @media (min-width: $screen-xs-min) {
      padding: 6px 10px;
    }
  }
}