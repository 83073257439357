.movies {
    background: $projectOffWhite;
    &__poster {
        background: $projectWhite;
        border: 1px solid $border-color;
        padding: 4px;
        float: left;
        img {
            display: block;
            width: 150px;
        }
    }
    p {
        padding: 5px 0;
    }
    &__quote {
        margin: 10px 0;
    }
    h3 {
        display: inline-block;
        padding-top:0;
    }
    &__play {
        color: #ff0000;
        display: inline-block;
        vertical-align: top;
        margin-left: 10px;
        &:before {
            font-size: 2rem;
        }
        &:hover {
            color: $projectGrey;
        }
    }
}