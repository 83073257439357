.pagination {
	text-align: center;


	&__item {
		border-radius: 4px;
		display: inline-block;
		padding: 5px 12px;
		cursor: pointer;
		&:hover {
			background: $brand-primary-accent;
			color: $projectWhite;
		}
		&:nth-child(n+5):nth-child(-n+11) {
			display: none;
			@media (min-width: $screen-md-min) {
				display: inline-block;
			}
		}
		&--active {
			background: $brand-primary;
			color: $projectWhite;
		}
		&--next, &--previous {
			display: inline-block;
			background: $projectBlack;
 			color: $projectWhite;
			font-size: 1rem;
			font-weight: bold;
			&:hover {
				background: $projectBlack;
			}
		}
		&--previous {
			margin-right: 10px;
			&:before {
				font-family: $font-icons;
				content: '\f104\f104';
				margin-right: 8px;
			}
			//&:after {
			//	content : '';
			//	@media (min-width: $screen-md-min) {
			//		content: 'ious';
			//	}
			//}
		}
		&--next {
			margin-left: 10px;
			&:after {
				font-family: $font-icons;
				content: '\f105\f105';
				margin-left: 8px;
			}
		}

	}

}