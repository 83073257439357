.hub {
  background-color: $projectBlack;
  color: $projectOffWhite;
  padding: 30px 0;

  &__title {
    color: $projectWhite
  }

  &--inner {
    padding-top: 10px;

    .hub__title {
      text-align: center;
      margin: 20px 0 10px;
    }
    .hub-block__text {
      font-size: 0.9rem;
    }
  }

  &__description {
    text-align: center;
  }

  /* btn load more */
  &__load-more {
    display: inline-block;
    margin: 10px auto;
  }
}

.hub-block {
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: $projectOffWhite;
    margin: 10px 0;

    @media (min-width: $screen-xs-min) {
      flex-direction: row;
    }

    @media (min-width: $screen-sm-min) {
      padding: 20px;
    }
  }

  &__image {
    text-align: center;
    width: 100%;
    @media (min-width: $screen-xs-min) {
      width: 30%;
      padding: 0 20px;
    }
    img {
      max-height: 150px;
      @media (min-width: $screen-xs-min) {
        max-height: 100px;
      }
    }
  }

  &__content {
    max-height: 0;
    width: 100%;
    visibility: hidden;
    transition: 0.5s;
    opacity: 0;
    padding-top: 20px;

    @media (min-width: $screen-xs-min) {
      max-height: initial;
      display: block;
      visibility: visible;
      margin-top: 0;
      padding-top: 0;
      padding-left: 20px;
      border-top: 0;
      border-left: 1px solid $border-color;
      opacity: 1;
    }

    &.active {
      max-height: 500px;
      padding-top: 20px;
      margin-top: 10px;
      border-top: 1px solid $border-color;
      visibility: visible;
      opacity: 1;
    }
  }

  &__heading {
    color: $projectBlack
  }

  p, li {
    color: $projectGrey;
  }

  &__view-more {
    cursor: pointer;

    @media (min-width: $screen-xs-min) {
      display: none;
    }
  }
}