//btn
.btn {
  display: block;
  padding: 15px;
  cursor: pointer;
  text-align: center;
  color: $projectBlack;
  text-decoration: none;
  font-size: $text-size-base;
  font-weight: 700;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 3px 0 0 $projectGrey;
  -webkit-font-smoothing: antialiased;
  transition: transform .2s linear;

  &:hover {
    color: $projectBlack;
    box-shadow: 0 3px 0 0 transparent;
  }

  &--brand {
    @include btn_color($brand-primary, $brand-primary-accent);
  }

  &--brand-secondary {
    @include btn_color($brand-secondary, $brand-secondary-accent);
  }

  &--brand-outline {
    border: 1px solid $brand-primary;
    background-color: transparent;
    box-shadow: none;
    &:hover {
      background-color: $brand-primary;
    }
  }
  &--icon {
    position: relative;
    padding-right: 55px;
    &:after {
      float: right;
      font-family: $font-icons;
      content: '\f107';
      background: $brand-primary-accent;
      width: 25px;
      height: 25px;
      line-height: 25px;
      border-radius: 13px;
      position: absolute;
      top: 50%;
      right: 20px;
      margin-top: -12px;
    }
  }
  &--brand-white {
    @include btn_color($projectWhite, $border-color);
    border-color: $border-color;
    color: $link-color;
  }
}

.btn-read-more{
  margin: 10px;
  display: flex;
  justify-content: center;
}