ul {
  li {
    position: relative;
  }
}

.list {
  li {
    padding-left: 30px;
    margin-bottom: 5px;
    &:before {
      content: '';
      position: absolute;
      left: 0;
    }
    .bold {
      font-weight: 700;
    }
  }

  &--bold {
    font-weight: 700;
  }

  &--brand {
    li:before {
      color: $brand-primary;
    }
  }

  &--negative {
    li:before {
      color: $brand-negative;
    }
  }

  &--bullet {
    li {
      &:before {
        top: 7px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }

    &.list--brand {
      li:before {
        background-color: $brand-primary;
      }
    }
  }

  &--numbers {
    counter-reset: numbered-list;

    li {
      &:before {
        content: counter(numbered-list) ".";
        counter-increment: numbered-list;
        font-size: $text-size-base;
        font-weight: 700;
      }
    }
  }

  &--standard {
    li {
      padding-left: 3px;
      &:before {
        position: relative;
        margin-right: 10px;
      }
    }
  }

  &--ticks {
    li {
      &:before {
        content: '\e80e'; // tick icon
        font-family: $font-icons;
        left: 4px;
        top: 1px;
      }
    }
  }

  &--crosses {
    li {
      &:before {
        content: '\e808'; // cross icon
        font-family: $font-icons;
        left: 4px;
        top: 1px;
      }
    }
  }
}