.header {
  border-top: 1px solid #dddfe2;
  border-bottom: 1px solid #dddfe2;
  padding: 20px 0 15px;

  &__logo {
    display: inline-block;
    a {
      display: inline-block;
    }
    img {
      width: 100%;
      max-width: 218px;
    }
  }
  &__title {
    display: none;
    width: 170px;
    height: 49px;
    border-left: 1px solid #dddfe2;
    padding: 5px 0 0 14px;
    vertical-align: top;
    font-size: 0.75rem;
    line-height: 18px;
    color: #7a7879;
    margin-left: 15px;

    @media (min-width: $screen-sm-min) {
      display: inline-block;
    }
  }
}

.nav {
  background: #fff;
  position: fixed;
  top: 89px;
  right: -300px;
  z-index: 100;
  width: 300px;
  height: calc(100% - 89px);
  border-left: 1px solid #dddfe2;
  transition: all .3s linear;
  overflow: auto;
  &__menu {
    border-top: 1px solid #dddfe2;
  }
  &__item {
    border-bottom: 1px solid #dddfe2;
    display: block;
    font-size: 1.2rem;
    &--active {
      .nav__link span {
        transform: rotateX(180deg);
      }
    }
  }
  &__link {
    cursor: default;
    display: block;
    color: $projectGrey;
    padding: 20px 15px;
    span {
      width: 30px;
      height: 30px;
      display: block;
      float: right;
      text-align: center;
      padding: 0 5px;
      font-size: 1.5rem;
      transition-duration: 0.2s;
      @media (min-width: $screen-md-min) {
        display: none;
      }
    }
    &:hover {
      color: $projectGrey;
      border-left: 4px solid $brand-primary;
      @media (min-width: $screen-md-min) {
        border-left: 0;
      }
    }
  }
  &__submenu {
    display: none;
    li {
      border-top: 1px solid #dddfe2;
      font-size: 0.88rem;
      a {
        display: block;
        color: $projectGrey;
        padding: 15px 30px;
      }
    }
  }
  &--open {
    right: 0;
  }
  &__toggle {
    position: relative;
    z-index: 120;
    margin-top: 15px;
    cursor: pointer;
    float: right;
    width: 25px;
    height: 21px;
    span, &:before, &:after {
      content: '';
      position: absolute;
      display: block;
      width: 25px;
      height: 3px;
      top: 7px;
      background: #000;
      transition: all .3s linear;
    }
    &--open {
      position: fixed;
      top: 21px;
      right: 16px;
    }
    &:before {
      top: 0;
    }
    &:after {
      top: 14px;
    }
    &--open {
      .nav__toggle__item {
        opacity: 0;
      }
      &:before {
        top: 5px;
        transform: rotate(45deg);
      }
      &:after {
        top: 5px;
        transform: rotate(-45deg);
      }
    }
    @media (min-width: $screen-md-min) {
      display: none;
    }
  }
  &__background {
    position: fixed;
    top: 89px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 90;
    display: none;
  }

  @media (min-width: $screen-md-min) {
    border: 0;
    position: relative;
    right: 0;
    display: inline-block;
    width: auto;
    top: 0;
    transition: none;
    margin-top: 5px;
    overflow: visible;
    .nav &__menu {
      border: 0;
    }
    .nav &__link {
      padding: 10px 13px;
      font-size: 0.88rem;
    }
    .nav &__item {
      position: relative;
      border: 0;
      display: inline-block;
      &:hover {
        .nav__submenu {
          box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
          display: block !important;
          position: absolute;
          top: 38px;
          left: 0;
          width: 180px;
          background-color: $brand-primary;
          li a {
            color: #fff;
            padding: 8px 0;
            margin: 0 15px;
            border-bottom: 1px solid #aeda9f;
          }
          .nav__view-all {
            background: #6faa64;
            a {
              border: 0;
            }
          }
        }
      }
    }
    .nav &__submenu li {
      border: 0;
    }
  }
}

.header__icons {
  display: none;
  height: 49px;
  float: right;
  border-left: 1px solid #dddfe2;
  padding-left: 29px;

  @media (min-width: $screen-lg-min) {
    display: block;
  }

}