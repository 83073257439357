.container-bic .bonus-cards-skeleton {
    display: grid;
    margin-bottom: 2rem;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  
  @media (min-width: 1024px) {
    .container-bic .bonus-cards-skeleton {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  
  @media (min-width: 1440px) {
    .container-bic .bonus-cards-skeleton {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  
  .container-bic .bonus-cards-skeleton > .bg-skeleton {
    border: #f2f2f5 1px solid;
    border-radius: 8px;
    height: 506px;
  }
  
  .container-bic .commercial-toplist-skeleton {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
  }
  
  @media (min-width: 768px) {
    .container-bic .commercial-toplist-skeleton {
      grid-gap: 24px;
      gap: 24px;
    }
  }
  
  .container-bic .commercial-toplist-skeleton > .toplist-row-skeleton {
    height: 242px;
    border-radius: 16px;
  }
  
  .container-bic .bg-skeleton {
    background-color: #f2f2f5;
    background-image: linear-gradient(90deg, #f2f2f5 0%, #ffffff 20%, #f2f2f5 40%, #f2f2f5 100%);
    background-repeat: no-repeat;
  }
  
  .container-bic .skeleton-animation {
    animation: skeleton-loading 2s infinite;
    animation-timing-function: ease;
  }
  
  @keyframes skeleton-loading {
    0% {
      background-position: -400px 0;
    }
    100% {
      background-position: 400px 0;
    }
  }
  
  .container-bic .hidden {
    display: none;
  }