// ======= Contact Form Styles Start ======= //
.contact_form {
  width: 100%;
  max-width: 840px;
  margin: 25px auto;
  overflow: hidden;
  background: white;
  border-radius: 5px;
  position: relative;

  header,
  footer {
    min-height: 75px;
    background: rgba(0, 0, 0, 0.05);
    line-height: 75px;
    padding: 0 20px;
    border-radius: 5px 5px 0 0;

    h3 {
      font-size: 20px;
      text-transform: uppercase;
      line-height: 75px;
      margin: 0;
      padding-top: 0;
      color: $brand-primary-accent;
    }
  }

  footer {
    border-radius: 0 0 5px 5px;
    @include clearfix();
  }

  input,
  textarea {
    box-sizing: border-box;
    font-family: $font-family-body;
    margin: 0;
    border: 0;
    padding: 5px 5px 5px 20px;
    display: block;
    width: 100%;
    overflow: hidden;
    height: 50px;
    font-size: $text-size-base;

    &:focus {
      outline: none;
      background-color: transparentize($brand-secondary, 0.9);
      color: transparentize($projectBlack, 0.3);
    }

    &.validation--error {
      background: transparentize($brand-secondary, 0.7);
      position: relative;
    }
  }

  .first_last_container {
    display: block;
    @media (min-width: $screen-md-min) {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  &__firstname,
  &__lastname {
    width: 100%;
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);

    //@media #{$screen-medium} {
    //  width: 50%;
    //}
  }

  &__lastname {
    border-top: 0;
    @media (min-width: $screen-md-min) {
      border-left: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  &__email {
    width: 100%;
    border-top: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__message {
    width: 100%;

    textarea {
      width: 100%;
      height: 200px;
      resize: none;
      padding: 20px;
      border-top: 0;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  &__btn {
    width: 100%;
    max-width: 320px;
    height: 50px;
    margin: 12px auto;
    @media (min-width: $screen-md-min) {
      float: right;
      margin: 12px 20px 0 12px;
    }

    &:focus {
      outline: none;
    }
  }

  img {
    max-width: 100%;
  }

  .loader {
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.2);
    top: 0;
    left: 0;
    z-index: 2;
    text-align: center;
    cursor: progress;

    i {
      font-size: 50px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      color: darken($brand-secondary, 10%);
    }
  }

  &__success {
    width: 100%;
    max-width: 840px;
    margin: 25px auto;
    overflow: hidden;
    background: white;
    border-radius: 5px;

    header {
      min-height: 75px;
      background: rgba(0, 0, 0, 0.05);
      line-height: 75px;
      padding: 0 20px;
      border-radius: 5px 5px 0 0;

      h3 {
        padding-top: 0;
        font-size: 24px;
        text-transform: uppercase;
        color: $brand-primary;
        line-height: 75px;
        margin: 0;
      }
    }
    p {
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 20px;
    }
  }
}

// Overwrite validation plugin
.iziToast.iziToast-color-red {
  background: transparentize($brand-rating-stars, 0.1);
  border-color: transparentize($projectBlack, 0.1);
}