.free-games {
  background: $projectOffWhite;
  padding: 30px 0;

  &__title {
    background: $projectWhite;
    border: 1px solid $border-color;
    padding: 10px 30px;
    font-family: $font-family-heading;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 0;
    position: relative;
    &_arrow {
      position: absolute;
      right: 6px;
      top: 50%;
      margin-top: -15px;
      @media (min-width: $screen-md-min) {
        display: none;
      }
    }
    @media (min-width: $screen-xs-min) {
      margin: 0 0.5rem;
    }
    @media (min-width: $screen-md-min) {
      cursor: auto;
    }
  }
  &__count {
    color: $projectGrey;
    text-align: right;
    margin: 10px 0.5rem 0;
    @media (min-width: $screen-md-min) {
      text-align: left;
    }
    span {
      color: $projectBlack;
      font-weight: bold;
    }
  }
  &__heading {
    display: block;
    text-align: center;
  }
  &__description {
    display: block;
    width: 80%;
    text-align: center;
    margin: 0 auto 30px;
  }
}

.games-results {
  margin-top: 10px;

  &__holder {
    flex: 0 0 auto;
    //padding-right: 1rem;
    //padding-left: 1rem;
    flex-basis: 100%;
    max-width: 100%;
    margin: 0;

    @media (min-width: $screen-xs-min) {
      margin: 0 0.5rem;
      flex-basis: 46%;
      max-width: 46%;
    }
    @media (min-width: $screen-lg-min) {
      flex-basis: 31%;
      max-width: 31%;
    }
  }

  &__item {
    background: $projectWhite;
    padding: 14px 14px 44px;
    margin-bottom: 20px;
    position: relative;
    border: 1px solid $border-color;

    @media (min-width: $screen-sm-min) {
      padding: 14px;
      min-height: 200px;
      &:hover {
        padding: 14px 14px 54px;
        margin-bottom: 0;
        top: -5px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        .games-results__button, .games-results__fun {
          display: block;
        }
        .games-results__rating {
          display: block;
        }
        .games-results__image {
          padding-top: 50%;
          //&:after {
          //
          //  }
          //  img {
          //    position: absolute;
          //    top: 0;
          //    bottom: 0;
          //    right: 0;
          //    left: 0;
          //  }
        }
      }
    }
  }
  &__title {
    display: block;
    font-weight: bold;
    font-family: $font-family-heading;
    color: $brand-primary;
    min-height: 2.3em;
    position: relative;
    line-height: 1.1em;
    margin-bottom: 4px;
    &_inner {
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: auto;
    }
  }
  // slight hack to avoid creating mobile versions
  &__image {
    position: relative;
    overflow: hidden;
    margin-bottom: 5px;
    padding: 60% 0 0 0;
    background: url("#{$image-path}/giochi-gratis-on-error.jpg") 0 0 transparent no-repeat scroll;
    background-size: cover;

    img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
    }
    @media (min-width: $screen-sm-min) {

      img {
        //position: relative;
        //top: auto;
        //right: auto;
        //bottom: auto;
        //left: auto;
      }
    }
  }
  &__type {
    display: block;
    color: $projectGrey;
    margin-bottom: 5px;
  }
  &__rating {
    position: absolute;
    z-index: 12;
    top: 14px;
    left: 14px;
    padding: 2px 10px;
    color: $brand-secondary;
    background: $projectWhite;
    i:before {
      margin: 0;
    }
    @media (min-width: $screen-sm-min) {
      display: none;
    }
  }
  &__button {
    padding: 5px;
    @media (min-width: $screen-sm-min) {
      display: none;
      padding: 8px;
    }
  }
  &__fun {
    margin-top: 5px;
    text-align: center;
    display: block;
    @media (min-width: $screen-sm-min) {
      display: none;
    }
  }
}

.categories {
  position: relative;

  &__header {
    background: $projectBlack;
    color: $projectWhite;
    font-size: 1.5rem;
    font-family: $font-family-heading;
    font-weight: bold;
    padding: 10px 30px;
    display: none;
    @media (min-width: $screen-md-min) {
      display: block;
    }
  }

  &__menu {
    position: absolute;
    top: 55px;
    left: 0;
    z-index: 33;
    overflow: hidden;
    display: none;
    width: 100%;
    flex-wrap: wrap;

    @media (min-width: $screen-md-min) {
      display: flex;
      position: relative;
      top: auto;
      left: auto;
    }

    &--open {
      display: flex;
    }

    li.categories__item {
      cursor: pointer;
      background: $projectWhite;
      border-left: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      width: 50%;
      height: 110px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        max-height: 30px;
        margin-bottom: 10px;
      }

      span {
        display: block;
        color: $brand-primary;
        font-family: $font-family-heading;
        font-size: 0.95rem;
        font-weight: bold;
      }

      &:nth-child(even) {
        border-right: 1px solid $border-color;
      }
      &:hover, &--active {
        background: $projectOffWhite;
      }
    }
  }
}

.game-collections {
  padding: 20px 25px;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  display: none;
  @media (min-width: $screen-md-min) {
    display: block;
  }
  &__title {
    display: block;
    border-bottom: 1px solid $border-color;
    padding-bottom: 10px;
    font-family: $font-family-heading;
    font-weight: bold;
    margin-bottom: 8px;
  }
  &__menu {
    li a {
      color: $brand-primary;
      font-family: $font-family-heading;
      cursor: pointer;
      font-size: 0.9rem;
      &:hover {
        color: $brand-primary-accent;
      }
    }
  }
}