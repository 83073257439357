@font-face {
  font-family: 'Lato';
  src: url("#{$fonts-path}/lato/lato-light.eot");
  src: url("#{$fonts-path}/lato/lato-light.eot?#iefix") format("embedded-opentype"),
  url("#{$fonts-path}/lato/lato-light.woff2") format("woff2"),
  url("#{$fonts-path}/lato/lato-light.woff") format("woff"),
  url("#{$fonts-path}/lato/lato-light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("#{$fonts-path}/lato/lato-regular.eot");
  src: url("#{$fonts-path}/lato/lato-regular.eot?#iefix") format("embedded-opentype"),
  url("#{$fonts-path}/lato/lato-regular.woff2") format("woff2"),
  url("#{$fonts-path}/lato/lato-regular.woff") format("woff"),
  url("#{$fonts-path}/lato/lato-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("#{$fonts-path}/lato/lato-bold.eot");
  src: url("#{$fonts-path}/lato/lato-bold.eot?#iefix") format("embedded-opentype"),
  url("#{$fonts-path}/lato/lato-bold.woff2") format("woff2"),
  url("#{$fonts-path}/lato/lato-bold.woff") format("woff"),
  url("#{$fonts-path}/lato/lato-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("#{$fonts-path}/lato/lato-black.eot");
  src: url("#{$fonts-path}/lato/lato-black.eot?#iefix") format("embedded-opentype"),
  url("#{$fonts-path}/lato/lato-black.woff2") format("woff2"),
  url("#{$fonts-path}/lato/lato-black.woff") format("woff"),
  url("#{$fonts-path}/lato/lato-black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoSlab';
  src: url('#{$fonts-path}/robotoslab/RobotoSlab-Thin.eot');
  src: local('☺'),
  url('#{$fonts-path}/robotoslab/RobotoSlab-Thin.woff') format('woff'),
  url('#{$fonts-path}/robotoslab/RobotoSlab-Thin.ttf') format('truetype'),
  url('#{$fonts-path}/robotoslab/RobotoSlab-Thin.svg') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoSlab';
  src: url('#{$fonts-path}/robotoslab/RobotoSlab-Light.eot');
  src: local('☺'),
  url('#{$fonts-path}/robotoslab/RobotoSlab-Light.woff') format('woff'),
  url('#{$fonts-path}/robotoslab/RobotoSlab-Light.ttf') format('truetype'),
  url('#{$fonts-path}/robotoslab/RobotoSlab-Light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoSlab';
  src: url('#{$fonts-path}/robotoslab/RobotoSlab-Regular.eot');
  src: local('☺'),
  url('#{$fonts-path}/robotoslab/RobotoSlab-Regular.woff') format('woff'),
  url('#{$fonts-path}/robotoslab/RobotoSlab-Regular.ttf') format('truetype'),
  url('#{$fonts-path}/robotoslab/RobotoSlab-Regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoSlab';
  src: url('#{$fonts-path}/robotoslab/RobotoSlab-Bold.eot');
  src: local('☺'),
  url('#{$fonts-path}/robotoslab/RobotoSlab-Bold.woff') format('woff'),
  url('#{$fonts-path}/robotoslab/RobotoSlab-Bold.ttf') format('truetype'),
  url('#{$fonts-path}/robotoslab/RobotoSlab-Bold.svg') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'casino-it-icons';
  src: url('#{$fonts-path}/fontello/casinoit.eot?8560733');
  src: url('#{$fonts-path}/fontello/casinoit.eot?8560733#iefix') format('embedded-opentype'),
  url('#{$fonts-path}/fontello/casinoit.woff2?8560733') format('woff2'),
  url('#{$fonts-path}/fontello/casinoit.woff?8560733') format('woff'),
  url('#{$fonts-path}/fontello/casinoit.ttf?8560733') format('truetype'),
  url('#{$fonts-path}/fontello/casinoit.svg?8560733#casinoit') format('svg');
  font-weight: normal;
  font-style: normal;
}