.footer {
  background-color: $projectBlack;
  margin-top: 50px;
  .footer__title {
    color: $projectWhite;
    display: block;
    margin: 20px 0 5px;
    font-family: $font-family-heading;

    @media (min-width: $screen-sm-min) {
      margin: 50px 0 10px;
    }
  }

  &__description {
    color: $projectGrey;
  }

  &__menu {
    margin-bottom: 0;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 40px;
    }

    li {
      border-bottom: 1px solid $projectGrey;
      padding: 7px 0;

      a {
        color: $projectGrey;

        &:hover {
          color: $brand-primary;
        }
      }
    }
  }

  &__logos {
    margin-top: 20px;
    text-align: left;
    margin-bottom: 20px;

    @media (min-width: $screen-sm-min) {
      text-align: right;
    }

    img {
      margin: 0 20px 0 0;

      @media (min-width: $screen-sm-min) {
        margin: 0 0 0 20px;
      }
    }
  }
  &__tagline {
    color: $projectWhite;
    font-size: $heading-size-h5;
    margin-top: 10px;

    @media (min-width: $screen-sm-min) {
      margin-top: 0;
      margin-bottom: 20px;
    }

    &:after {
      content: '';
      width: 50px;
      display: block;
      margin-top: 10px;
      border-bottom: 2px solid $brand-primary;
    }
  }
}

.footer-copyright {
  font-size: 0.8rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background-color: $projectBlack;
  padding: 30px 0;

  &__text {
    font-size: 0.8rem;
    color: $projectGrey;

    a {
      color: $brand-primary;
    }
  }

  &__menu {
    text-align: left;
    margin-bottom: 20px;

    @media (min-width: $screen-sm-min) {
      text-align: right;
      margin-bottom: 0;
    }
  }

  &__item {
    display: block;

    @media (min-width: $screen-sm-min) {
      display: inline-block;
      margin-left: 8px;
      border-left: 1px solid $projectGrey;
      padding-left: 8px;
    }

    a {
      color: $projectGrey;

      &:hover {
        color: $brand-primary;
      }
    }

    &:first-child {
      border: 0;
      margin-left: 0;
      padding-left: 0;
    }
  }
}