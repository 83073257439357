.toplist {
  padding-top: 60px;
  padding-bottom: 60px;

  &--pb-0 {
    padding-bottom: 0;
  }

  &__title {
    text-align: center;
    margin-bottom: 30px;
  }

  &--inner {
    .toplist__title {
      margin-bottom: 0;
    }
  }

  &__description {
    margin: 0 auto;

    @media (min-width: $screen-md-min) {
      width: 80%;
    }

    & + .toplist__description {
      padding-top: 0;
    }
  }

  &__row,
  &__header {
    display: flex;
  }

  &__holder {
    margin-top: 30px;
  }

  &__header {
    display: none;
    padding: 0 20px;
    border: 1px solid $border-color;
    background: $projectOffWhite;
    align-items: center;

    @media (min-width: $screen-sm-min) {
      display: flex;
    }

    p {
      color: $projectBlack;
      align-self: center;
      flex-grow: 0;
      font-size: 0.9rem;
    }
  }

  &__row {
    position: relative;
    color: $projectGrey;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    padding: 20px;

    &:hover {
      background-color: $projectOffWhite;

      .toplist__btn {
        background-color: $brand-primary-accent;
        transform: translateY(3px);
        box-shadow: none;
      }

      .toplist__bonus{
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
      }

    }

    &--featured {
      background-color: transparentize($brand-primary, 0.9);
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
      border: 1px solid $brand-primary;

      &:hover {
        background-color: transparentize($brand-primary, 0.8);

        .toplist__btn {
          background-color: $brand-secondary-accent;
        }
      }

      .toplist__payout,
      .toplist__payout-speed,
      .toplist__games,
      .toplist__payment-options,
      .toplist__platforms,
      .toplist__divider {
        display: block;
      }

      .toplist__games,
      .toplist__payment-options,
      .toplist__platforms {
        @media (min-width: $screen-md-min) {
          display: none;
        }

        @media (min-width: $screen-lg-min) {
          display: block;
        }
      }

      .toplist__divider {
        @media (min-width: $screen-md-min) {
          display: none;
        }
      }
    }
  }

  &__label {
    font-size: 1rem;
    color: $projectBlack;
    display: block;
    font-weight: normal;
    padding: 0;

    @media (min-width: $screen-md-min) {
      display: none;
    }
  }

  &__value {
    padding: 0;
  }

  &__divider {
    display: none;
    height: 1px;
    background-color: $border-color;
    width: 100%;
    margin: 20px;
  }

  &__position {
    font-size: 1.5rem;
    flex-basis: 10%;

    @media (min-width: $screen-md-min) {
      flex-basis: 7%;
      max-width: 7%;
    }

    @media (min-width: $screen-lg-min) {
      order: 1;
      flex-basis: 4%;
      max-width: 4%;
    }
  }

  &__casino {
    flex-basis: 50%;

    @media (min-width: $screen-md-min) {
      flex-basis: 25%;
      max-width: 25%;
    }

    @media (min-width: $screen-lg-min) {
      order: 2;
      flex-basis: 14%;
      max-width: 14%;
    }

    i:before {
      font-size: 1.2rem;
      color: $brand-rating-stars;
    }

    img {
      display: block;
      margin: 0 auto 10px;
      max-width: 125px;
    }
  }

  &__bonus {
    flex-basis: 40%;
    transition: -webkit-transform .2s linear;
    transition: transform .2s linear;
    transition: transform .2s linear,-webkit-transform .2s linear;


    @media (min-width: $screen-md-min) {
      flex-basis: 16%;
      max-width: 16%;
    }

    @media (min-width: $screen-lg-min) {
      order: 3;
      flex-basis: 15%;
      max-width: 15%;
    }

    .toplist__value {
      color: $brand-primary;
      font-weight: bold;
      font-size: 1.5rem;

    }
  }

  &__payout {
    display: none;
    flex-basis: 30%;

    @media (min-width: $screen-md-min) {
      display: block;
      flex-basis: 16%;
      max-width: 16%;
    }

    @media (min-width: $screen-lg-min) {
      order: 4;
      flex-basis: 8%;
      max-width: 8%;
    }
  }

  &__payout-speed {
    display: none;
    flex-basis: 40%;

    @media (min-width: $screen-md-min) {
      display: block;
      flex-basis: 16%;
      max-width: 16%;
    }

    @media (min-width: $screen-lg-min) {
      order: 5;
      flex-basis: 10%;
      max-width: 10%;
    }
  }

  &__games {
    display: none;
    flex-basis: 30%;

    @media (min-width: $screen-lg-min) {
      display: block;
      order: 6;
      flex-basis: 8%;
      max-width: 8%;
    }
  }

  &__payment-options {
    display: none;
    flex-basis: 70%;
    margin-top: 10px;

    @media (min-width: $screen-lg-min) {
      display: block;
      order: 8;
      flex-basis: 15%;
      max-width: 15%;
      margin-top: 0;
    }

    &__value {
      display: flex;
      justify-content: space-around;
      align-content: center;
      flex-wrap: wrap;
      padding: 10px;

      img {
        align-self: center;
        margin: 4px;
        max-width: 50px;
      }

      img:nth-child(n+4) {
        display: none;
        @media (min-width: $screen-sm-min) {
          display: inline;
        }
      }
    }
  }

  &__platforms {
    display: none;
    flex-basis: 30%;
    margin-top: 10px;

    @media (min-width: $screen-lg-min) {
      display: block;
      order: 7;
      flex-basis: 12%;
      max-width: 12%;
      margin-top: 0;
    }
  }

  &__button-block {
    flex-basis: 100%;
    margin-top: 10px;

    @media (min-width: $screen-md-min) {
      flex-basis: 20%;
      max-width: 20%;
      margin-top: 0;
    }

    @media (min-width: $screen-lg-min) {
      order: 9;
      flex-basis: 14%;
      max-width: 14%;
    }
  }

  &__btn {
    max-width: 370px;
    margin: 0 auto;
    display: block;
  }

  &__review-link {
    color: $brand-links;
    margin-top: 10px;
    display: block;
    position: relative;
    z-index: 2;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;

  }
}