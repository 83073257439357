.scrollable-table {
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;

  .table {
    margin: 0;
  }
}

.table {
  display: table;
  margin: 20px 0;

  &.bounce {
    animation: bounce--horizontal 1.5s 1;
    @media (min-width: $screen-xs-min) {
      animation: none;
    }
  }

  &__row {
    display: table-row;
    &:last-of-type {
      .table__cell {
        border-bottom: 1px solid $border-color;
      }
    }
  }

  &__heading {
    background-color: $projectBlack;
    display: table-header-group;
    font-weight: bold;
    color: $projectWhite;
  }

  &__body {
    display: table-row-group;
  }

  &__cell,
  &__head {
    border-left: 1px solid $border-color;
    border-top: 1px solid $border-color;
    display: table-cell;
    padding: 3px 10px;

    &:last-of-type {
      border-right: 1px solid $border-color;
    }
  }
}