.hero {
  background: 50% 50% $projectBlack url(#{$image-path}/bg/slider-bg.jpg) no-repeat;
  background-size: cover;
  padding-bottom: 20px;
  @media (min-width: $screen-sm-min) {
    padding-bottom:0;
  }
  &__heading {
    color: $projectWhite;
    margin-top: 30px;

    @media (min-width: $screen-md-min) {
      margin-top: 50px;
    }
  }

  &__subheading {
    font-size: $text-size-base;
    font-weight: 700;

    color: $projectWhite;
    margin-top: 10px;
  }

  &__description {
    color: $projectWhite;
    font-size: $text-size-base;
  }

  &__list {
    color: $projectWhite;
  }

  .btn {
    margin-bottom: 30px;
    @media (min-width: $screen-lg-min) {
      margin-bottom: 70px;
    }
  }

  &__tagline {
    color: $projectWhite;
    font-size: 1.3rem;
    font-weight: lighter;
    margin-bottom: 40px;
    &:after {
      content: '';
      display: block;
      width: 40px;
      border-bottom: 2px solid $brand-primary;
      margin-top: 10px;
    }
  }

  &__icons {
    overflow: scroll;
    @media (min-width: $screen-sm-min) {
      overflow: auto;
    }
  }

  &__logo-menu {
    margin-bottom:30px;
    display: inline-block;
    width: 700px;
    @media (min-width: $screen-sm-min) {
      width: auto;
    }
    li {
      height: 58px;
      line-height: 58px;
      float: left;
      padding: 0 15px;
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      img {
        vertical-align: middle;
      }
      &:last-child {
        border-right: 0;
      }
      @media (min-width: $screen-md-min) {
        padding: 0 10px;
        margin-bottom: 0;
      }
      @media (min-width: $screen-lg-min) {
        padding: 0 15px;
      }
    }

  }
}


