.available-games {
  text-align: center;
  margin-top: 20px;

  .row {
    margin: 0;
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }

  &__item {
    border-left: 1px solid $border-color;
    border-top: 1px solid $border-color;
    position: relative;
    padding: 10px;

    &:before {
      content: '';
      position: absolute;
      left: 5px;
      top: 5px;
      font-size: $text-size-small;
      font-family: $font-icons;
    }

    &--available:before {
      content: '\e80e';
      color: $brand-primary;
    }

    &--unavailable:before {
      content: '\e813';
      color: $projectGrey;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      align-self: center;
      max-height: 40px;
    }
  }

  &__label {
    font-weight: 700;
    color: $brand-primary;

  }

  &__btn {
    margin-top: 20px;
  }
}