.faq {
  padding: 30px 0 0;
  ul{
    margin-top: 20px;
  }
  &__title {
    text-align: center;
    &--no-desc {
      margin-bottom: 20px;
    }
  }
  &__description {
    text-align: center;
    margin-bottom: 10px;
    @media (min-width: $screen-sm-min) {
      width: 90%;
      margin: 0 auto 20px;
    }
  }
  &__item {
    border: 1px solid #ddd;
    margin-bottom: 20px;
    transition-duration: 0.5s;
    &--open {
      background: $projectBlack;
      .faq__item_title {
        color: $projectWhite;
      }
      .faq__item_content {
        color: $projectWhite;
      }
      .icon-down-open {
        transform: rotateX(180deg);
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__item_title {
    padding: 17px 30px;
    @media (min-width: $screen-sm-min) {
      padding: 17px 50px;
    }
    font-family: $font-family-heading;
    font-weight: bold;
    cursor: pointer;
    position: relative;

    &:before {
      position: absolute;
      color: $brand-primary;
      width: 16px;
      font-size: 18px;
      left: 6px;
      top: 50%;
      transform: translateY(-50%);
      @media (min-width: $screen-sm-min) {
        margin-left: 20px;
      }
    }
  }
  &__item_content {
    padding: 0 30px 20px;
  }
  .icon-down-open {
    position: absolute;
    top: 15px;
    right: 20px;
    transition-duration: 0.5s;

    @media (min-width: $screen-xs-min) {
      top: 15px;
      right: 26px;
    }
    &:before {
      color: $brand-primary;
    }
  }
}