.toplist__holder--inner {
  border-top: 1px solid $border-color;
  display: none;
  margin-bottom: 30px;

  @media (min-width: $screen-md-min) {
    display: block;
  }

  .toplist__label {
    display: block;
    font-size: $text-size-small;
  }
}

.top-3 {
  align-items: flex-start;
  margin-top: 30px;
  max-width: 100%;

  &__wrapper {
    width: 100%;
    margin: 0;
  }

  &__main-link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    &:focus {
      outline: 1px solid $projectWhite;
    }
  }

  &__item {
    position: relative;
    color: $projectGrey;
    padding: 15px 15px 30px;
    border: 1px solid $border-color;
    margin-top: 35px;
    width: 32%;
    max-width: 400px;

    &--1 {
      width: 36%;
    }

    @media (min-width: $screen-sm-min) {
      max-width: initial;

      &--2 {
        border-right: 0;
      }
    }

    &--3 {
      border-left: 0;
    }

    &:hover {
      background-color: $projectOffWhite;
      color: $projectGrey;

      .top-3__btn .btn {
        background: $brand-primary;
      }

      .top-3__bonus span {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
      }
    }

    &--featured {
      border: 2px solid $brand-primary;
      background: transparentize($brand-primary, 0.9);
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      margin-top: 0;

      .top-3__position {
        background: $projectBlack;
        color: $projectWhite;
        &__number:after {
          display: none;
        }
      }

      &:hover {
        background-color: transparentize($brand-primary, 0.8);

        .top-3__btn .btn {
          background-color: $brand-primary-accent;
          transform: translateY(3px);
          box-shadow: none;
        }
      }
    }
  }

  &__btn {
    display: none;

    @media (min-width: $screen-xs-min) {
      width: 45%;
      margin-top: 10px;
    }

    @media (min-width: $screen-sm-min) {
      display: block;
      width: 100%;
    }

    &--bottom {
      display: block;
    }

    .btn {
      max-width: 370px;
      margin: 0 auto;
    }
  }

  &__position {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $border-color;
    font-size: 1.5rem;
    vertical-align: middle;
    position: relative;
    padding: 10px 5px;

    &__number {
      align-self: center;

      &:after {
        content: "";
        width: 60px;
        border-bottom: 2px solid $brand-primary;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__text {
      align-self: center;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      padding: 0 10px;
      margin-left: 10px;
      font-size: 0.8rem;
      text-transform: uppercase;
    }
  }

  &__image {
    margin-top: 10px;

    img {
      margin: 0 auto;
    }
  }

  &__rating {
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    @media (min-width: $screen-xs-min) {
      width: 50%;
      padding: 10px 0;
      border-right: 1px solid $border-color;
      border-top: 1px solid $border-color;
    }

    @media (min-width: $screen-sm-min) {
      width: 100%;
      padding: 0;
      border: none;
    }

    &__stars {
      align-self: center;
    }

    &__value {
      align-self: center;
      padding: 0;
    }

    i:before {
      font-size: 1rem;
      color: $brand-rating-stars;
    }

    span {
      font-weight: bold;
    }
  }

  &__review {
    display: block;
    color: $brand-links;
    margin: 20px auto 0;
    padding-bottom: 10px;
    font-size: $text-size-small;
    position: relative;
    z-index: 9;

    &--bottom {
      width: 100%;
      z-index: 2;
      margin-top: 10px;

      @media (min-width: $screen-xs-min) {
        width: 45%;
      }

      @media (min-width: $screen-sm-min) {
        display: none;
      }
    }
  }

  &__bonus {
    padding: 10px 0 0;

    @media (min-width: $screen-xs-min) {
      width: 50%;
      border-top: 1px solid $border-color;
      padding: 10px 0;
    }

    span {
      font-size: 1.5rem;
      color: $brand-primary;
      display: block;
      font-weight: 700;
      transition: -webkit-transform 0.2s linear;
      transition: transform 0.2s linear;
      transition: transform 0.2s linear, -webkit-transform 0.2s linear;
    }

    p {
      padding: 0;
    }
  }

  &__game-image {
    display: none;

    @media (min-width: $screen-xs-min) {
      display: block;
    }

    @media (min-width: $screen-md-min) {
      margin-top: 20px;
    }

    img {
      margin: 0 auto;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-top: 6px;

    @media (min-width: $screen-xs-min) {
      flex-direction: row;
    }

    &--btn {
      justify-content: space-between;
    }
  }

  &__label {
    width: 100%;
    text-align: center;
    font-size: ($text-size-base * 0.82);
    padding: 0 10px 0 0;
    align-self: center;
    padding-bottom: 2px;

    @media (min-width: $screen-xs-min) {
      text-align: right;
      width: 50%;
    }
  }

  &__value {
    width: 100%;
    padding: 0;
    align-self: center;
    text-align: center;

    @media (min-width: $screen-xs-min) {
      text-align: left;
      width: 50%;
    }

    &--banking {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-content: center;

      @media (min-width: $screen-xs-min) {
        justify-content: flex-start;
      }

      img {
        margin-right: 4px;
        align-self: center;
      }
    }
  }

  .slick {
    &-arrow {
      position: absolute;
      top: 120px;
      background: $projectBlack;
      display: block;
      border: 0;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      z-index: 1;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:before {
        font-family: $font-icons;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: $projectWhite;
        width: 100%;
        text-align: center;
      }
    }

    &-prev {
      left: 28px;

      &:before {
        content: "\f104";
      }
    }

    &-next {
      right: 28px;

      &:before {
        content: "\f105";
      }
    }

    &-disabled {
      background: $border-color;
    }
  }
}
