.jackpot {
  background: 50% 50% $projectBlack;
  background-size: cover;
  text-align: center;
  padding: 70px 0 50px;
  position: relative;

  &__wrapper {
    position: relative;
  }

  &__over-cta {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  &__menu {
    margin-top: 30px;
    li:nth-child(n+9) {
      display: none;
      @media (min-width: $screen-md-min) {
        display: block;
      }
    }
  }
  &__heading {
    color: $projectWhite;
  }
  &__description {
    color: $projectWhite;
    @media (min-width: $screen-sm-min) {
      width: 80%;
      display: block;
      margin: 0 auto;
    }
  }
  &__image {
    width: 100%;
    transition-duration: 0.2s;
    border-radius: 75px;
    border: 2px solid #34495e;
    img {
      display: block;
      width: 100%;
    }
  }
  &__border {
    position: relative;
    border-radius: 75px;
    border: 0px solid #7bc78f;
    overflow: hidden;
  }
  &__play {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: $projectWhite;
    opacity: 0;
    transition-duration: 0.2s;
    span {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      margin-top: -12px;
    }
  }
  &__top {
    max-width: 150px;
    height: 165px;
    overflow: hidden;
    margin: 0 auto;
  }
  &__item {
    display: block;
    margin-bottom: 30px;
    &:hover {
      .jackpot__image {
        width: 92%;
        margin: 4% 0 0 4%;
        border: 8px solid #34495e;
      }
      .jackpot__border {
        border: 3px solid #7bc78f;
      }
      .jackpot__play {
        opacity: 1;
      }
    }
  }
  &__prize {
    color: $projectWhite;
    font-weight: bold;
  }
}