.review-content {
  margin-top: 20px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    @media (min-width: $screen-sm-min) {
      flex-direction: row;
    }
  }

  &__title {
    color: $projectBlack;
    font-size: $heading-size-h4;
  }

  &__rating {
    display: flex;
    margin-top: 4px;
    color: $brand-rating-stars;
    justify-content: space-between;
    align-items: center;

    span {
      align-self: center;
      margin: 0 2px;
    }
  }

  &__section {
    margin-top: 20px;

    .features__title {
      margin-bottom: 20px;
    }
  }

  &__section.features__section {
    border: 1px solid $border-color;
    padding: 20px;
  }

  &__mobile-container {
    float: right;
    min-height: 100px;
    min-width: 300px;
    text-align: center;
    border: 1px solid $border-color;
    margin-top: 11px;

    .mobile__title {      
      background: $projectBlack;
      padding: 10px 0;
      color: $projectWhite;

      h3 {
        font-size: 1.3rem;
      }
    }

    .mobile__content {
      padding: 20px 15px;
    }

    .mobile__list {
      display: flex;
      justify-content: space-between;
      margin: 0px;
      padding: 0px;
      list-style-type: none;

      span, strong, p {
        font-size: 18px;
        margin: 0px;
        padding: 0px;
        text-transform: capitalize;
      }

      li > span:first-child {
        color: $brand-primary;
      }
    }
  }

  &__game-detail-section {

    .list {
      margin-bottom: 20px;
      
      li {
        padding: 4px 0;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: center;

        .details__title {
          flex-basis: 60%;
        }
  
        .details__desc {
          flex-basis: 40%;          
        }
      }
    }

    .section__game-footer a {
      padding: 10px;
      display: block;
      text-align: center;
    }
  }
}