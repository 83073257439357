.review-screenshots {
    &__item {
        &_image{
            margin: 0 auto;
        }
    }
    &__main-image {
        li {
            display: none;
            list-style: none;
            img {
                width: 100%;
            }
            &.first {
                display: block;
            }
        }
        margin-bottom: 10px;
    }
    &__thumbnails {
        margin-bottom: 10px;
        padding: 0 20px;
        position: relative;
        display: none;
        li {
            width: 100px;
            margin: 0 5px;
            padding: 2px 0;
            list-style: none;
            float: left;
            cursor: pointer;
            &:focus {
                outline: none;
            }
            img {
                opacity: 0.7;
            }
            &.slick-current {
                img {
                    opacity: 1;
                    outline: 2px solid $brand-primary;
                }
            }
        }
        .slick {
            &-arrow {
                position: absolute;
                top: 0px;
                height: 100%;
                border: 0;
                background: none;
                width: 20px;
                cursor: pointer;
                &:before {
                    font-family: $font-icons;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    color: $brand-primary;
                    width: 100%;
                    text-align: center;
                }
                &:focus {
                    outline: none;
                    &:before {
                        color: $brand-primary-accent;
                    }
                }
            }
            &-prev {
                left: -5px;
                &:focus {
                    box-shadow: inset 2px 0 0 0 $brand-primary;
                }
                &:before {
                    content: '\f104';
                }
            }
            &-next {
                right: -5px;
                &:focus {
                    box-shadow:inset -2px 0 0 0 $brand-primary;
                }
                &:before {
                    content: '\f105';
                }
            }
        }
    }
    .slick-initialized {
        display: block;
    }
}