.inner-header {
  background: $projectOffWhite;
  box-shadow: inset 0 4px 7px -4px rgba(0, 0, 0, 0.20);
  position: relative;
  padding-bottom: 50px;

  &__heading {
    color: $projectBlack;
    margin-top: 20px;
    font-family: $font-family-heading;
    @media (min-width: $screen-sm-min) {
      margin-top: 50px;
    }
  }
  &__description {
    font-size: 1rem;
  }
  &__image {
    display: flex;
    margin: 10px auto 0;
    justify-content: center;
    @media (min-width: $screen-md-min) {
      margin-top: 90px;
      display: block;
    }

    img {
      border-radius: 50%;
      background-color: #fff;
      box-sizing: content-box;
      width: 115px;
      align-self: center;
      border: 2px solid #73ca74;
      padding: 25px;
      @media (min-width: $screen-md-min) {
        width: 200px;

      }
    }
    &--corner {
      display: inline-block;
      float: right;
      margin: 0 0 20px 20px;

      img {
        padding: 0;
        border: none;
        @media (min-width: $screen-md-min) {
          border: 2px solid #73ca74;
          padding: 25px;
          margin: 10px 0 2px 10px;
        }
      }
    }

    &--casino-terrestri {
      padding-top: 55px;
      text-align: center;

      img {
        padding: 2px;
        border: 1px solid #73ca74;

        @media (max-width: $screen-md-min) {
          max-width: 300px;
          
        }
      }

      @media (max-width: $screen-md-min) {
        padding-top: 23px;
      }
    }
  }
  &__button {
    width: 100%;
    margin: 20px 0 30px;
    @media (min-width: $screen-md-min) {
      margin: 40px 0 80px;
      width: 80%;
    }
  }
  &:after {
    content: '';
    background: url(#{$image-path}/arrow.png);
    position: absolute;
    bottom: -40px;
    right: 0;
    left: 0;
    margin: auto;
    width: 20px;
    height: 89px;
    background-size: 20px;
    background-repeat: no-repeat;

  }
  &--no-arrow {
    &:after {
      display: none;
    }
  }

  &__template {
    max-width: 100%;
    border: 2px solid $brand-primary;
    padding: 15px 10px 25px;
    margin: 15px 0;

    box-shadow: 0 1px 7px 0px rgba(0,0,0,.2);

    .item {
      &__rating-right-side {
        float: right;
        text-align: right;
      }
  
      &__diff--end-rating {
        margin-left: -4px;
        color: $projectGrey;
      }
  
      &__rating li {
        padding: 10px 0;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
      }
  
      &__rating li:last-child {
        border-top: 0px;
      }
  
      &__game-information {
        margin-top: 20px;
  
        li {
          padding-top:5px;
        }
  
        .item__game-info-key,
        .item__game-info-value {
          width: 49%;
          display: inline-block;
        }
  
        .item__game-info-key {
          color: $projectBlack;
        }
  
        .item__game-info-value {
          color: $projectGrey;
        }
  
      }
  
      &__white-bg-logo {
        padding: 20px 5px;
        background: #fff;
        text-align: center;
        border: 1px solid $border-color;
        border-radius: 5px;
        margin-bottom: 20px;
  
        a {
          margin-top: 10px;
          display: block;
        }
  
        &--casino {
          padding: 30px 0;
          float: left;
          margin-right: 10px;
  
          img {
            max-width: 100px;
          }        
        }
  
        &--casino + span {
          position: relative;
          top: 30px;
          font-size: 14px;
        }
      }
  
      &__play-btn-container {
        clear: both;
        
      }
  
    }
  }
    

}