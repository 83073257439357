.blacklist {
    background: $projectBlack;
    padding-top: 50px;
    &__heading {
        text-align: center;
        color: $projectWhite;
    }
    &__description {
        text-align: center;
        color: $projectWhite;
        @media (min-width: $screen-sm-min) {
            width: 80%;
            display: block;
            margin: 0 auto;
        }
    }
    .btn--brand {
        width: 95%;
        margin: 30px 0 65px 0;
        @media (min-width: $screen-sm-min) {
            width: 60%;
        }
    }
}

.blacklist-item {
    &__content {
        text-align: left;
        background: $brand-negative;
        margin-top: 50px;
        position: relative;
    }
    &__logo {
        position: absolute;
        top: -20px;
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        background: $projectWhite;
        border-radius: 4px;
        margin: 0 25px;
        border: 1px solid #ddd;
        img {
            vertical-align: middle;
        }
    }
    &__name {
        height: 80px;
        margin: 10px 0 0 140px;
        display: inline-block;
        float: left;
        color: $projectWhite;
    }
    &__name_text {
        display: block;
        font-size: 1.4rem;
    }
    &__reason {
        clear: left;
        display: block;
        padding: 0 20px 20px;
        &__title {
            display: block;
            font-family: $font-family-heading;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.8rem;
            color: $projectWhite;
            letter-spacing: 1px;
            margin-bottom: 4px;
        }
        ul li {
            color: $projectWhite;
            margin-bottom: 4px;
            font-size: $text-size-small;
            i {
                color: $brand-rating-stars--off;
            }
        }
    }
    .icon {
        color: $brand-rating-stars;
        &:before {
            margin-right: 5px;
            margin-left: 0;
        }
        &--off {
            color: $brand-rating-stars--off;
        }
    }
    .blacklist-item__rating {
        font-weight: bold;
    }
}