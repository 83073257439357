.operator-info {
  &__title {
    font-size: $heading-size-h4;
    color: $projectBlack;
    margin-bottom: 10px;
  }

  &__sidebar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
  }

  &__item {
    margin-bottom: 10px;
    width: 100%;
    &__title {
      background-color: $projectBlack;
      font-size: $heading-size-h6;
      color: $projectWhite;
      padding: 10px 20px;
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      @media(min-width: $screen-sm-min) {
        padding: 20px;
      }

      p {
        padding: 0;
      }

      &--border {
        border: 1px solid $border-color;
      }

      &--border-top {
        border-top: 1px solid $border-color;
        padding: 20px 0;
      }
    }

    &--languages {
      @media(min-width: $screen-xs-min) {
        width: 50%;
      }

      @media(min-width: $screen-sm-min) {
        width: calc(100% / 3.2);
      }

      @media(min-width: $screen-md-min) {
        width: 100%;
        order: 1;
      }

      .operator-info__item__content {
        justify-content: flex-start;
      }
    }

    &--support {
      @media(min-width: $screen-xs-min) {
        width: 50%;
      }

      @media(min-width: $screen-sm-min) {
        width: calc(100% / 3.2);
      }

      @media(min-width: $screen-md-min) {
        width: 100%;
        order: 3
      }
    }

    &--payment-options {
      width: 100%;

      @media(min-width: $screen-sm-min) {
        width: calc(100% / 3.2);
      }

      @media(min-width: $screen-md-min) {
        width: 100%;
        order: 2
      }

      .operator-info__item__content {
        justify-content: space-around;
      }
    }

    &--payouts {
      .operator-info__item__content {
        @media(min-width: $screen-xs-min) {
          padding: 20px 0;
        }

        @media(min-width: $screen-sm-min) {
          padding: 20px;
        }
      }
    }
  }

  &__flag {
    max-width: 30%;
    margin-right: 3%;

    &:last-of-type {
      margin-right: 0;
    }

    img {
      border: 1px solid $border-color;
      width: 100%;
      display: block;
    }
  }

  &__bank-option {
    margin-bottom: 5px;
    margin-right: 5px;

  }

  &__device {
    text-align: center;
    width: calc(100% / 3);

    //@media(min-width: $screen-xs-min) {
    //  width: initial;
    //}

    &__icon {
      color: $brand-primary;
      display: block;
      font-size: 1.8rem;
    }
  }

  &__ring {
    text-align: center;
    width: 100%;
    margin-bottom: 30px;

    @media(min-width: $screen-xs-min) {
      width: initial;
      margin-bottom: 0;
    }

    &__value {
      position: relative;
      width: 128px;
      height: 128px;
      border-radius: 50%;
      border: 1px solid $brand-primary;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $text-size-large;
      font-weight: 700;
      margin: 0 auto;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 116px;
        height: 116px;
        border-radius: 50%;
        border: 1px solid $border-color;
      }
    }

    &__label {
      margin-top: 10px;
      font-weight: 700;
    }
  }

  &__about {
    width: 100%;
    padding-right: 10px;
    padding-bottom: 20px;

    @media(min-width: $screen-xs-min) {
      width: calc(100% / 3);
      padding-bottom: 0;
    }

    &__title {
      font-weight: 700;
      font-family: $font-family-heading;
      font-size: $text-size-large;
      color: $projectBlack;
    }

  }

  &__link {
    width: 100%;

    @media(min-width: $screen-xs-min) {
      width: calc(100% / 3);
    }
  }

  &__btn {
    padding: 10px 15px;
  }
}