// Delete when templates are finished
$xs-640: '(max-width: 640px)';
$xs-619: '(max-width: 619px)';
$xs-584: '(max-width: 584px)';
$xs-519: '(max-width: 519px)';
$xs-480: '(max-width: 480px)';
$xs-425: '(max-width: 425px)';
$xs-388: '(max-width: 388px)';
$xs-min: '(min-width: 768px)';
$xs-max: '(max-width: 767px)';
$xs-481-767: '(min-width: 481px) and (max-width: 767px)';
$xs-585-767: '(min-width: 585px) and (max-width: 767px)';
$xs-389-584: '(min-width: 389px) and (max-width: 584px)';
$small: '(min-width: 768px) and (max-width: 991px)';
$small-max: '(max-width: 991px)';
$small-min: '(min-width: 992px)';
$small-medium: '(min-width: 768px) and (max-width: 1199px)';
$medium: '(min-width: 992px) and (max-width: 1199px)';
$medium-max: '(max-width: 1199px)';
$medium-min: '(min-width: 1200px)';

@mixin respond-to($media) {
  @media only screen and #{$media} {
    @content;
  }
}

// END DELETE

// SITEBUILD MIX-INS START

// Creates buttons
@mixin btn_color($button_color, $hover_color: darken($button_color, 10%) ) {
  @extend .btn;
  background: $button_color;
  box-shadow: 0 3px 0 0 $hover_color;

  &:hover {
    transform: translateY(3px);
    background: $hover_color;
  }
}

// Clearfix
@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}