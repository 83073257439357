.content {
  padding: 20px 0 ;
  @media (min-width: $screen-md-min) {
    padding: 30px 0 ;
  }

  + .content {
    padding: 0 0 20px;

    @media (min-width: $screen-md-min) {
      padding: 0 0 30px;
    }
  }

  &.clear {
    padding-bottom: 0;

    &:after {
      content: '';
      clear: both;
      display: table;
    }
  }

  &__title {
    color: $projectBlack;
  }
  h3 {
    padding-top: 20px;
  }
}

.box-read-more-mobile{
  overflow:hidden;
}

.persona-image{
  margin-top: 10px;
}

.description__bold {
  font-weight: 600;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.btn-fright {
  float: right;
}