.pros-cons {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  justify-content: space-between;
  align-items: flex-start;

  &__title {
    font-size: $text-size-large;
  }

  &__link {
    margin-top: 20px;
    display: block;
    color: $brand-links;

    &:hover {
     color: $brand-links-hover;
    }
  }

  &__item {
    width: 100%;
    padding: 10px 0;

    @media (min-width: $screen-xs-min) {
      width: 48%;
    }

    @media (min-width: $screen-md-min) {
      width: calc(100% / 3.2);
    }

    p,
    ul {
      padding: 10px 0 0;
    }

    ul li {
      margin-bottom: 10px;
    }

    &--positive {
      .icon {
        color: $brand-positive;
      }

    }

    &--negative {
      .icon {
        color: $brand-negative;
      }

    }

    &--recommend {
      background-color: $projectBlack;
      width: 100%;
      padding: 20px;
      margin-top: 20px;

      @media (min-width: $screen-md-min) {
        margin-top: 0;
        width: calc(100% / 3.2);
      }

      .pros-cons__title,
      p {
        color: $projectWhite;
      }

    }
  }
}