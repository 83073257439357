.feature-boxes {
  display: none;
  margin-top: 30px;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  @media (min-width: $screen-lg-min) {
    display: block;
  }

  &__item {
    height: 100%;
    border: 1px solid $projectGrey;
    background-color: $projectWhite;
    text-align: center;
    padding: 20px;

    &:hover,
    &.active {
      background-color: $projectBlack;
      color: $projectWhite;

      .feature-boxes__item__divider {
        background-color: transparentize($projectWhite, 0.4);
      }

      .feature-boxes__item__icon {
        display: none;
      }

      .feature-boxes__item__desc {
        display: flex;
      }

      .feature-boxes__item__more-info {
        display: none;
      }

    }

    &__icon {
      img {
        max-height: 64px;
        margin: 0 auto 20px;
        display: block;
      }
    }

    &__title {
      font-size: $heading-size-h6;
    }

    &__key-value {
      color: $brand-primary;
      font-weight: 700;
    }

    &__divider {
      height: 1px;
      width: 100%;
      margin: 10px 0;
      background-color: $projectGrey;
    }

    &__more-info {
      color: $link-color;
    }

    &__desc {
      display: none;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      font-size: $text-size-small;
      p {
        font-size: $text-size-small;
        padding: 0;
      }
    }

    &__payout-option {
      width: 50%;
      padding: 10px 5px 0;

      &__value {
        color: $link-color;
        font-size: $text-size-small;
      }
    }

    &__game-list {
      display: flex;
      flex-wrap: wrap;
    }

    &__game {
      text-align: left;
      text-transform: capitalize;

      &:nth-of-type(odd) {
        text-align: right;
        &:after {
          content: '|';
          margin: 0 5px;
        }
      }

      &__num {
        color: $link-color;
      }
    }
  }
}