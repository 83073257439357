@keyframes move-the-object {
  0% {
    width: 25px;
    height: 25px;
    opacity: 1;
    top: -2px;
    left: -2px;
  }
  99.99% {
    width: 31px;
    height: 31px;
    top: -5px;
    left: -5px;
    opacity: 0;
  }
  100% {
    border: 1px solid $projectWhite;
    opacity: 0.7;
  }
}

.recent-winners {
  // replace this with mobile styling
  max-width: 100%;
  padding-top: 20px;
  &__heading {
    text-align: center;
  }
  &__description {
    text-align: center;

    @media(min-width: $screen-md-min) {
      width: 75%;
      margin: 0 auto 20px;
    }
  }
  &__map {
    display: none;

    @media(min-width: $screen-sm-min) {
      display: block;
      background: no-repeat;
      position: relative;
      width: 526px;
      height: 681px;
      background-size: initial;
      background-position: initial;
      margin: 0 auto;
    }
  }
  &__vector-map {
    display: block;
    margin: 0 auto;
  }
  &__point {
    position: absolute;
    top: 100px;
    left: 100px;
    cursor: pointer;
    span {
      display: block;
      width: 23px;
      height: 23px;
      background: $brand-primary;
      border-radius: 23px;
      &:before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: 25px;
        height: 25px;
        border-radius: 23px;
        border: 1px solid $brand-primary;
        animation: move-the-object 1s 3 forwards;
      }
    }
    &--active {
      span {
        &:before {
          width: 31px;
          height: 31px;
          top: -5px;
          left: -5px;
        }
      }
    }
    &:hover {
      span:before {
        width: 31px;
        height: 31px;
        top: -5px;
        left: -5px;
      }
    }
  }
}

.recent-winners-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  overflow: visible;
  width: 100%;
  height: 0;
  //width: 100% !important;
  //height: 1px !important;

  @media(min-width: $screen-xs-min) {
    width: 0;
    height: 0;
  }

  &__wrapper {
    position: absolute;
    bottom: auto;
    top: 54px;
    left: 0;
    right: auto;
    background: $projectWhite;
    border: 1px solid $border-color;
    width: 100%;
    padding: 10px;
    box-shadow: 3px -3px 3px 1px rgba(0, 0, 0, 0.01);
    @media(min-width: $screen-xs-min) {
      left: 10px;
      width: 270px;
    }

    &:after, &:before {
      content: '';
      display: none;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 40px 0 0 40px;
      border-color: transparent transparent transparent $projectWhite;
      position: absolute;
      top: -40px;
      left: 0;
      @media(min-width: $screen-xs-min) {
        display: block;
      }
    }
    &:before {
      border-width: 42px 0 0 42px;
      border-color: transparent transparent transparent $border-color;
      top: -43px;
      left: -1px;
    }
    @media(min-width: $screen-xs-min) {

      &--right {
        left: auto;
        right: -10px;
        &:after, &:before {
          border-width: 0 0 40px 40px;
          border-color: transparent transparent $projectWhite transparent;
          left: auto;
          bottom: auto;
          top: -40px;
          right: 0;
        }

        &:before {
          border-width: 0 0 42px 42px;
          border-color: transparent transparent $border-color transparent;
          top: -43px;
          right: -1px;
        }
      }
      &--bottom {
        top: auto;
        bottom: 30px;
        left: 10px;
        right: auto;
        &:after, &:before {
          border-width: 40px 40px 0 0;
          border-color: $projectWhite transparent transparent transparent;
          top: auto;
          bottom: -40px;
          left: 0;
        }
        &:before {
          border-width: 42px 42px 0 0;
          border-color: $border-color transparent transparent transparent;
          top: auto;
          bottom: -43px;
          left: -1px;
        }
      }
      &--right-bottom {
        bottom: 30px;
        left: auto;
        top: auto;
        right: -10px;
        &:after, &:before {
          border-width: 0 40px 40px 0;
          border-color: transparent $projectWhite transparent transparent;
          bottom: -40px;
          top: auto;
          left: auto;
          right: 0;
        }

        &:before {
          border-width: 0 42px 42px 0;
          border-color: transparent $border-color transparent transparent;
          bottom: -43px;
          right: -1px;
        }
      }
    }
  }
  &__name {
    background: $brand-primary;
    display: block;
    padding: 12px 20px;
    font-size: 1.5rem;
    color: $projectWhite;
    font-weight: bold;
  }
  &__menu {
    padding: 20px;
    overflow: hidden;
    dt {
      float: left;
      clear: left;
      color: $projectGrey;
      padding-right: 5px;
    }
    dd {
      color: $projectBlack;
      text-align: left;
      float: left;
      font-weight: 700;
      &.casino {
        clear: left;
        margin-bottom: 20px;
      }
    }
  }

}

.jsmaps-wrapper circle {
  visibility: hidden;
  opacity: 0;
  transition: all .4s;
  fill: $brand-primary;
  &.is-visible {
    display: block;
    visibility: visible;
    opacity: 1;
    fill: $brand-links;
  }

  &.is-visible:hover,
  &.is-active {
    fill: $brand-primary;
  }
}

.jsmaps-tooltip {
  display: none !important;
}
