.sitemap {
    padding: 30px 0;
    &__menu {
        column-count: 1;
        @media (min-width: $screen-sm-min) {
            column-count: 2;
        }
        @media (min-width: $screen-lg-min) {
            column-count: 3;
        }
    }
    &__item {
        break-inside: avoid;
        background: $projectWhite;
        padding: 5px 5px 10px;
        border: 2px solid $border-color;
        width: 100%;
        margin-bottom: 20px;
        ul li {
            a {
                padding: 2px 10px;
                color: $projectGrey;
                &:hover {
                    padding-left: 6px;
                    color: $projectBlack;
                    border-left: 4px solid $brand-primary;
                }
            }
        }
        .icon-angle-down {
            display: none;
        }
    }
    &__link {
        padding: 10px;
        display: block;
        background: $projectBlack;
        color: $projectWhite;
        font-size: 1.2rem;
        font-family: $font-family-heading;
        margin-bottom: 5px;
        &:hover {
            color: $projectWhite;
        }
    }
}