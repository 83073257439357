.related {
  padding-top: 30px;
  @media (min-width: $screen-xs-min) {
    padding-bottom: 30px;
  }
  &__heading {
    text-align: center;
  }
  &__description {
    width: 80%;
    margin: 0 auto 30px;
    text-align: center;
  }
  &__icon{
    margin: 35px 0 0;
    font-size: 6rem;
    color: #fff;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;

  }
  &__item {
    display: block;
    border: 1px solid $border-color;
    padding: 15px 15px 30px;
    text-align: center;
    margin: 0 auto 20px;
    color: $projectGrey;
    min-height: 375px;
    position: relative;
    .cls-1, .cls-2, .cls-3{
      fill:none;
      stroke: #5fc86b;
    }
    .cls-1, .cls-2, .cls-3, .cls-4{
      stroke-width: 1px;
    }
    .cls-bold {
      stroke-width: 2px;
    }
    &:hover {
      color: $projectGrey;
      svg .cls-1, svg .cls-2, svg .cls-3{
        stroke: $projectWhite!important;
      }
    }
    .related__more {
      color: $link-color;
      position: absolute;
      bottom: 15px;
      left: 0;
      text-align: center;
      display: block;
      width: 100%;
    }
    &:hover {
      .related__more {
        color: $link-hover-color;
      }
      .related__image {
        background: none;
        span:before {
          color: $brand-primary;
        }
      }
    }
  }
  &__image {
    background-color: $projectBlack;
    background-size: cover;
    margin-bottom: 15px;
    width: 100%;
    height: 154px;
    position: relative;
    span:before {
      margin: 40px 0 0;
      font-size: 4rem;
      color: $projectWhite;
    }
  }
  &__title {
    font-weight: bold;
  }
}

.relatedBoxSlider {
  .slick {
    &-arrow {
      position: absolute;
      top: 120px;
      background: $projectBlack;
      display: block;
      border: 0;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      z-index: 1;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:before {
        font-family: $font-icons;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: $projectWhite;
        width: 100%;
        text-align: center;
      }
    }

    &-prev {
      left: 38px;

      &:before {
        content: '\f104';
      }
      @media (min-width: $screen-xs-min) {
        left: 0;
      }
    }

    &-next {
      right: 38px;

      &:before {
        content: '\f105';
      }
      @media (min-width: $screen-xs-min) {
        right: 0;
      }
    }
  }
}
