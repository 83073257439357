@keyframes bounce {
  0% {
    transform: translate3d(0, -25px, 0);
  }
  25% {
    transform: translate3d(0, 10px, 0);
  }
  50% {
    transform: translate3d(0, -6px, 0);
  }
  75% {
    transform: translate3d(0, 2px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounce--horizontal {
  0% {
    transform: translate3d(0px, 0, 0);
  }
  25% {
    transform: translate3d(20px,0 , 0);
  }
  50% {
    transform: translate3d(0, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}