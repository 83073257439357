html {
  font-size: 14px;
  @media(min-width: $screen-sm-min) {
    font-size: 16px;
  }
}

body {
  font-family: $font-family-body;
  background-color: $projectWhite;
  font-size: $text-size-base;
  color: $projectGrey;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  &.no-scroll {
    overflow: hidden;
  }
}

.container {
  width: 100%;
  position: relative;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;

  @media screen and (min-width: $screen-lg-min) {
    max-width: 1170px;
  }
}

h1,
h2,
h3 {
  font-family: $font-family-heading;
  line-height: 1.2;
}

h1 {
  font-size: $heading-size-h2;
  @media screen and (min-width: $screen-sm-min) {
    font-size: $heading-size-h1;
  }
}

h2 {
  font-size:1.8rem;
  @media screen and (min-width: $screen-sm-min) {
    font-size: $heading-size-h2;
  }
}

h3 {
  font-size: $heading-size-h3;
}

h4 {
  font-size: $heading-size-h4;
}

h5 {
  font-size: $heading-size-h5;
}

h6 {
  font-size: $heading-size-h6;
}

p {
  font-size: $text-size-base;
  line-height: 1.4;
  padding: 10px 0;

  .bold {
    font-weight: 700;
  }
}

a {
  color: $link-color;
  text-decoration: none;

  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

h3 {
  &.middle-text {
    text-align: center;
    margin-bottom: 20px;
  }
}