.conclusion-cta-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid $border-color;
  align-content: center;
  margin: 20px 0;

  &:hover {
    .conclusion-cta-box__btn {
      transform: translateY(3px);
      box-shadow: none;
      background-color: $brand-primary-accent;
    }
  }

  &__header {
    padding: 20px 10px 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    background-color: $projectOffWhite;

    @media (min-width: $screen-xs-min) {
      padding: 0 10px;
      margin: 20px 0;
    }

    @media (min-width: $screen-sm-min) {
      width: 30%;
      padding: 0 10px;
      margin: 20px 0;
      background-color: initial;
      align-content: initial;
      border-right: 1px solid $border-color;
    }

  }

  &__body {
    padding: 20px;
    width: 100%;

    @media (min-width: $screen-sm-min) {
      width: 70%;
    }
  }

  &__title {
    font-size: $heading-size-h4;
  }

  &__logo,
  &__rating {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;

    @media (min-width: $screen-xs-min) {
      width: 50%;
    }

    @media (min-width: $screen-sm-min) {
      width: 100%;
    }
  }

  &__logo {

    @media (min-width: $screen-xs-min) {
      border-right: 1px solid $border-color;
    }

    @media (min-width: $screen-sm-min) {
      border-right: 0;
    }

    img {
      align-self: center;
    }

  }

  &__rating {
    display: flex;
    align-content: center;
    justify-content: center;

    &__value {
      align-self: center;
      span {
        font-weight: 700;
        font-size: $text-size-large;
      }
    }
  }

  &__stars {
    align-self: center;
    padding-right: 5px;
    color: $brand-rating-stars;
  }

  &__ranking {

  }

  &__btn {
    width: 100%;
    display: block;
    margin: 0 20px 20px;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}